import { agMakeTextColDef } from '../../../../../lib/custom-element/ag-list/column-define/text.define';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NbToastrService, NbDialogService, NbDialogRef } from "@nebular/theme";
import { CurrencyMaskConfig } from "ng2-currency-mask";
import { filter, takeUntil } from "rxjs/operators";
import { environment } from "../../../../../../environments/environment.prod";
import { ActionControlListOption } from "../../../../../lib/custom-element/action-control-list/action-control.interface";
import { CustomIcon, FormGroupComponent } from "../../../../../lib/custom-element/form/form-group/form-group.component";
import { Select2Option } from "../../../../../lib/custom-element/select2/select2.component";
import { DataManagerFormComponent } from "../../../../../lib/data-manager/data-manager-form.component";
import { BusinessModel } from "../../../../../models/accounting.model";
import { ContactModel } from "../../../../../models/contact.model";
import { ProductModel } from "../../../../../models/product.model";
import { TaxModel } from "../../../../../models/tax.model";
import { UnitModel } from "../../../../../models/unit.model";
import { ApiService } from "../../../../../services/api.service";
import { CommonService } from "../../../../../services/common.service";
import { RootServices } from "../../../../../services/root.services";
import { AdminProductService } from "../../../../admin-product/admin-product.service";
import { ProductFormComponent } from "../../../../admin-product/product/product-form/product-form.component";
import { ContactFormComponent } from "../../../../contact/contact/contact-form/contact-form.component";
import { ReferenceChoosingDialogComponent } from "../../../../dialog/reference-choosing-dialog/reference-choosing-dialog.component";
import { MobileAppService } from "../../../../mobile-app/mobile-app.service";
import { CollaboratorService } from "../../../collaborator.service";
import { CollaboratorSellerMyReportPrintComponent } from "../seller-my-report-print/seller-my-report-print.component";
import * as XLSX from 'xlsx';
import { AgTextCellRenderer } from "../../../../../lib/custom-element/ag-list/cell/text.component";
import { agMakeSelectionColDef } from "../../../../../lib/custom-element/ag-list/column-define/selection.define";
import { ColDef, ColumnApi, GridApi, IRowNode } from "@ag-grid-community/core";
import { Model } from "../../../../../models/model";
import { AgDynamicListComponent } from "../../../../general/ag-dymanic-list/ag-dymanic-list.component";
import { AgDateCellRenderer } from '../../../../../lib/custom-element/ag-list/cell/date.component';
import { agMakeAccCurrencyColDef } from '../../../../../lib/custom-element/ag-list/column-define/acc-currency.define';
import { agMakeTagsColDef } from '../../../../../lib/custom-element/ag-list/column-define/tags.define';
import { AgSelect2Filter } from '../../../../../lib/custom-element/ag-list/filter/select2.component.filter';

var CryptoJS = require("crypto-js");

@Component({
  selector: 'ngx-seller-my-report-form',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './seller-my-report-form.component.html',
  styleUrls: ['./seller-my-report-form.component.scss']
})
export class CollaboratorSellerMyReportFormComponent extends DataManagerFormComponent<Model> implements OnInit {

  componentName: string = 'CollaboratorSellerMyReportFormComponent';
  idKey = 'Code';
  apiPath = '/collaborator/report/seller/my-reports';
  baseFormUrl = '/collaborator/report/salary/form';

  env = environment;

  locale = this.cms.getCurrentLoaleDataset();
  curencyFormat: CurrencyMaskConfig = this.cms.getCurrencyMaskConfig();
  numberFormat: CurrencyMaskConfig = this.cms.getNumberMaskConfig();

  // locale = this.commo nService.getCurrentLoaleDataset();
  priceCurencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0 };
  toMoneyCurencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0 };
  quantityFormat: CurrencyMaskConfig = { ...this.cms.getNumberMaskConfig(), precision: 2 };

  towDigitsInputMask = this.cms.createFloatNumberMaskConfig({
    digitsOptional: false,
    digits: 2
  });

  /** Tax list */
  static _taxList: (TaxModel & { id?: string, text?: string })[];
  taxList: (TaxModel & { id?: string, text?: string })[];

  /** Unit list */
  static _unitList: (UnitModel & { id?: string, text?: string })[];
  unitList: (UnitModel & { id?: string, text?: string })[];
  @ViewChild('detailsViewport', { static: false }) detailsViewport: CdkVirtualScrollViewport;


  uploadConfig = {

  };

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastrService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public prds: AdminProductService,
    public ref: NbDialogRef<CollaboratorSellerMyReportFormComponent>,
    public mobileAppService: MobileAppService,
    public collaboratorService: CollaboratorService,
    // public changeDirectorRef: ChangeDetectorRef,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastrService, dialogService, cms);

    /** Append print button to head card */
    this.actionButtonList.splice(this.actionButtonList.length - 1, 0, {
      name: 'print',
      status: 'primary',
      label: this.cms.textTransform(this.cms.translate.instant('Common.print'), 'head-title'),
      icon: 'printer',
      title: this.cms.textTransform(this.cms.translate.instant('Common.print'), 'head-title'),
      size: 'medium',
      disabled: () => this.isProcessing,
      hidden: () => false,
      click: (event: any, option: ActionControlListOption) => {
        this.preview(option.form);
      },
    });

    /** Detail processing */
    /** AG-Grid */
    this.columnDefs = [


      {
        ...agMakeSelectionColDef(this.cms),
        headerName: '#',
        field: 'Id',
        width: 120,
        // valueGetter: 'node.data.Id',
        valueGetter: (params) => {
          return params.data?.No;
        }
      },
      // {
      //   headerName: ' Ngày chứng từ',
      //   field: 'VoucherDate',
      //   width: 170,
      //   // pinned: 'left',
      //   filter: 'agDateColumnFilter',
      //   filterParams: {
      //     inRangeFloatingFilterDateFormat: 'DD/MM/YY',
      //   },
      //   cellRenderer: AgDateCellRenderer,
      // },
      {
        headerName: 'Diễn giải',
        field: 'Description',
        width: 1400,
        filter: 'agTextColumnFilter',
        autoHeight: true,
      },
      // {
      //   headerName: this.cms.translateText('Common.contact'),
      //   field: 'Object',
      //   // pinned: 'left',
      //   width: 200,
      //   // cellRenderer: AgTextCellRenderer,
      //   filter: AgSelect2Filter,
      //   valueFormatter: (params) => {
      //     return params.data?.ObjectName;
      //   },
      //   filterParams: {
      //     select2Option: {
      //       ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
      //         placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
      //           item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
      //           return item;
      //         }
      //       }),
      //       multiple: true,
      //       logic: 'OR',
      //       allowClear: true,
      //     }
      //   },
      // },
      // {
      //   ...agMakeTagsColDef(this.cms, (tag) => {
      //     this.cms.previewVoucher(tag.type, tag.id);
      //   }),
      //   headerName: 'Chứng từ',
      //   field: 'Voucher',
      //   width: 180,
      //   // pinned: 'right',
      //   cellClass: ['ag-cell-items-center'],
      //   valueGetter: (params) => {
      //     return (params.data?.Voucher ? [params.data?.Voucher] : []).map(m => ({
      //       id: m,
      //       text: m
      //     }));
      //   }
      // },
      // {
      //   headerName: 'Sản phẩm',
      //   field: 'Product',
      //   // pinned: 'left',
      //   width: 200,
      //   cellRenderer: (params) => {
      //     return params.data?.Description;
      //   },
      //   filter: AgSelect2Filter,
      //   filterParams: {
      //     select2Option: {
      //       ...this.cms.makeSelect2AjaxOption('/admin-product/products', { includeIdText: true, sort_SearchRank: 'desc' }, {
      //         placeholder: 'Chọn sản phẩm...', limit: 10, prepareReaultItem: (item) => {
      //           // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
      //           return item;
      //         }
      //       }),
      //       multiple: true,
      //       logic: 'OR',
      //       allowClear: true,
      //     }
      //   },
      // },
      // {
      //   headerName: 'Khoản mục CP',
      //   field: 'CostClassification',
      //   width: 180,
      //   filter: 'agTextColumnFilter',
      //   filterParams: {
      //     type: 'equals'
      //   },
      //   autoHeight: true,
      //   cellRenderer: AgTextCellRenderer,
      // },
      // {
      //   headerName: 'Luồng hạch toán',
      //   field: 'Thread',
      //   width: 180,
      //   filter: 'agTextColumnFilter',
      //   filterParams: {
      //     type: 'equals'
      //   },
      //   autoHeight: true,
      //   cellRenderer: AgTextCellRenderer,
      // },
      // {
      //   headerName: 'TK',
      //   field: 'Account',
      //   width: 100,
      //   filter: 'agTextColumnFilter',
      //   pinned: 'right',
      // },
      // {
      //   headerName: 'ĐU',
      //   field: 'ContraAccount',
      //   width: 100,
      //   filter: 'agTextColumnFilter',
      //   pinned: 'right',
      // },
      {
        headerName: 'SL',
        field: 'Quantity',
        width: 80,
        filter: 'agNumberColumnFilter',
        pinned: 'right',
      },
      {
        headerName: 'ĐVT',
        field: 'ProductUnit',
        width: 100,
        // filter: 'agTextColumnFilter',
        valueFormatter: (params) => {
          return params.data?.ProductUnitLabel;
        },
        pinned: 'right',
        filter: AgSelect2Filter,
        filterParams: {
          select2Option: {
            placeholder: 'Chọn...',
            allowClear: true,
            width: '100%',
            dropdownAutoWidth: true,
            minimumInputLength: 0,
            withThumbnail: false,
            multiple: true,
            keyMap: {
              id: 'id',
              text: 'text',
            },
            data: this.prds.unitList$.value,
          }
        },
      },
      // {
      //   ...agMakeAccCurrencyColDef(this.cms),
      //   headerName: 'Giá',
      //   field: 'Price',
      //   pinned: 'right',
      //   width: 100,
      // },
      // {
      //   ...agMakeAccCurrencyColDef(this.cms),
      //   headerName: 'Đ.Kỳ',
      //   field: 'HeadAmount',
      //   // pinned: 'right',
      //   width: 100,
      // },
      // {
      //   ...agMakeAccCurrencyColDef(this.cms),
      //   headerName: 'PS.Nợ',
      //   field: 'GenerateDebit',
      //   // pinned: 'right',
      //   width: 200,
      // },
      {
        ...agMakeAccCurrencyColDef(this.cms),
        headerName: 'PS.Có',
        field: 'GenerateCredit',
        pinned: 'right',
        width: 200,
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'Quantity',
        field: 'Quantity',
        width: 100,
      },
      {
        ...agMakeTextColDef(this.cms),
        headerName: 'SystemUuid',
        field: 'SystemUuid',
        width: 100,
      },
      // {
      //   ...agMakeAccCurrencyColDef(this.cms),
      //   headerName: this.cms.translateText('Accounting.increment'),
      //   field: 'IncrementAmount',
      //   pinned: 'right',
      //   width: 200,
      // }




      // {
      //   ...agMakeSelectionColDef(this.cms),
      //   headerName: 'STT',
      //   field: 'Id',
      //   valueGetter: 'node.data.No',
      // },
      // {
      //   headerName: 'Diễn giải',
      //   field: 'Description',
      //   width: 400,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      //   pinned: 'left',
      // },
      // {
      //   headerName: 'Sản phẩm',
      //   field: 'Product',
      //   width: 350,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      // },
      // {
      //   headerName: 'ĐVT',
      //   field: 'ProductUnit',
      //   width: 350,
      //   filter: 'agTextColumnFilter',
      //   cellRenderer: AgTextCellRenderer,
      // },
      // {
      //   ...agMakeNumberColDef(this.cms),
      //   headerName: 'Số lượng',
      //   field: 'Quantity',
      //   width: 150,
      //   pinned: 'right',
      // },
      // {
      //   ...agMakeCurrencyColDef(this.cms),
      //   headerName: 'Giá',
      //   field: 'Price',
      //   width: 200,
      //   pinned: 'right',
      // },
      // {
      //   ...agMakeCurrencyColDef(this.cms),
      //   headerName: 'Số tiền',
      //   field: 'Amount',
      //   width: 150,
      //   pinned: 'right',
      // },
      // {
      //   ...agMakeTextColDef(this.cms),
      //   headerName: 'SystemUuid',
      //   field: 'SystemUuid',
      //   width: 512,
      // },
      // {
      //   ...agMakeCommandColDef(null, this.cms, false, false, false),
      //   // width: 123,
      //   headerName: 'Lệnh',
      // },
    ];
    /** End Detail processing */
  }

  // getRequestId(callback: (id?: string[]) => void) {
  //   callback(this.inputId);
  // }



  getRequestId(callback: (id?: string[]) => void) {
    // callback(this.inputId);
    return super.getRequestId(callback);
  }

  select2OptionForPublisher = {
    ...this.cms.makeSelect2AjaxOption('/collaborator/publishers', {}, {
      placeholder: 'Chọn CTV Bán Hàng...', limit: 10, prepareReaultItem: (item) => {
        item['id'] = item.Contact;
        item['text'] = item.Name;
        return item;
      }
    }),
    // minimumInputLength: 1,
  };

  select2OptionForProduct: Select2Option = {
    placeholder: 'Chọn...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    withThumbnail: true,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    ajax: {
      data: function (params) {
        return {
          ...params,
          offset: params['offset'] || 0,
          limit: params['limit'] || 10
        };
      },
      transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
        const params = settings.data;
        const offset = settings.data['offset'];
        const limit = settings.data['limit'];
        const results = !params['term'] ? this.prds.productSearchIndexsGroupById : this.prds.productSearchIndexsGroupById.filter(f => this.cms.smartFilter(f.SearchText, params['term']));
        success({ data: results.slice(offset, offset + limit), total: results.length });
        return null;
      },
      delay: 300,
      processResults: (rs: { data: any[], total: number }, params: any) => {
        const data = rs.data;
        const total = rs.total;
        params.limit = params.limit || 10;
        params.offset = params.offset || 0;
        params.offset = params.offset += params.limit;
        return {
          results: data.map(item => {
            item.id = item.Code;
            item.text = `${item.Sku} - ${item.Name} (${item.Code})`;
            item.thumbnail = item?.FeaturePicture?.Thumbnail;
            return item;
          }),
          pagination: {
            more: params.offset < total
          }
        };
      },
    },
  };

  select2OptionForUnit = {
    placeholder: 'Chọn ĐVT...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
  };
  select2OptionForPage = {
    placeholder: 'Chọn trang...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  select2OptionForTax = {
    placeholder: 'Chọn thuế...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
  };

  // Type field option
  select2OptionForType = {
    placeholder: 'Chọn loại...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
  };
  select2DataForType = [
    { id: 'PRODUCT', text: 'Sản phẩm' },
    { id: 'CATEGORY', text: 'Danh mục' },
  ];

  objectControlIcons: CustomIcon[] = [{
    icon: 'plus-square-outline',
    title: this.cms.translateText('Common.addNewContact'),
    status: 'success',
    states: {
      '<>': {
        icon: 'edit-outline',
        status: 'primary',
        title: this.cms.translateText('Common.editContact'),
      },
      '': {
        icon: 'plus-square-outline',
        status: 'success',
        title: this.cms.translateText('Common.addNewContact'),
      },
    },
    action: (formGroupCompoent: FormGroupComponent, formGroup: FormGroup, array: FormArray, index: number, option: { parentForm: FormGroup }) => {
      const currentObject = this.cms.getObjectId(formGroup.get('Object').value);
      this.cms.openDialog(ContactFormComponent, {
        context: {
          inputMode: 'dialog',
          inputId: currentObject ? [currentObject] : null,
          showLoadinng: true,
          onDialogSave: (newData: ContactModel[]) => {
            console.log(newData);
            const newContact: any = { ...newData[0], id: newData[0].Code, text: newData[0].Name };
            formGroup.get('Object').patchValue(newContact);
          },
          onDialogClose: () => {

          },
        },
        closeOnEsc: false,
        closeOnBackdropClick: false,
      });
    },
  }];

  contactControlIcons: CustomIcon[] = [{
    icon: 'plus-square-outline',
    title: this.cms.translateText('Common.addNewContact'),
    status: 'success',
    states: {
      '<>': {
        icon: 'edit-outline',
        status: 'primary',
        title: this.cms.translateText('Common.editContact'),
      },
      '': {
        icon: 'plus-square-outline',
        status: 'success',
        title: this.cms.translateText('Common.addNewContact'),
      },
    },
    action: (formGroupCompoent: FormGroupComponent, formGroup: FormGroup, array: FormArray, index: number, option: { parentForm: FormGroup }) => {
      const currentObject = this.cms.getObjectId(formGroup.get('Publisher').value);
      this.cms.openDialog(ContactFormComponent, {
        context: {
          inputMode: 'dialog',
          inputId: currentObject ? [currentObject] : null,
          showLoadinng: true,
          onDialogSave: (newData: ContactModel[]) => {
            console.log(newData);
            const newContact: any = { ...newData[0], id: newData[0].Code, text: newData[0].Name };
            formGroup.get('Publisher').patchValue(newContact);
          },
          onDialogClose: () => {

          },
        },
        closeOnEsc: false,
        closeOnBackdropClick: false,
      });
    },
  }];

  accountingBusinessList: BusinessModel[] = [];
  select2OptionForAccountingBusiness = {
    placeholder: 'Nghiệp vụ kế toán...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    // dropdownCssClass: 'is_tags',
    maximumSelectionLength: 1,
    multiple: true,
    // tags: true,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
  };

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {

    /** Load and cache tax list */
    this.taxList = (await this.apiService.getPromise<TaxModel[]>('/accounting/taxes')).map(tax => {
      tax['id'] = tax.Code;
      tax['text'] = tax.Name;
      return tax;
    });

    /** Load and cache unit list */
    this.unitList = (await this.apiService.getPromise<UnitModel[]>('/admin-product/units', { limit: 'nolimit' })).map(tax => {
      tax['id'] = tax.Code;
      tax['text'] = tax.Name;
      return tax;
    });
    this.rsv.accountingService.accountingBusinessList$.pipe(filter(f => !!f), takeUntil(this.destroy$)).subscribe(list => {
      this.accountingBusinessList = list.filter(f => ['COLLABORATORSALARY'].indexOf(f.Type) > -1);
    });
    return super.init().then(async status => {
      if (this.isDuplicate) {
        // Clear id
        this.id = [];
        this.array.controls.forEach((formItem, index) => {
          formItem.get('Code').setValue('');
          formItem.get('Title').setValue('Copy of: ' + formItem.get('Title').value);
          // this.getDetails(formItem as FormGroup).controls.forEach(conditonFormGroup => {
          //   // Clear id
          //   // conditonFormGroup.get('Id').setValue('');
          // });
        });
      }

      return status;
    });

  }

  /** Execute api get */
  executeGet(params: any, success: (resources: Model[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includeObject'] = true;
    params['includeDetails'] = true;
    params['includeRelativeVouchers'] = true;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: Model[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: Model) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      // Details form load
      if (this.gridApi) {
        this.loadList();
      }

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    }).then(rs => {
      return rs;
    });

  }

  makeNewFormGroup(data?: Model): FormGroup {
    if (data) {
      if (data.DateOfReport) {
        data.DateOfReport = new Date(data.DateOfReport);
      }
      if (data.DateOfBegin) {
        data.DateOfBegin = new Date(data.DateOfBegin);
      }
      if (data.DateOfEnd) {
        data.DateOfEnd = new Date(data.DateOfEnd);
      }
    }
    const newForm = this.formBuilder.group({
      Code: [''],
      Page: { disabled: true, value: this.collaboratorService.currentpage$?.value || null },
      Object: ['', Validators.required],
      ObjectName: ['', Validators.required],
      // ObjectName: { disabled: true, value: null },
      ObjectEmail: [''],
      ObjectPhone: [''],
      ObjectAddress: [''],
      ObjectIdentifiedNumber: [''],
      ObjectTaxCode: [''],
      ObjectBankName: [''],
      ObjectBankCode: [''],

      Title: ['', Validators.required],
      Note: [''],
      SubNote: [''],
      DateOfReport: [new Date(), Validators.required],
      DateOfBegin: [null, Validators.required],
      DateOfEnd: [null, Validators.required],
      _total: [''],
      RelativeVouchers: [],
      // Details: this.formBuilder.array([]),
    });

    // Add controls allway disabled
    // this.disabledControls.push(newForm.get('ObjectName'));

    if (data) {
      newForm.patchValue(data);
      newForm['__Details'] = data.Details;
    } else {
      // this.addDetailFormGroup(newForm);
    }
    // newForm['_details'] = this.getDetails(newForm);
    newForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      console.log('Form value change: ', value);
    });
    // if (data) {
    // }
    return newForm;
  }

  checkDisabledFormGroup(formData) {
    if (formData) {
      return !this.isDuplicate && this.cms.getObjectId(formData.State) == 'APPROVED';
    }
    return false;
  }

  onAddFormGroup(index: number, newForm: FormGroup, formData?: Model): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }

  goback(): false {
    // super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/collaborator/report/salary/list']);
    } else {
      this.ref.close();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }

  onObjectChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          formGroup.get('ObjectName').setValue(selectedData.Name);
          formGroup.get('ObjectPhone').setValue(selectedData.Phone);
          formGroup.get('ObjectEmail').setValue(selectedData.Email);
          formGroup.get('ObjectAddress').setValue(selectedData.Address);
        }
      }
    }
  }

  calculatToMoney(detail: FormGroup, source?: string) {
    const toMoney = detail.get('Amount').value;
    return toMoney;
  }

  // toMoney(formItem: FormGroup, detail?: FormGroup, source?: string, index?: number) {
  //   this.cms.takeUntil(this.componentName + '_ToMoney_ ' + index, 300).then(() => {
  //     // Call culate total
  //     const details = this.getDetails(formItem);
  //     let total = 0;
  //     for (let i = 0; i < details.controls.length; i++) {
  //       total += this.calculatToMoney(details.controls[i] as FormGroup);
  //     }
  //     formItem.get('_total').setValue(total);
  //   });
  //   return false;
  // }


  async preview(formItem: FormGroup) {
    const data: Model = formItem.value;
    this.cms.openDialog(CollaboratorSellerMyReportPrintComponent, {
      context: {
        showLoadinng: true,
        title: 'Xem trước',
        data: [data],
        idKey: ['Code'],
        onSaveAndClose: (priceReport: Model) => {
          this.saveAndClose();
        },
        onSaveAndPrint: (priceReport: Model) => {
          this.save();
        },
      },
    });
    return false;
  }

  openRelativeVoucher(relativeVocher: any) {
    if (relativeVocher) this.cms.previewVoucher(this.cms.getObjectId(relativeVocher.type), relativeVocher);
    return false;
  }

  customIcons: { [key: string]: CustomIcon[] }[] = [];
  getCustomIcons(name: string): CustomIcon[] {
    if (this.customIcons[name]) return this.customIcons[name];
    return this.customIcons[name] = [{
      icon: 'plus-square-outline',
      title: this.cms.translateText('Common.addNewProduct'),
      status: 'success',
      states: {
        '<>': {
          icon: 'edit-outline',
          status: 'primary',
          title: this.cms.translateText('Common.editProduct'),
        },
        '': {
          icon: 'plus-square-outline',
          status: 'success',
          title: this.cms.translateText('Common.addNewProduct'),
        },
      },
      action: (formGroupCompoent: FormGroupComponent, formGroup: FormGroup, array: FormArray, index: number, option: { parentForm: FormGroup }) => {
        const currentProduct = this.cms.getObjectId(formGroup.get('Product').value);
        this.cms.openDialog(ProductFormComponent, {
          context: {
            inputMode: 'dialog',
            inputId: currentProduct ? [currentProduct] : null,
            showLoadinng: true,
            onDialogSave: (newData: ProductModel[]) => {
              console.log(newData);
              // const formItem = formGroupComponent.formGroup;
              const newProduct: any = { ...newData[0], id: newData[0].Code, text: newData[0].Name, Units: newData[0].UnitConversions?.map(unit => ({ ...unit, id: this.cms.getObjectId(unit?.Unit), text: this.cms.getObjectText(unit?.Unit) })) };
              formGroup.get('Product').patchValue(newProduct);
            },
            onDialogClose: () => {

            },
          },
          closeOnEsc: false,
          closeOnBackdropClick: false,
        });
      }
    }];
  }

  openRelativeVoucherChoosedDialog(formGroup: FormGroup) {
    this.cms.openDialog(ReferenceChoosingDialogComponent, {
      context: {
        components: {
          'COMMERCEPOSORDER': { title: 'Đơn hàng POS' },
          'SALES': { title: 'Phiếu bán hàng' },
          'PRICEREPORT': { title: 'Phiếu báo giá' },
        },
        // inputMode: 'dialog',
        onDialogChoose: async (chooseItems: any[], type?: string) => {
          console.log(chooseItems);
          const relationVoucher = formGroup.get('RelativeVouchers');
          const relationVoucherValue: any[] = (relationVoucher.value || []);
          const insertList = [];
          this.onProcessing();

          setTimeout(() => {
            this.onProcessed();
          }, 1000);
        },
      }
    })
    return false;
  }

  exportDetails(formItem: FormGroup) {
    const data = this.getRawFormData();
    const details = [];
    let no = 0;
    for (const detail of data.array[0].Details) {
      no++;
      details.push({
        STT: no,
        Sku: detail['Product']['Sku'],
        Product: this.cms.getObjectId(detail['Product']),
        ProductName: detail['Description'],
        ProductTaxName: detail['ProductTaxName'],
        Tax: detail['Tax'],
        Unit: this.cms.getObjectId(detail['Unit']),
        UnitName: this.cms.getObjectText(detail['Unit']),
        Price: detail['Price'],
        Quantity: detail['Quantity'],
        ToMoney: detail['ToMoney'],
      });
    }
    const sheet = XLSX.utils.json_to_sheet(details);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, 'Chi tiết đơn đặt mua hàng');
    XLSX.writeFile(workbook, 'DDMH-' + data.array[0].Code + ' - ' + data.array[0].Title + ' - NCC: ' + this.cms.getObjectId(data.array[0].Object) + ' - ' + data.array[0].ObjectName + '.xlsx');

  }

  onMoreBtnMessageClick(msg: any) {
    console.log(msg);
    this.cms.showDialog('Bình luận', msg.text, []);
  }


  /** Detail processing */
  public gridApi: GridApi;
  public productGridApi: GridApi;
  public gridColumnApi: ColumnApi;
  public columnDefs: ColDef[];
  public productsColumnDefs: ColDef[];
  public gridParams;

  onGridReady(params) {
    this.gridParams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadList();
  }
  loadList(callback?: (list: Model[]) => void) {
    if (this.gridApi) {
      let details: Model[] = (this.array.controls[0]['__Details'] || []).map((detail: Model) => {
        // if (!detail.Products) {
        //   detail.Products = [];
        // }
        // detail.Products.map(product => {
        //   product['id'] = this.cms.getObjectId(product.Product) + '-' + this.cms.getObjectId(product.Unit);
        //   product['text'] = product.ProductName + '/' + this.cms.getObjectText(product.Unit) + '/' + product.Level1CommissionRatio;
        // });
        return detail;
      });
      this.gridApi.setRowData(details);
    }
  }

  // selectedDetailNode: IRowNode<Model> = null;
  onDetialsSelected(nodes: IRowNode<Model>[]) {
    console.log('On Details selected: ', nodes);
    if (nodes.length == 1) {
      // Load relative products
    }
  }
  onGridInit(component: AgDynamicListComponent<any>) {
    const $this = this;
    let actionButtonList = component.actionButtonList;
    // actionButtonList = actionButtonList.filter(f => f.name != 'choose');

    if (component.parentObject) {
      component.parentObject['__DetailsComponent'] = component;
    }

    actionButtonList = [];
    // actionButtonList.unshift({
    //   type: 'button',
    //   name: 'delete',
    //   title: 'Gở',
    //   status: 'danger',
    //   label: 'Gở',
    //   iconPack: 'eva',
    //   icon: 'minus-square-outline',
    //   size: 'medium',
    //   click: (event) => {
    //     const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
    //     $this.gridApi.applyTransaction({ remove: selectedNodes.map(m => m.data) });

    //     return true;
    //   }
    // });
    actionButtonList.unshift({
      type: 'button',
      name: 'getStatistics',
      title: 'Lấy dữ liệu thống kê',
      status: 'primary',
      label: 'Thống kê',
      iconPack: 'eva',
      icon: 'arrow-downward-outline',
      size: 'medium',
      click: (event) => {



        const reportData: Model = component.parentData;
        this.apiService.getPromise<Model[]>(this.apiPath, {
          getStatatistic: true,
          object: this.cms.getObjectId(reportData.Object),
          from: reportData.DateOfBegin ? reportData.DateOfBegin.toISOString() : null,
          to: reportData.DateOfEnd ? reportData.DateOfEnd.toISOString() : null,
          page: this.cms.getObjectId(this.collaboratorService.currentpage$.value),
        }).then(rs => {
          rs = rs.map((m, i) => {
            m.No = i + 1;
            m.Quantity = m.GenerateCreditQuantity;
            return m;
          });
          console.log(rs);
          component.gridApi.setRowData(rs);
        });

        return true;
      }
    });
    component.actionButtonList = actionButtonList;
  }
  /** End Detail processing */

  getRawFormData() {
    const rawData = super.getRawFormData();
    for (const i in rawData.array) {
      rawData.array[i]['Details'] = (this.array.controls[i]['__DetailsComponent'] as AgDynamicListComponent<Model>).getData();
    }
    return rawData;
  }

}
