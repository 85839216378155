<nb-card class="small-header smart-table">
    <nb-card-header>
      <ngx-card-header [size]="size" [icon]="favicon" [title]="title ? title : ('User.Group.title' | translate:{action: '', definition: cms.translate.instant('Common.table')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

      <!-- <nav>
        <nb-icon pack="eva" icon="list-outline"></nb-icon> Danh nhóm người dùng
        <button nbButton status="success" hero size="tiny" (click)="loadList()" style="float: right;">
          <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
        </button>
      </nav> -->
    </nb-card-header>

    <nb-card-body>
      <div class="ng2-smart-table-wrap">
        <ng2-smart-table #smartTable *ngIf="settings" [attr.select]="hasSelect" #table [settings]="settings" [source]="source" (delete)="delete($event)" (edit)="onEditAction($event)"
          (create)="onSerialAction($event)" (userRowSelect)="onUserRowSelect($event)" (rowSelect)="onRowSelect($event)">
        </ng2-smart-table>
      </div>
    </nb-card-body>
  </nb-card>
