<form [formGroup]="form" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Systen.Parameter.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <input type="hidden" formControlName="Id">
            <div class="row">
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Name" label="Common.name" [array]="array" [index]="i" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Common.name' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array" [index]="i" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                  <textarea nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}"></textarea>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Type" label="Kiểu dữ liệu" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                  <ngx-select2 formControlName="Type" [select2Option]="select2optionForType" [data]="typeList"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="InputType" label="Kiểu nhập liệu" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                  <ngx-select2 formControlName="InputType" [select2Option]="select2optionForInputType" [data]="inputTypeList"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-12" *ngIf="(formItem.get('InputType').value | objectid) == 'text' || (formItem.get('InputType').value | objectid) == 'int'">
                <ngx-form-group [formGroup]="formItem" name="Value" label="Common.value" [array]="array" [index]="i" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Value" placeholder="{{'Common.value' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12" *ngIf="(formItem.get('InputType').value | objectid) == 'textarea'">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Value'), 'warning')">
                  <label class="label"><span class="valid">Giá trị (*)</span><span class="invalid">Giá trị là bắt buộc (*)</span></label>
                  <ckeditor [editor]="Editor" formControlName="Value" [config]="ckEditorConfig"></ckeditor>
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">Lưu & Đóng</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero (click)="save()">Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>