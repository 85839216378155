<form [formGroup]="form" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Accounting.AccCostClassification.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <div class="row">
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Code" label="Common.code" [array]="array" [index]="i" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                    <input type="text" nbInput fullWidth formControlName="Code" placeholder="{{'Common.autoGenerate' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="Name" label="Common.name" [array]="array" [index]="i" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                    <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>  
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Parent" label="Khoản mục cha" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                                    <ngx-select2 formControlName="Parent" [select2Option]="select2OptionForParent" [data]="costClassificationList"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-9">
                                <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [touchedValidate]="false">
                                    <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="AccModel" label="Mô hình kế toán" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                                    <ngx-select2 formControlName="AccModel" [select2Option]="select2OptionForAccModel"></ngx-select2>
                                </ngx-form-group>
                            </div>
                        </div>
                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton status="warning" (click)="addFormGroup()" hero style="float: left;">Thêm cái nữa</button>
                        <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">Lưu & Đóng</button>
                        <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero (click)="save()">Lưu</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>