<div class="dialog-wrap {{inputMode}}" #dialogWrap>
  <nb-card [nbSpinner]="loading || data.__loading" *ngFor="let data of this.data; let i=index;" style="max-height: initial; margin: 0 auto; width: 22cm;" class="small-header">
    <nb-card-header>
      <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="('Warehouse.GoodsDeliveryNote.title' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
    </nb-card-header>
    <nb-card-body class="print-body" #printContent>
      <div id="print-area">
        <ngx-print-header></ngx-print-header>
        <div>
          <div class="print-title">{{ 'Warehouse.InventoryAdjustNote.title' | translate:{action: '', definition: ''} |
            uppercase }}
          </div>
          <div class="under-title">{{ data.Code }} - {{ data.DateOfAdjusted | date:'short' }}</div>
        </div>
        <div>
          <div class="under-line">Tiêu đề: {{data.Title}}</div>
          <div style="display: flex;">
            <div style="flex: 1;">
              <div class="under-line">{{'Common.contactId' | translate | headtitlecase}} :
                {{renderValue(data.ObjectName)}}
              </div>
            </div>
            <div>
              <div class="under-line">{{'ID liên hệ' | translate | headtitlecase}} :
                {{renderValue(data.Object | objectid)}}
              </div>
            </div>
          </div>
          <div class="under-line">{{'Common.address' | translate | headtitlecase}} :
            {{renderValue(data.ObjectAddress)}}
          </div>
        </div>
        <div>
          <div>
            <table style="width: 100%;" class="print-voucher-detail-table">
              <tr class="print-voucher-detail-header">
                <th>#</th>
                <th>{{'Common.goods' | translate | headtitlecase}}</th>
                <th>{{'Warehouse.container' | translate | headtitlecase}}</th>
                <th class="">{{'Voucher.shortUnit' | translate | headtitlecase}}</th>
                <th class="text-align-right nowrap">{{'Warehouse.theoryInventory' | translate | headtitlecase}}</th>
                <th class="text-align-right nowrap">{{'Warehouse.realityInventory' | translate | headtitlecase}}</th>
                <th class="text-align-right nowrap">{{'Số truy xuất mới' | translate | headtitlecase}}</th>
              </tr>
              <ng-container *ngFor="let detail of data.Details; let i = index;">
                <tr class="print-voucher-detail-line" *ngIf="detail.Type === 'CATEGORY'">
                  <td colspan="8" style="font-weight: bold;" [innerHTML]="renderValue(detail.Description)"></td>
                </tr>
                <tr class="print-voucher-detail-line" *ngIf="detail.Type !== 'CATEGORY'">
                  <td>{{detail.No}}</td>
                  <td><span [innerHTML]="detail.Description"></span> <br>ID: {{detail.Product | objectid}} - SKU: {{detail.Product?.Sku}}</td>
                  <td class="">{{detail.Container?.FindOrder}} - {{detail.Container.ShelfName}}</td>
                  <td class="">{{detail.Unit | objecttext}}</td>
                  <td class="text-align-right">{{renderValue(detail.Inventory) | number}}</td>
                  <td class="text-align-right">{{renderValue(detail.Quantity) | number}}</td>
                  <td class="text-align-right">{{renderNewAccessNumber(detail?.AccessNumbers)}}</td>
                </tr>
              </ng-container>
              <tr>
                <td colspan="7">{{'Common.note' | translate | headtitlecase}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <div class="under-line" *ngIf="data.Note"><span [innerHTML]="renderValue(data.Note)"></span></div>
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <div style="text-align: center; flex: 0 0 50%;">
            <br> {{'Thủ kho' | translate | headtitlecase}}
            <br><br><br>
          </div>
          <div style="text-align: center; flex: 0 0 50%;">
            <br> {{'Nhân viên kiểm kho' | translate | headtitlecase}}
            <br><br><br>
          </div>
        </div>
        <div class="default-probox-one-print-footer">{{cms.env.defaultPrintFooter}}</div>
      </div>
    </nb-card-body>
    <nb-card-footer *ngIf="data.RelativeVouchers && data.RelativeVouchers.length > 0" class="print-relative-vouchers">
      <span class="tag" *ngFor="let relationVoucher of data.RelativeVouchers" nbTooltip="{{relationVoucher.type?.text}}: {{relationVoucher | objectstext}}">
        <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.type?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
        <nb-icon pack="eva" icon="close-outline" class="close-btn"></nb-icon>
      </span>
    </nb-card-footer>
    <nb-card-footer>
      <i>Chú ý: {{this.cms.getObjectId(data.State) != 'APPROVED' ? 'Tồn kho lý thuyết tính tới thời điểm hiện tại vì phiếu này chưa được duyệt!' : 'Tồn kho lý thuyết tại thời điểm duyệt phiếu vì phiếu này đã duyệt'}}<br>Nếu tồn thực tế lớn hơn số lượng hàng hóa có thể quét được mã vạch thì hệ thống sẽ tự động tạo mã cho các hàng hóa đã mất mã truy xuất !</i>
    </nb-card-footer>
    <nb-card-footer>
      <div class="buttons-row">
        <button *ngIf="mode === 'print'" nbButton hero status="info" (click)="edit(data);">
          <nb-icon pack="eva" icon="edit"></nb-icon>{{'Common.edit' | translate | headtitlecase}}
        </button>
        <button nbButton hero status="info" (click)="prepareCopy(data);" style="float: left;">
          <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
        </button>
        <!-- <button nbButton hero status="danger" (click)="approvedConfirm(data, i);">{{(processMapList[i]?.nextStateLabel || '') | translate | headtitlecase}}<nb-icon pack="eva" icon="skip-forward"></nb-icon></button> -->

        <!--State process buttons-->
        <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
        <!--End State process buttons-->

        <button *ngIf="!sourceOfDialog || sourceOfDialog === 'form'" nbButton hero status="success" (click)="saveAndClose(data);">
          <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>