<div class="dialog-wrap" #dialogWrap>
  <nb-card [nbSpinner]="loading" style="max-height: initial; margin: 0 auto;" class="small-header">
    <!-- <nb-card-header>{{ title }}</nb-card-header> -->
    <nb-card-header>
      <nav>
        {{ title }}
        <button nbButton status="danger" hero size="medium" (click)="dismiss('close')" style="float: right;" title="Trở về">
          <nb-icon pack="eva" icon="close-outline"></nb-icon>ESC
        </button>
      </nav>
    </nb-card-header>
    <nb-card-body *ngIf="content" [innerHTML]="content"></nb-card-body>
    <nb-card-body class="table-content" *ngIf="tableContent">
      <ngx-my-table [content]="tableContent"></ngx-my-table>
    </nb-card-body>
    <nb-card-footer *ngIf="footerContent || actions?.length > 0">
      <span *ngIf="footerContent" [innerHTML]="footerContent"></span>
      <div class="buttons-row">
        <button *ngFor="let item of actions" nbButton hero [status]="item?.status" [outline]="item?.outline" [disabled]="item?.disabled" (click)="onButtonClick(item);" [ngClass]="{'is-focus': item.focus}">
          <ng-container *ngIf="item?.rightIcon; else elseIcon">
            {{ item.label }}<nb-icon *ngIf="item?.rightIcon" [pack]="'eva'" [icon]="item.rightIcon"></nb-icon>
          </ng-container>
          <ng-template #elseIcon>
            <nb-icon *ngIf="item?.icon" [pack]="'eva'" [icon]="item.icon"></nb-icon>{{ item.label }}
          </ng-template>          
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>