<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card [nbSpinner]="isProcessing" class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Cơ hội' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <label class="label label-with-border-below">{{'Common.contactInfo' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">
                            <div class="col-sm-12">
                                <nb-tabset>
                                    <nb-tab tabTitle="{{'Common.mainInfo' | translate | headtitlecase}} (*)">
                                        <!-- <label class="label label-with-border-below">{{'Common.mainInfo' | translate | headtitlecase}}</label> -->
                                        <div class="row">
                                            <input type="hidden" formControlName="Code">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="Customer" label="Voucher.organizationOrPersornal" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [customIcons]="objectControlIcons" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="Customer" [select2Option]="select2OptionForContact" (selectChange)="onCustomerChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="CustomerName" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="CustomerName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="CustomerPhone" label="Common.phone" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="CustomerPhone" placeholder="{{formItem.get('CustomerPhone').placeholder || ('Common.phone' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="CustomerIdentifiedNumber" label="Common.identifiedNumber" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="CustomerIdentifiedNumber" placeholder="{{'Common.identifiedNumber' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="CustomerAddress" label="Common.address" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="CustomerAddress" placeholder="{{formItem.get('CustomerAddress').placeholder || ('Common.address' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="CustomerEmail" label="Common.email" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="CustomerEmail" placeholder="{{formItem.get('CustomerEmail').placeholder || ('Common.email' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                    </nb-tab>
                                    <nb-tab tabTitle="{{'Cộng tác viên' | translate | headtitlecase}}">
                                        <!-- <label class="label label-with-border-below">{{'Common.contact' | translate | headtitlecase}}</label> -->
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="Publisher" label="Common.contact" [array]="array" [index]="i" [allowCopy]="false" [customIcons]="contactControlIcons">
                                                    <ngx-select2 formControlName="Publisher" [select2Option]="select2OptionForPublisher" (selectChange)="onPublisherChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="PublisherName" label="Common.name" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="PublisherName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="PublisherPhone" label="Common.phone" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="PublisherPhone" placeholder="{{formItem.get('PublisherPhone').placeholder || ('Common.phone' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="PublisherIdentifiedNumber" label="Common.identifiedNumber" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="PublisherIdentifiedNumber" placeholder="{{'Common.identifiedNumber' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="PublisherAddress" label="Common.address" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="PublisherAddress" placeholder="{{formItem.get('PublisherAddress').placeholder || ('Common.address' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="PublisherEmail" label="Common.email" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="PublisherEmail" placeholder="{{formItem.get('PublisherEmail').placeholder || ('Common.email' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                    </nb-tab>

                                    <nb-tab tabTitle="{{'Giao vận' | translate | headtitlecase}}">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DirectReceiver" label="Người trực tiếp nhận hàng" [array]="array" [index]="i" [allowCopy]="false" [customIcons]="contactControlIcons">
                                                    <ngx-select2 formControlName="DirectReceiver" [select2Option]="select2OptionForContact" (selectChange)="onDirectReceiverChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DirectReceiverName" label="Common.name" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="DirectReceiverName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DirectReceiverPhone" label="Common.phone" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="DirectReceiverPhone" placeholder="{{'Common.phone' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DirectReceiverEmail" label="Common.email" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="DirectReceiverEmail" placeholder="{{'Common.email' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DeliveryProvince" label="Địa chỉ nhận hàng, mục tiêu phải đến: Tỉnh/TP" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="DeliveryProvince" [select2Option]="makeSelect2Option(select2OptionForProvince, formItem)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DeliveryDistrict" label="Common.district" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="DeliveryDistrict" [select2Option]="makeSelect2Option(select2OptionForDistrict, formItem)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DeliveryWard" label="Common.ward" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="DeliveryWard" [select2Option]="makeSelect2Option(select2OptionForWard, formItem)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DeliveryAddress" label="Địa chỉ nhận hàng" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="DeliveryAddress" placeholder="{{'Số nhà, tên đường...' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-9">
                                                <ngx-form-group [formGroup]="formItem" name="DeliveryMapLink" label="Link bản đồ" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="DeliveryMapLink" placeholder="{{'Link bản đồ...' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DeliveryCost" label="Phí vận chuyển" [array]="array" [index]="i" [allowCopy]="false">
                                                    <input type="text" nbInput fullWidth formControlName="DeliveryCost" placeholder="{{'Phí vận chuyển' | translate | headtitlecase}}" class="align-right" currencyMask [options]="toMoneyCurencyFormat" (focus)="currencyMaskFocus($event, toMoneyCurencyFormat)" (keydown)="currencyMastKeydown($event, toMoneyCurencyFormat)" (paste)="onPasteNumber($event, toMoneyCurencyFormat)">
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                    </nb-tab>
                                </nb-tabset>
                            </div>
                        </div>

                        <div style="display: none">
                            <label class="label label-with-border-below">{{'Common.receivedAndPayment' | translate | headtitlecase}}</label>
                            <div class="row dashed-radius-border">
                                <div class="col-sm-3">
                                    <ngx-form-group [formGroup]="formItem" name="CustomerBankName" label="Bank.name" [array]="array" [index]="i" [allowCopy]="false">
                                        <input type="text" nbInput fullWidth formControlName="CustomerBankName" placeholder="{{'Bank.name' | translate | headtitlecase}}">
                                    </ngx-form-group>
                                </div>
                                <div class="col-sm-6">
                                    <ngx-form-group [formGroup]="formItem" name="CustomerBankCode" label="Bank.code" [array]="array" [index]="i" [allowCopy]="false">
                                        <input type="text" nbInput fullWidth formControlName="CustomerBankCode" placeholder="{{'Bank.code' | translate | headtitlecase}}">
                                    </ngx-form-group>
                                </div>
                                <div class="col-lg-3">
                                    <div class="row">
                                        <!--<div class="col-lg-12">
                                            <ngx-form-group [formGroup]="formItem" name="DateOfOpportunity" label="{{validateVoucherDate(formItem.get('DateOfOpportunity'), 'Ngày tạo')}}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                <input disabled type="text" nbInput fullWidth formControlName="DateOfOpportunity" placeholder="{{'Ngày tạo' | translate | headtitlecase}}">
                                            </ngx-form-group>
                                        </div>-->
                                        <!-- <div class="col-lg-6">
                                        <ngx-form-group [formGroup]="formItem" name="RequireInvoice" label="Yêu cầu xuất hóa đơn" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <nb-checkbox formControlName="RequireInvoice" nbTooltip="Yêu cầu người bán xuất hóa đơn VAT">Y/C xuất hóa đơn</nb-checkbox>
                                        </ngx-form-group>
                                    </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12" *ngIf="formItem.get('RelativeVouchers').value?.length > 0">
                                <div class="form-group">
                                    <label class="label"><span class="valid">{{'Common.relativeVoucher' | translate | headtitlecase}}: </span></label>
                                    <div>
                                        <span class="tag" *ngFor="let relationVoucher of formItem.get('RelativeVouchers').value" nbTooltip="{{relationVoucher.typeMap?.text}}: {{relationVoucher | objectstext}}">
                                            <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.typeMap?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
                                            <nb-icon pack="eva" icon="close-outline" class="close-btn" (click)="removeRelativeVoucher(formItem, relationVoucher)"></nb-icon>
                                        </span>
                                    </div>
                                    <div class="break"></div>
                                </div>
                            </div>

                            <!--<div class="col-sm-9">
                                <ngx-form-group [formGroup]="formItem" name="DeliveryAddress" label="Sales.deliveryAddress" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="DeliveryAddress" placeholder="{{'Sales.deliveryAddress' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>-->
                            <!-- <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="RelativeVouchers" label="Common.relationVoucher" [array]="array" [index]="i" [allowCopy]="false">
                                    <button class="fit-control-button" [outline]="true" nbButton status="primary" fullWidth (click)="openRelativeVoucherChoosedDialog(formItem)" size="medium" hero>
                                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>Thêm chứng từ liên quan
                                    </button>
                                </ngx-form-group>
                            </div> -->

                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="Title" label="Common.title" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                    <input type="text" nbInput fullWidth formControlName="Title" placeholder="{{'Common.title' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Target" label="Mục tiêu theo đuổi" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                    <ngx-select2 formControlName="Target" [select2Option]="select2OptionForTarget"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Page" label="Collaborator.Page.label" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                    <ngx-select2 formControlName="Page" [select2Option]="select2OptionForPage" [data]="this.collaboratorService.pageList$ | async"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <!-- <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="RelativeVouchers" label="Common.relationVoucher" [array]="array" [index]="i" [allowCopy]="false">
                                    <button class="fit-control-button" [outline]="true" nbButton status="primary" fullWidth (click)="openRelativeVoucherChoosedDialog(formItem)" size="medium" hero>
                                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>Thêm chứng từ liên quan
                                    </button>
                                </ngx-form-group>
                            </div> -->
                            <!-- <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Employee" label="Common.employee" [array]="array" [index]="i" [allowCopy]="true">
                                    <ngx-select2 formControlName="Employee" [select2Option]="selectEmployeeOption"></ngx-select2>
                                </ngx-form-group>
                            </div> -->

                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <ngx-form-group [formGroup]="formItem" name="Note" label="Common.note" [array]="array" [index]="i" [allowCopy]="false">
                                                    <textarea class="scrollable-container" nbInput fullWidth formControlName="Note" placeholder="{{'Common.note' | translate | headtitlecase}}" rows="4"></textarea>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-12">
                                                <ngx-form-group [formGroup]="formItem" name="SubNote" label="Common.subNote" [array]="array" [index]="i" [allowCopy]="false">
                                                    <textarea class="scrollable-container" nbInput fullWidth formControlName="SubNote" placeholder="{{'Common.subNote' | translate | headtitlecase}}" rows="3"></textarea>
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="label">Bình luận</label>
                                            <div style="position: relative;">
                                                <ngx-comment [messages]="formItem.Comments" [opportunity]="formItem.get('Code').value" (moreBtnMessageClick)="onMoreBtnMessageClick($event)"></ngx-comment>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!-- Detail form -->
                        <label class="label">{{'Voucher.productOrservice' | translate | headtitlecase}}</label>
                        <div formArrayName="Details" class="form-details" [sortablejs]="formItem._details" [sortablejsOptions]="{ handle: '.sorting-handle' }">
                            <!-- <cdk-virtual-scroll-viewport #detailsViewport [itemSize]="240" style="height: 80vh;" class="scrollable-container"> -->

                            <div formArrayName="Details" class="form-details" [sortablejs]="getDetails(formItem)" [sortablejsOptions]="{ handle: '.sorting-handle' }">
                                <div class="form-detail-item" *ngFor="let detail of getDetails(formItem).controls; let ic=index" [formGroup]="detail">
                                    <hr>
                                    <div class="row">
                                        <div class="col-lg-2 col-md-2 col-sm-12 col-is-12">
                                            <div class="row">
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-is-3">
                                                    <ngx-form-group [hideLabel]="false" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'" [formGroup]="detail" name="No" label="STT" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false">
                                                        <input nbInput class="sorting-handle" title="Kéo thả để di chuyển" fullWidth formControlName="No" value="{{ic+1}}" readonly style="text-align: center; cursor: grab; width: 100%; height: 2.5rem; padding: 0;">
                                                    </ngx-form-group>
                                                    <div class="form-group">
                                                        <label class="label" *ngIf="getFieldValue(detail, 'Type') === 'CATEGORY'">Gở</label>
                                                        <button nbButton status="{{getFieldValue(detail, 'Type') !== 'CATEGORY' ? 'danger' : 'primary'}}" hero size="medium" (click)="removeDetailGroup(formItem, detail, ic)" style="width: 100%; margin-bottom: 3px; padding: 0">
                                                            <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div class="col-lg-9 col-md-9 col-sm-9 col-is-9">
                                                    <ngx-form-group [hideLabel]="false" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'" [formGroup]="detail" name="Image" label="Common.image" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false">
                                                        <ngx-files-input formControlName="Image" [config]="{style: {height: '6.9rem', padding: '0'}, thumbnailStype: {height: '5rem', width: '100%'}}"></ngx-files-input>
                                                    </ngx-form-group>
                                                    <div class="form-group" *ngIf="getFieldValue(detail, 'Type') === 'CATEGORY'">
                                                        <label class="label">Di chuyển</label>
                                                        <input type="text" nbInput fullWidth placeholder="Kéo để di chuyển" disabled class="sorting-handle" style="cursor: pointer;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-md-10 col-sm-12 col-is-12">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4 col-sm-12 col-is-12">
                                                    <div class="row">
                                                        <div class="col-lg-{{getFieldValue(detail, 'Type') !== 'CATEGORY' ? '4' : '12'}} col-sm-{{getFieldValue(detail, 'Type') !== 'CATEGORY' ? '3' : '12'}} col-is-12">
                                                            <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Type" label="Common.type" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                                                <ngx-select2 [data]="select2DataForType" formControlName="Type" (selectChange)="toMoney(formItem, detail, 'Type', ic)" [select2Option]="select2OptionForType"></ngx-select2>
                                                            </ngx-form-group>
                                                        </div>
                                                        <div class="col-lg-8 col-md-8 col-sm-9 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                            <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Product" label="Common.productOrService" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="true" [touchedValidate]="false" [customIcons]="getCustomIcons(i + ic)" [option]="{parentForm: formItem}">
                                                                <ngx-select2 formControlName="Product" [select2Option]="select2OptionForProduct" (selectChange)="onSelectProduct(detail, $event, formItem, detail)"></ngx-select2>
                                                            </ngx-form-group>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-12 col-is-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                            <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Quantity" label="Voucher.quantity" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false" [align]="'right'">
                                                                <input type="text" nbInput fullWidth formControlName="Quantity" placeholder="{{'Voucher.quantity' | translate | headtitlecase}}" class="align-right" (keyup)="toMoney(formItem, detail, 'Quantity', ic)" [inputMask]="towDigitsInputMask">
                                                            </ngx-form-group>
                                                        </div>
                                                        <div class="col-lg-2 col-md-2 col-sm-2 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                            <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Unit" label="Voucher.unit" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false" [customIcons]="unitCustomIcons">
                                                                <ngx-select2 formControlName="Unit" [data]="detail['UnitList'] || unitList" [select2Option]="select2OptionForUnit" (selectChange)="onSelectUnit(detail, $event, formItem)"></ngx-select2>
                                                            </ngx-form-group>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                            <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Price" label="Giá EU" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false" [align]="'right'">
                                                                <input type="text" nbInput fullWidth formControlName="Price" placeholder="{{'Voucher.price' | translate | headtitlecase}}" class="align-right" (keyup)="toMoney(formItem, detail, 'Price', ic)" currencyMask [options]="priceCurencyFormat" (focus)="currencyMaskFocus($event, priceCurencyFormat)" (keydown)="currencyMastKeydown($event, priceCurencyFormat)" (paste)="onPasteNumber($event, priceCurencyFormat)">
                                                            </ngx-form-group>
                                                        </div>
                                                        <div class="col-lg-3 col-md-3 col-sm-3 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                            <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="ToMoney" label="Voucher.toMoney" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [align]="'right'">
                                                                <input type="text" nbInput fullWidth formControlName="ToMoney" placeholder="Thành tiền" class="align-right" currencyMask [options]="toMoneyCurencyFormat" (keyup)="toMoney(formItem, detail, 'ToMoney', ic)" (focus)="currencyMaskFocus($event, toMoneyCurencyFormat)" (keydown)="currencyMastKeydown($event, toMoneyCurencyFormat)" (paste)="onPasteNumber($event, toMoneyCurencyFormat)">
                                                            </ngx-form-group>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-is-12" *ngIf="getFieldValue(detail, 'Type') === 'CATEGORY'">
                                                            <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Description" label="Common.description" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                                                <textarea class="scrollable-container" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}" rows="1"></textarea>
                                                            </ngx-form-group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="row">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                            <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="IsRelativeToPublisher" label="Có liên quan tới CTV" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [touchedValidate]="false">
                                                                <div nbinput class="ng-untouched ng-pristine ng-valid input-full-width size-medium status-basic shape-rectangle nb-transition" style="height: 2.5rem">
                                                                    <nb-checkbox formControlName="IsRelativeToPublisher">
                                                                        <label class="label"><span class="valid">{{'Có liên quan tới CTV' | translate | headtitlecase}}</span></label>
                                                                    </nb-checkbox>
                                                                </div>
                                                            </ngx-form-group>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-12 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                    <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Description" label="Common.description" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [touchedValidate]="false">
                                                        <textarea class="scrollable-container" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}" rows="1"></textarea>
                                                    </ngx-form-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-lg-2 col-md-4 col-sm-12 col-is-12">
                                        <button nbButton status="success" (click)="addDetailFormGroup(formItem)" size="medium" hero [disabled]="isProcessing">
                                            <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>{{'Sales.addProduct' | translate | headtitlecase}}
                                        </button>
                                    </div>
                                    <div class="col-lg-3 col-md-8 col-sm-12 col-is-12"></div>
                                    <div class="col-lg-7 col-md-12 col-sm-12 col-is-12">
                                        <div class="row">
                                            <div class="col-md-2 col-sm-4 col-is-4"></div>
                                            <div class="col-md-2 col-sm-4 col-is-4"></div>
                                            <div class="col-md-2 col-sm-4 col-is-4"></div>
                                            <div class="col-md-3 col-sm-4 col-is-4" style="text-align: right; font-weight: bold;">{{'Voucher.total' | translate | headtitlecase}}</div>
                                            <!-- <div class="col-md-3 col-sm-8 col-is-8" style="text-align: right; font-weight: bold; padding-right: 1.7rem;">{{formItem.get('_total').value | currency:'VND':'':'1.2-2'}}</div> -->
                                            <div class="col-md-3 col-sm-8 col-is-8" style="text-align: right; font-weight: bold; padding-right: 1.7rem;">{{formItem.get('_total').value | currency:'VND'}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- </cdk-virtual-scroll-viewport> -->
                            <hr>
                            <div class="row" style="display: flex; align-items: center">
                                <!-- <div class="buttons-row" style="flex: 1">
                                    <button nbButton status="success" (click)="addDetailFormGroup(formItem)" size="medium" hero [disabled]="isProcessing">
                                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>{{'Common.addProduct' | translate | headtitlecase}}
                                    </button>
                                </div> -->
                                <!-- <div style="font-weight: bold; padding-right: 28px;">{{'Voucher.total' | translate | headtitlecase}}: {{formItem.get('_total').value | currency:'VND'}}</div> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12" style="text-align: right;">

                            </div>
                        </div>
                        <!-- End Detail form -->

                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview(formItem)" [disabled]="!form.valid || isProcessing">
                            <nb-icon nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="save()">{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>