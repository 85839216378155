<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card [nbSpinner]="isProcessing" class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Báo Cáo Doanh Số' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase) + ' (ID: '+(formItem.value.Code || 'NEW')+')'" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div *ngIf="formItem.__state" class="component-state">
                        <nb-tag [status]="formItem.__state.status" appearance="filled" [text]="formItem.__state | objecttext"></nb-tag>
                    </div>
                    <div [formGroup]="formItem">
                        <label class="label label-with-border-below">{{'Common.contactInfo' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">
                            <div class="col-sm-12">
                                <nb-tabset>
                                    <nb-tab tabTitle="{{'Common.mainInfo' | translate | headtitlecase}} (*)">
                                        <div class="row">
                                            <input type="hidden" formControlName="Code">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="Object" label="NV Sales" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [customIcons]="objectControlIcons" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="Object" [select2Option]="select2OptionForContact" (selectChange)="onObjectChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectName" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectPhone" label="Common.phone" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectPhone" placeholder="{{formItem.get('ObjectPhone').placeholder || ('Common.phone' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectIdentifiedNumber" label="Common.identifiedNumber" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectIdentifiedNumber" placeholder="{{'Common.identifiedNumber' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectAddress" label="Common.address" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectAddress" placeholder="{{formItem.get('ObjectAddress').placeholder || ('Common.address' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectEmail" label="Common.email" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectEmail" placeholder="{{formItem.get('ObjectEmail').placeholder || ('Common.email' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                    </nb-tab>
                                </nb-tabset>
                            </div>
                        </div>

                        <label class="label label-with-border-below">{{'Common.receivedAndPayment' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="ObjectBankName" label="Bank.name" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ObjectBankName" placeholder="{{'Bank.name' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="ObjectBankCode" label="Bank.code" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ObjectBankCode" placeholder="{{'Bank.code' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-lg-3">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <ngx-form-group [formGroup]="formItem" name="DateOfReport" label="{{validateVoucherDate(formItem.get('DateOfReport'), 'Ngày báo cáo')}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                                            <input type="text" [owlDateTime]="DateOfReport" [owlDateTimeTrigger]="DateOfReport" nbInput fullWidth formControlName="DateOfReport" placeholder="{{'Ngày báo cáo' | translate | headtitlecase}}">
                                            <owl-date-time #DateOfReport [startAt]="formItem.get('DateOfReport')?.value || this.cms.lastVoucherDate"></owl-date-time>
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-6">
                                        <ngx-form-group [formGroup]="formItem" name="Page" label="Collaborator.Page.label" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                                            <ngx-select2 formControlName="Page" [select2Option]="select2OptionForPage" [data]="this.collaboratorService.pageList$ | async"></ngx-select2>
                                        </ngx-form-group>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12" *ngIf="formItem.get('RelativeVouchers').value?.length > 0">
                                <div class="form-group">
                                    <label class="label"><span class="valid">{{'Common.relativeVoucher' | translate | headtitlecase}}: </span></label>
                                    <div>
                                        <span class="tag" *ngFor="let relationVoucher of formItem.get('RelativeVouchers').value" nbTooltip="{{relationVoucher.typeMap?.text}}: {{relationVoucher | objectstext}}">
                                            <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.typeMap?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
                                            <nb-icon pack="eva" icon="close-outline" class="close-btn" (click)="removeRelativeVoucher(formItem, relationVoucher)"></nb-icon>
                                        </span>
                                    </div>
                                    <div class="break"></div>
                                </div>
                            </div>

                            <div class="col-sm-9">
                                <ngx-form-group [formGroup]="formItem" name="Title" label="Common.title" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                    <input type="text" nbInput fullWidth formControlName="Title" placeholder="{{'Common.title' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-lg-3">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <ngx-form-group [formGroup]="formItem" name="DateOfBegin" label="{{validateVoucherDate(formItem.get('DateOfBegin'), 'Từ ngày')}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                                            <input type="text" [owlDateTime]="DateOfBegin" [owlDateTimeTrigger]="DateOfBegin" nbInput fullWidth formControlName="DateOfBegin" placeholder="{{'Từ ngày' | translate | headtitlecase}}">
                                            <owl-date-time #DateOfBegin [startAt]="formItem.get('DateOfBegin')?.value || this.cms.lastVoucherDate"></owl-date-time>
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-6">
                                        <ngx-form-group [formGroup]="formItem" name="DateOfEnd" label="{{validateVoucherDate(formItem.get('DateOfEnd'), 'Đến ngày')}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                                            <input type="text" [owlDateTime]="DateOfEnd" [owlDateTimeTrigger]="DateOfEnd" nbInput fullWidth formControlName="DateOfEnd" placeholder="{{'Đến ngày' | translate | headtitlecase}}">
                                            <owl-date-time #DateOfEnd [startAt]="formItem.get('DateOfEnd')?.value || this.cms.lastVoucherDate"></owl-date-time>
                                        </ngx-form-group>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="Note" label="Common.note" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <textarea class="scrollable-container" nbInput fullWidth formControlName="Note" placeholder="{{'Common.note' | translate | headtitlecase}}" rows="4"></textarea>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="SubNote" label="Common.subNote" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <textarea class="scrollable-container" nbInput fullWidth formControlName="SubNote" placeholder="{{'Common.subNote' | translate | headtitlecase}}" rows="3"></textarea>
                                </ngx-form-group>
                            </div>

                        </div>

                        <!-- Detail form -->
                        <label class="label">{{'Voucher.productOrservice' | translate | headtitlecase}}</label>
                        <div class="row">
                            <div class="col-sm-12" style="text-align: right;">
                                <ngx-ag-dynamic-list [titleNowrap]="true" #details [title]="'Chi tiết'" [columnDefs]="columnDefs" [rowModelType]="'clientSide'" [parentObject]="formItem" [parentData]="formItem.value" [rowData]="formItem.__Details" [idKey]="['No']" (onReady)="onGridReady($event)" (onNodesSelected)="onDetialsSelected($event)" (onComponentInit)="onGridInit($event)" style="display: block; height: 500px"></ngx-ag-dynamic-list>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12" style="text-align: right;">

                            </div>
                        </div>
                        <!-- End Detail form -->

                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview(formItem)" [disabled]="!form.valid || isProcessing">
                            <nb-icon nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="save()">{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>