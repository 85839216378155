import { ContactGroupModel } from '../../../../models/contact.model';
import { IdTextModel } from '../../../../models/common.model';
import { Component, OnInit } from '@angular/core';
import { ContactModel, ContactDetailTypeModel, ContactDetailModel } from '../../../../models/contact.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ApiService } from '../../../../services/api.service';
import { NbToastrService, NbDialogService, NbDialogRef } from '@nebular/theme';
import { CommonService } from '../../../../services/common.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DataManagerFormComponent } from '../../../../lib/data-manager/data-manager-form.component';
import { RootServices } from '../../../../services/root.services';

@Component({
  selector: 'ngx-collaborator-publisher-form',
  templateUrl: './collaborator-publisher-form.component.html',
  styleUrls: ['./collaborator-publisher-form.component.scss'],
})
export class CollaboratorPublisherFormComponent extends DataManagerFormComponent<ContactModel> implements OnInit {

  componentName: string = 'CollaboratorPublisherFormComponent';
  idKey = 'Code';
  apiPath = '/collaborator/contacts';
  // baseFormUrl = '/contact/contact/form';

  // organizationList: ContactModel[];
  select2Option = {
    placeholder: 'Tổ chức...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    multiple: true,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
    ajax: {
      // url: params => {
      //   return this.apiService.buildApiUrl('/contact/contacts', { filter_Name: params['term'] });
      // },
      transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
        console.log(settings);
        const params = settings.data;
        this.apiService.getPromise('/contact/contacts', { filter_Name: params['term'] }).then(rs => {
          success(rs);
        }).catch(err => {
          console.error(err);
          failure();
        });
      },
      delay: 300,
      processResults: (data: any, params: any) => {
        console.info(data, params);
        return {
          results: data.map(item => {
            item['id'] = item['Code'];
            item['text'] = item['Name'];
            return item;
          }),
        };
      },
    },
  };

  select2OptionForPage = {
    placeholder: this.cms.translateText('Common.page') + '...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    multiple: false,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    ajax: {
      // url: params => {
      //   return this.apiService.buildApiUrl('/zalooa/official-accounts', { filter_Name: params['term'], select: 'id=>Code,text=>Name' });
      // },
      transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
        console.log(settings);
        const params = settings.data;
        this.apiService.getPromise('/zalooa/official-accounts', { filter_Name: params['term'], select: 'id=>Code,text=>Name' }).then(rs => {
          success(rs);
        }).catch(err => {
          console.error(err);
          failure();
        });
      },
      delay: 300,
      processResults: (data: any, params: any) => {
        console.info(data, params);
        return {
          results: data.map(item => {
            // item['id'] = item['Code'];
            // item['text'] = item['Name'];
            return item;
          }),
        };
      },
    },
  };

  groupList: ContactGroupModel[];
  select2GroupsOption = {
    placeholder: this.cms.translateText('Common.group') + '...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    multiple: true,
    tags: false,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  typeList: ContactDetailTypeModel[];
  select2OptionForType = {
    placeholder: this.cms.translateText('Common.type') + '...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    tags: true,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
  };

  platformList: IdTextModel[] = [
    { id: 'PROBOXONE', text: 'ProBox One' },
    { id: 'ZALO', text: 'Zalo' },
    { id: 'FACEBOOK', text: 'Facebook' },
    { id: 'TELEGRAM', text: 'Telegram' },
  ];
  select2OptionForPlatForm = {
    placeholder: this.cms.translateText('Common.platform') + '...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    tags: true,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };
  select2OptionForLocationType = {
    placeholder: this.cms.translateText('Common.type') + '...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    tags: true,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    data: [
      { id: 'HOME', text: 'Nhà riêng' },
      { id: 'WORK', text: 'Nơi làm việc' },
      { id: 'OTHER', text: 'Khác' },
    ]
  };
  select2ContactType = {
    placeholder: this.cms.translateText('Cá nhân/Tổ chức') + '...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    tags: true,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    data: [
      { id: 'PERSON', text: 'Cá nhân' },
      { id: 'ORGANIZATION', text: 'Tổ chức (Doanh nghiệp/HKD/Cơ quan)' },
    ]
  };
  select2ContactTitle = {
    placeholder: this.cms.translateText('Danh xưng') + '...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    tags: true,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    data: [
      { id: 'Anh', text: 'Anh ' },
      { id: 'Chị', text: 'Chị ' },
      { id: 'Quý', text: 'Quý ' },
    ]
  };
  select2BaseOption = {
    placeholder: this.cms.translateText('Common.select') + '...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    // tags: true,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  makeSelect2Option(select2Options: any, formGroup: FormGroup) {
    return {
      ...select2Options,
      formGroup
    }
  }
  select2OptionForProvince = {
    placeholder: 'Chọn tỉnh/TP...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    ajax: {
      transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
        console.log(settings);
        const params = settings.data;
        this.apiService.getPromise('/general/locations', { token: this.apiService?.token?.access_token, select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': params['term'], eq_Type: '[PROVINCE,CITY]' }).then(rs => {
          success(rs);
        }).catch(err => {
          console.error(err);
          failure();
        });
      },
      delay: 300,
      processResults: (data: any, params: any) => {
        // console.info(data, params);
        return {
          results: data
        };
      },
    },
  };
  select2OptionForDistrict = {
    placeholder: 'Chọn quận/huyện...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    ajax: {
      url: (params, options: any) => {
        const formGroup = options?.formGroup;
        const provice = formGroup && this.cms.getObjectId(formGroup.get('Province').value);
        return this.apiService.buildApiUrl('/general/locations', { token: this.apiService?.token?.access_token, select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': params['term'], eq_Type: '[CDISTRICT,PDISTRICT,BURG,CITYDISTRICT]', eq_Parent: provice });
      },
      delay: 300,
      processResults: (data: any, params: any) => {
        console.info(data, params);
        return {
          results: data
        };
      },
    },
  };

  select2OptionForWard = {
    placeholder: 'Chọn phường/xã/thị trấn...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    ajax: {
      url: (params: any, options: any) => {
        const formGroup = options?.formGroup;
        const district = formGroup && this.cms.getObjectId(formGroup.get('District').value);
        return this.apiService.buildApiUrl('/general/locations', { token: this.apiService?.token?.access_token, select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': params['term'], eq_Type: '[VILLAGE,WARD,TOWNS]', eq_Parent: district });
      },
      delay: 300,
      processResults: (data: any, params: any) => {
        // console.info(data, params);
        return {
          results: data
        };
      },
    },
  };


  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastrService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref: NbDialogRef<CollaboratorPublisherFormComponent>,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastrService, dialogService, cms);
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
    // if (this.inputId) {
    //   this.mode = 'dialog';
    // }
  }

  async init() {
    this.typeList = (await this.apiService.getPromise<ContactDetailTypeModel[]>('/contact/detailTypes')).map(type => ({ ...type, id: type.Code, text: type.Name }));
    this.groupList = await this.apiService.getPromise<ContactGroupModel[]>('/contact/groups', { onlyIdText: true });
    return super.init();
  }

  async formLoad(formData: ContactModel[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: ContactModel) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    });

  }

  // getRequestId(callback: (id?: string[]) => void) {
  //   callback(this.inputId);
  // }

  /** Execute api get */
  executeGet(params: any, success: (resources: ContactModel[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includeOrganizations'] = true;
    params['includeGroups'] = true;
    // params['includeDetails'] = true;
    // params['includeOutsideReferences'] = true;
    // params['includeLocations'] = true;
    super.executeGet(params, success, error);
  }

  makeNewFormGroup(data?: ContactModel): FormGroup {
    const newForm = this.formBuilder.group({
      Code: { value: null, disabled: true },
      Avatar: [''],
      ContactType: ['', Validators.required],
      Name: ['', Validators.required],
      FirstName: [''],
      LastName: [''],
      Phone: [''],
      CallcenterNumber: [''],
      // Phone2: [''],
      // Phone3: [''],
      Email: [''],
      Address: [''],
      Title: ['', Validators.required],
      Province: [''],
      District: [''],
      Ward: [''],
      ShortName: [''],
      // Sex: [''],
      Note: [''],
      Organizations: [''],
      Groups: [''],
      MapLink: [],
      // Details: this.formBuilder.array([]),
      // OutsideReferences: this.formBuilder.array([]),
      // Locations: this.formBuilder.array([]),
    });
    if (data) {
      newForm.patchValue(data);
      newForm.get('Code').disable();
    }
    // this.disabledControls.push(newForm.get('Code'));
    return newForm;
  }
  onAddFormGroup(index: number, newForm: FormGroup, formData?: ContactModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }
  goback(): false {
    super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/contact/contact/list']);
    } else {
      this.ref.close();
      // this.dismiss();
    }
    return false;
  }
  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }

  onAfterCreateSubmit(newFormData: ContactModel[]) {
    super.onAfterCreateSubmit(newFormData);
    // this.minierpService.reloadCache();
  }
  onAfterUpdateSubmit(newFormData: ContactModel[]) {
    super.onAfterUpdateSubmit(newFormData);
    // this.minierpService.reloadCache();
  }

  dismiss() {
    this.ref.close();
  }


}
