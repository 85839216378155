<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Phiếu chốt KPI' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Object" label="Voucher.organizationOrPersornal" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                  <ngx-select2 formControlName="Object" [select2Option]="select2OptionForContact" (selectChange)="onObjectChange(formItem, $event, i)"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="ObjectName" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="ObjectName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-md-3">
                <ngx-form-group [formGroup]="formItem" name="Code" label="ID" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="Code" placeholder="{{'ID' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="ObjectPhone" label="Common.phone" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="ObjectPhone" placeholder="{{formItem.get('ObjectPhone').placeholder || ('Common.phone' | translate | headtitlecase)}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="ObjectAddress" label="Common.address" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="ObjectAddress" placeholder="{{formItem.get('ObjectAddress').placeholder || ('Common.address' | translate | headtitlecase)}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="ObjectEmail" label="Common.email" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="ObjectEmail" placeholder="{{formItem.get('ObjectEmail').placeholder || ('Common.email' | translate | headtitlecase)}}">
                </ngx-form-group>
              </div>


              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Title" label="Tiêu đề" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Title" placeholder="{{'Tiêu đề' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Cycle" label="Chu kỳ" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                  <ngx-select2 formControlName="Cycle" [select2Option]="select2OptionForCycle"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="DateOfEnd" label="Chốt đến ngày" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                  <input type="text" [owlDateTime]="DateOfEnd" [owlDateTimeTrigger]="DateOfEnd" nbInput fullWidth formControlName="DateOfEnd" placeholder="{{'Chốt đến ngày' | translate | headtitlecase}}">
                  <owl-date-time #DateOfEnd></owl-date-time>
                </ngx-form-group>
              </div>
              <!-- <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Code" label="ID" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Code" placeholder="{{'ID' | translate | headtitlecase}}">
                </ngx-form-group>
              </div> -->
              <!-- <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Title" label="Mô tả" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Mô tả' | translate | headtitlecase}}">
                </ngx-form-group>
              </div> -->
              <!-- <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="LevelDistributedIndicator" label="Chỉ số phân bổ vượt cấp" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <ngx-select2 formControlName="LevelDistributedIndicator" [select2Option]="select2OptionForIndicator" [status]="'success'" nbTooltip="Chỉ số sẽ được lấy giá trị phân bổ cho từng nhóm KPI bên dưới"></ngx-select2>
                </ngx-form-group>
              </div> -->
              <!-- <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Formular" label="Công thức" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <textarea type="text" nbInput fullWidth formControlName="Formular" class="scrollable-container" style="max-height: 10rem;" [mentionConfig]="mentionConfig" placeholder="{{'Công thức' | translate | headtitlecase}}"></textarea>
                </ngx-form-group>
              </div> -->
            </div>

            <hr>
            <div class="preview" *ngIf="formItem.value?.Details && formItem.value.Details.length > 0">
              <ngx-collaborator-kpi-award-info [data]="[formItem.value]"></ngx-collaborator-kpi-award-info>
            </div>

          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup() && false" hero style="float: left;">Thêm cái nữa</button>
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose() && false">Lưu & Đóng</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>