import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NbDialogService, NbToastrService, NbThemeService, NbDialogRef } from "@nebular/theme";
import { ColDef } from "@ag-grid-community/core";
import { filter, take } from "rxjs/operators";
import { AppModule } from "../../../../../../app.module";
import { AgDateCellRenderer } from "../../../../../../lib/custom-element/ag-list/cell/date.component";
import { AgTextCellRenderer } from "../../../../../../lib/custom-element/ag-list/cell/text.component";
import { agMakeCommandColDef } from "../../../../../../lib/custom-element/ag-list/column-define/command.define";
import { agMakeCurrencyColDef } from "../../../../../../lib/custom-element/ag-list/column-define/currency.define";
import { agMakeSelectionColDef } from "../../../../../../lib/custom-element/ag-list/column-define/selection.define";
import { agMakeStateColDef } from "../../../../../../lib/custom-element/ag-list/column-define/state.define";
import { agMakeTagsColDef } from "../../../../../../lib/custom-element/ag-list/column-define/tags.define";
import { AgSelect2Filter } from "../../../../../../lib/custom-element/ag-list/filter/select2.component.filter";
import { AgGridDataManagerListComponent } from "../../../../../../lib/data-manager/ag-grid-data-manger-list.component";
import { PageModel } from "../../../../../../models/page.model";
import { ApiService } from "../../../../../../services/api.service";
import { CommonService } from "../../../../../../services/common.service";
import { RootServices } from "../../../../../../services/root.services";
import { CollaboratorService } from "../../../../collaborator.service";
import { CollaboratorPublisherListCommissionReportFormComponent } from "../list-commission-report-form/list-commission-report-form.component";
import { Model } from "../../../../../../models/model";
import { CollaboratorPublisherListCommissionReportPrintComponent } from "../list-commission-report-print/list-commission-report-print.component";
import { HttpErrorResponse } from "@angular/common/http";
import { agMakeAccCurrencyColDef } from "../../../../../../lib/custom-element/ag-list/column-define/acc-currency.define";
import { AgCheckboxCellRenderer } from "../../../../../../lib/custom-element/ag-list/cell/checkbox.component";
import { agMakeTextColDef } from "../../../../../../lib/custom-element/ag-list/column-define/text.define";
import { agMakeNumberColDef } from "../../../../../../lib/custom-element/ag-list/column-define/number.define";
import { CollaboratorStateMap } from "../../../../collaborator.state.map";


@Component({
  selector: 'ngx-publisher-list-commission-report-list',
  templateUrl: './list-commission-report-list.component.html',
  styleUrls: ['./list-commission-report-list.component.scss']
})
export class CollaboratorPublisherListCommissionReportListComponent extends AgGridDataManagerListComponent<Model, CollaboratorPublisherListCommissionReportFormComponent> implements OnInit {

  componentName: string = 'CollaboratorPublisherListCommissionReportListComponent';
  apiPath = '/collaborator/report/publisher/list-commissions';
  formPath = '/collaborator/report/publisher/list-commission/form';
  idKey = ['Code'];

  // Use for load settings menu for context
  // feature = {
  //   Module: { id: 'Purchase', text: 'Thu mua' },
  //   Feature: { id: 'PurchaseOrder', text: 'Đơn đặt mua hàng' }
  // };

  printDialog = CollaboratorPublisherListCommissionReportPrintComponent;
  // formDialog = CollaboratorPublisherListCommissionReportFormComponent;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  @Input() gridHeight = '100%';

  queue = [];


  // static processingMap = AppModule.processMaps.common;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<CollaboratorPublisherListCommissionReportListComponent>,
    public collaboratorService: CollaboratorService,
    public datePipe: DatePipe,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    return super.init().then(async state => {
      await this.cms.waitForLanguageLoaded();



      // Add page choosed
      this.collaboratorService.pageList$.pipe(filter(f => f && f.length > 0), take(1)).toPromise().then(pageList => {
        this.actionButtonList.unshift({
          type: 'select2',
          name: 'page',
          status: 'success',
          label: 'Select page',
          icon: 'plus',
          title: this.cms.textTransform(this.cms.translate.instant('Collaborator.Page.title', { action: this.cms.translateText('Common.choose'), definition: '' }), 'head-title'),
          size: 'medium',
          select2: {
            data: pageList,
            option: {
              placeholder: 'Chọn trang...',
              allowClear: true,
              width: '100%',
              dropdownAutoWidth: true,
              minimumInputLength: 0,
              keyMap: {
                id: 'id',
                text: 'text',
              },
            }
          },
          asyncValue: this.collaboratorService.currentpage$,
          change: (value: any, option: any) => {
            this.onChangePage(value);
          },
          disabled: () => {
            return false;
          },
          click: () => {
            // this.gotoForm();
            return false;
          },
        });
      });

      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Mô tả',
          field: 'Description',
          width: 300,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          ...agMakeAccCurrencyColDef(this.cms),
          headerName: 'Doanh số',
          field: 'GenerateCredit',
          // pinned: 'right',
          width: 140,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Đã thu được',
          field: 'GeneratePaidAmount',
          // pinned: 'right',
          width: 200,
          valueGetter: (params) => {
            if (params?.data?.GeneratePaidAmount) {
              return this.cms.currencyPipe.transform(params.data.GeneratePaidAmount, 'VND') + ' (' + (params.data.GeneratePaidAmount / params.data.GenerateCredit * 100).toFixed(2) + '%)'
            }
            return '';
          }
        },
        {
          headerName: 'Đã thanh toán',
          field: 'IsFullPaid',
          width: 150,
          filter: 'agTextColumnFilter',
          cellClass: ['ag-cell-items-center', 'ag-cell-justify-end'],
          type: 'rightAligned',
          cellRenderer: AgCheckboxCellRenderer,
          cellRendererParams: {
            disabled: true,
          },
          headerComponentParams: { enabledCheckbox: true }
        },
        {
          ...agMakeAccCurrencyColDef(this.cms),
          headerName: 'CK Cơ bản',
          field: 'BasicCommissionAmount',
          width: 150,
          // pinned: 'right',
        },
        {
          ...agMakeAccCurrencyColDef(this.cms),
          headerName: 'CK Nâng cao',
          field: 'AdvanceCommissionAmount',
          width: 150,
          // pinned: 'right',
        },
        {
          ...agMakeAccCurrencyColDef(this.cms),
          headerName: 'CK Add-on',
          field: 'AddonCommissionAmount',
          width: 150,
          // pinned: 'right',
        },
        {
          ...agMakeNumberColDef(this.cms),
          headerName: 'Tổng số CTV',
          field: 'NumOfReports',
          width: 150,
          // pinned: 'right',
        },
        {
          ...agMakeNumberColDef(this.cms),
          headerName: 'Đã gửi yêu cầu',
          field: 'NumOfConfirmationRequestedReports',
          width: 150,
          // pinned: 'right',
        },
        {
          ...agMakeNumberColDef(this.cms),
          headerName: 'Đã xác nhận',
          field: 'NumOfConfirmedReports',
          width: 150,
          // pinned: 'right',
        },
        {
          ...agMakeNumberColDef(this.cms),
          headerName: 'Đã chốt',
          field: 'NumOfApprovedReports',
          width: 150,
          // pinned: 'right',
        },
        {
          ...agMakeAccCurrencyColDef(this.cms),
          headerName: 'Tổng CK',
          field: 'TotalCommissionAmount',
          width: 150,
          pinned: 'right',
        },
        {
          ...agMakeAccCurrencyColDef(this.cms),
          headerName: 'CK thực nhận',
          field: 'RealTotalCommissionAmount',
          width: 150,
          pinned: 'right',
        },
        {
          ...agMakeTagsColDef(this.cms, (tag) => {
            this.cms.previewVoucher(tag.type, tag.id);
          }),
          headerName: 'Chứng từ liên quan',
          field: 'RelativeVouchers',
          width: 330,
        },
        {
          headerName: 'Ngày chứng từ',
          field: 'DateOfVoucher',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          headerName: 'Người tạo',
          field: 'Creator',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn người tạo...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'];
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Từ ngày',
          field: 'DateOfBegin',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          headerName: 'Đến ngày',
          field: 'DateOfEnd',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          headerName: 'Ngày tạo',
          field: 'Created',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        // {
        //   ...agMakeTextColDef(this.cms),
        //   headerName: 'Trạng thái',
        //   field: 'State',
        //   width: 155,
        //   pinned: 'right',
        // },
        {
          ...agMakeStateColDef(this.cms, CollaboratorStateMap.publisherCommissionListStateMap, (data) => {
            this.preview([data]);
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 200,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true, [
            // {
            //   name: 'pushToQueue',
            //   // title: '',
            //   icon: 'layers-outline',
            //   status: 'info',
            //   outline: false,
            //   appendTo: 'head',
            //   action: async (params, btnConf) => {
            //     this.queue.push(params.node.data);
            //     return false;
            //   }
            // }
          ]),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }
  // queueParams = {};
  // prepareApiParams(params: any, getRowParams: IGetRowsParams) {
  //   params['includeContact'] = true;
  //   params['includeObject'] = true;
  //   params['includePublisher'] = true;
  //   params['includeSeller'] = true;
  //   params['includeSalesManager'] = true;
  //   params['includePublisherSupporter'] = true;
  //   params['includeCreator'] = true;
  //   params['includeRelativeVouchers'] = true;
  //   params['includeStateLogs'] = true;
  //   params['includeDetails'] = true;
  //   // params['sort_Id'] = 'desc';
  //   return {
  //     ...params,
  //     ...this.queueParams
  //   };
  // }

  executeGet(params: any, success: (resources: Model[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: Model[] | HttpErrorResponse) => void): void {

    // add params
    params['includeCreator'] = true;
    params['includeSummary'] = true;
    //
    return super.executeGet(params, success, complete);
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: Model[]) => void, onDialogClose?: () => void) {
    // this.cms.openDialog(CollaboratorPublisherListCommissionReportFormComponent, {
    //   context: {
    //     inputMode: 'dialog',
    //     inputId: ids,
    //     onDialogSave: (newData: Model[]) => {
    //       if (onDialogSave) onDialogSave(newData);
    //     },
    //     onDialogClose: () => {
    //       if (onDialogClose) onDialogClose();
    //     },
    //   },
    // });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }

  onChangePage(page: PageModel) {
    if (page !== null) {
      this.collaboratorService.currentpage$.next(this.cms.getObjectId(page));
      this.cms.takeOnce(this.componentName + '_on_domain_changed', 1000).then(() => {
        this.refresh();
      });
    }
  }

  // async preview<T>(data: Model[], source?: string, userConfig?: Partial<NbDialogConfig<Partial<T> | string>>) {
  //   window.open(this.apiPath + '/'+this.makeId(data[0])+'?renderPdfQuotation=download', '_blank');
  //   return false;
  // }
}
