import { Routes } from "@angular/router";
import { AuthGuardService } from "../../services/auth-guard.service";
import { AccManagerAccountListComponent } from "./account/acc-manager-account-list/acc-manager-account-list.component";


export const accManagerRoutes: Routes = [
    //Contract
  {
    path: 'acc-manager/account/list',
    canActivate: [AuthGuardService],
    component: AccManagerAccountListComponent,
    data: {
      reuse: true,
    },
  },
];