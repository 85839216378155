<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)"
  (keydown.enter)="onControlEnter($event)" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon"
            [title]="title ? title : ('System.Param.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)"
            [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <input formControlName="Name_old" type="hidden">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.name' | translate | headtitlecase}} (*)</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Common.name')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Name"
                    placeholder="{{'Common.name'}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('DefaultOperator'), 'warning')">
                  <label class="label"><span class="valid">{{'System.Param.defaultOperator' | translate | headtitlecase}} (*)</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('System.Param.defaultOperator')} | headtitlecase }}
                      (*)</span></label>
                      <nb-select size="medium" formControlName="DefaultOperator" fullWidth placeholder="{{'Common.compare' | translate | headtitlecase}}">
                        <nb-option value="LT">{{'Common.Compare.lt' | translate | headtitlecase}}</nb-option>
                        <nb-option value="LE">{{'Common.Compare.le' | translate | headtitlecase}}</nb-option>
                        <nb-option value="EQ">{{'Common.Compare.eq' | translate | headtitlecase}}</nb-option>
                        <nb-option value="GE">{{'Common.Compare.ge' | translate | headtitlecase}}</nb-option>
                        <nb-option value="GT">{{'Common.Compare.gt' | translate | headtitlecase}}</nb-option>
                        <nb-option value="NE">{{'Common.Compare.ne' | translate | headtitlecase}}</nb-option>
                        <nb-option value="BW">{{'Common.Compare.bw' | translate | headtitlecase}}</nb-option>
                        <nb-option value="IN">{{'Common.Compare.in' | translate | headtitlecase}}</nb-option>
                        <nb-option value="REGEX">{{'Common.Compare.regex' | translate | headtitlecase}}</nb-option>
                      </nb-select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('DefaultDataType'), 'warning')">
                  <label class="label"><span class="valid">{{'System.Param.defaultDataType' | translate | headtitlecase}} (*)</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('System.Param.defaultDataType')} | headtitlecase }}
                      (*)</span></label>
                      <nb-select size="medium" formControlName="DefaultDataType" fullWidth placeholder="{{'Common.dataType' | translate | headtitlecase}}">
                        <nb-option value="STRING">{{'Common.DataType.string' | translate | headtitlecase}}</nb-option>
                        <nb-option value="INTEGER">{{'Common.DataType.int' | translate | headtitlecase}}</nb-option>
                        <nb-option value="DOUBLE">{{'Common.DataType.float' | translate | headtitlecase}}</nb-option>
                        <nb-option value="DATE">{{'Common.DataType.date' | translate | headtitlecase}}</nb-option>
                        <nb-option value="TIME">{{'Common.DataType.time' | translate | headtitlecase}}</nb-option>
                        <nb-option value="DATE_TIME">{{'Common.DataType.datetime' | translate | headtitlecase}}</nb-option>
                        <nb-option value="OBJECT">{{'Common.DataType.object' | translate | headtitlecase}}</nb-option>
                        <nb-option value="OBJECTS">{{'Common.DataType.objects' | translate | headtitlecase}}</nb-option>
                      </nb-select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('RemoteDataSource'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.remoteDataSource' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Common.remoteDataSource')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="RemoteDataSource"
                    placeholder="{{'Common.remoteDataSource'}}">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('RemoteDataResource'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.remoteDataResource' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Common.remoteDataResource')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="RemoteDataResource"
                    placeholder="{{'Common.remoteDataResource'}}">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Description'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.description' | translate | headtitlecase}} (*)</span><span class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Common.description')} | headtitlecase }} (*)</span></label>
                  <textarea nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}" rows="3"></textarea>
                </div>
              </div>
            </div>

            <!-- Confition form -->
            <hr>
            <label class="label">{{'System.Param.options' | translate | headtitlecase}}</label>
            <div class="row">
              <div class="col-sm-1 label">Stt</div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-6 label">{{'Common.data' | translate | headtitlecase}}</div>
                  <div class="col-sm-6 label">{{'Common.label' | translate | headtitlecase}}</div>
                </div>
              </div>
              <div class="col-sm-1" style="text-align: center;">
                <button nbButton status="success" (click)="addOptionFormGroup(i)" size="medium"
                  style="width: 100%; margin-bottom: 3px;" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                </button>
              </div>
            </div>

            <div formArrayName="Options" class="form-details">
              <div class="form-detail-item" *ngFor="let condition of getOptions(i).controls; let ic=index"
                [formGroup]="condition">
                <div class="row">
                  <div class="col-sm-1">
                    <div class="form-group">
                      <!-- <label class="label">Stt</label> -->
                      <input nbInput fullWidth class="sorting-handle" disabled="true" value="{{ ic + 1 }}"
                        style="text-align: center; cursor: grab;">
                      <input type="hidden" formControlName="Id">
                    </div>
                  </div>
                  <div class="col-sm-10">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="label">{{'Common.data' | translate | headtitlecase}}</label>
                          <input type="text" nbInput fullWidth formControlName="Data"
                            placeholder="{{'Common.data' | translate | headtitlecase}}">
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="label">{{'Common.label' | translate | headtitlecase}}</label>
                          <input type="text" nbInput fullWidth formControlName="Label"
                            placeholder="{{'Common.label' | translate | headtitlecase}}">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1">
                    <div class="form-group">
                      <!-- <label class="label">CM</label> -->
                      <button nbButton status="danger" hero size="medium" (click)="removeOptionGroup(i, ic)"
                        style="width: 100%; margin-bottom: 3px;">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <!-- End Confition form -->


          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <!-- <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button> -->
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero
              (click)="saveAndClose()">Lưu & Đóng</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
