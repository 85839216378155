<div class="dialog-wrap {{inputMode}}" #dialogWrap>
  <nb-card [nbSpinner]="loading || data.__loading" *ngFor="let data of this.data; let i=index;" style="max-height: initial; margin: 0 auto; width: 23cm;" class="small-header">
    <nb-card-header>
      <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="('BÁO CÁO LƯƠNG' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
    </nb-card-header>
    <nb-card-body class="print-body" #printContent>
      <div id="print-area" style="position: relative;">
        <ngx-print-header></ngx-print-header>
        <div>
          <div class="print-title">{{ 'BÁO CÁO LƯƠNG' | translate:{action: '', definition: ''} | uppercase }}
          </div>
          <div class="under-title">{{ data.Code }} - {{ data.DateOfReport | date:'short' }}</div>
        </div>
        <div>
          <div style="display: flex;">
            <div style="flex: 1;">
              <div class="under-line">{{'Nhân viên/CTV' | translate | headtitlecase}} :
                {{renderValue(data.ObjectName)}}
              </div>
            </div>
            <div>
              <div class="under-line">{{'Common.phone' | translate | headtitlecase}} :
                {{renderValue(data.ObjectPhone)}}
              </div>
            </div>
            <div>
              <div class="under-line">{{'ID Liên hệ' | translate | headtitlecase}} :
                {{renderValue(data.Object | objectid)}}
              </div>
            </div>
          </div>
          <div class="under-line">{{'Common.address' | translate | headtitlecase}} : {{renderValue(data.ObjectAddress)}}</div>
          <ng-container *ngIf="data.Contact">
            <div style="display: flex;">
              <div style="flex: 1;">
                <div class="under-line">{{'Contact.name' | translate | headtitlecase}} :
                  {{renderValue(data.OriginatorName)}}
                </div>
              </div>
              <div>
                <div class="under-line">{{'Contact.phone' | translate | headtitlecase}} :
                  {{renderValue(data.OriginatorPhone)}}
                </div>
              </div>
              <div>
                <div class="under-line">{{'Contact.id' | translate | headtitlecase}} :
                  {{renderValue(data.Originator | objectid)}}
                </div>
              </div>
            </div>
            <div class="under-line">{{'Contact.address' | translate | headtitlecase}} : {{renderValue(data.OriginatorAddress)}}</div>

          </ng-container>
        </div>
        <div>
          <div>
            <table style="width: 100%;" class="print-voucher-detail-table">
              <thead class="print-voucher-detail-header">
                <td>#</td>
                <td class="" style="white-space: nowrap">{{'Common.dateOfVoucher' | translate | headtitlecase}}</td>
                <td class="" style="white-space: nowrap">{{'Common.voucher' | translate | headtitlecase}}</td>
                <td class="" style="white-space: nowrap">{{'Common.goodsOrService' | translate | headtitlecase}}</td>
                <td class="text-align-right" style="white-space: nowrap">{{'Số lượng' | translate | headtitlecase}}</td>
                <td class="" style="white-space: nowrap">{{'ĐVT' | translate | headtitlecase}}</td>
                <td class="text-align-right" style="white-space: nowrap">{{'Đơn giá' | translate | headtitlecase}}</td>
                <td class="text-align-right" style="white-space: nowrap">{{'Common.numOfMoney' | translate | headtitlecase}}</td>
                <td *ngIf="showIncreaseAmount" class="text-align-right" style="white-space: nowrap">{{'Biến động' | translate | headtitlecase}}</td>
            </thead>
            <tr class="print-voucher-detail-line" *ngFor="let detail of data.Details; let i = index">
                <td>{{i + 1}}</td>
                <td class="nowrap">{{detail.VoucherDate | date:'short'}}</td>
                <td>{{detail.Voucher}}</td>
                <td>{{((detail.VoucherType == 'COMMERCEPOSRETURN' || detail.VoucherType == 'SALESRETURNS') && 'Trả hàng: ' || '') +  detail.Description}}</td>
                <td class="text-align-right">{{detail.Quantity ? (detail.Quantity | number) : '--'}}</td>
                <td>{{detail.ProductUnitLabel}}</td>
                <td class="text-align-right" [innerHtml]="detail.Quantity ? renderCurrency((detail.GenerateCredit - detail.GenerateDebit) / detail.Quantity) : '--'"></td>
                <td class="text-align-right" style="font-weight: bold;" [innerHtml]="renderCurrency(detail.GenerateCredit - detail.GenerateDebit)"></td>
                <td *ngIf="showIncreaseAmount" class="text-align-right" [innerHtml]="renderCurrency(detail.IncrementAmount)"></td>
            </tr>
            <tr style="font-weight: bold;">
                <td colspan="5" class="text-align-lèt">Ghi chú: {{note}}</td>
                <td colspan="2" class="text-align-right">{{data['Total'] < 0 ? 'Ứng lương' : 'Lương' }}: </td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data['Total'])"></td>
                <td *ngIf="showIncreaseAmount" class="text-align-right"></td>
            </tr>
            </table>
            {{data.Note}}
          </div>
        </div>
        <div>
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <div style="text-align: center; flex: 0 0 50%;">
            <br>
            Quản lý
            <br><br><br>
          </div>
          <div style="text-align: center; flex: 0 0 50%;">
            <br>
            Nhân viên
            <br><br><br>
          </div>
        </div>
        <div class="default-print-footer" [innerHTML]="cms.systemConfigs$.value.PARAMETERS.DEFAULT_PURCHASEORDER_PRINT_FOOTER"></div>
        <div class="default-probox-one-print-footer">{{cms.env.defaultPrintFooter}}</div>
      </div>
    </nb-card-body>
    <nb-card-footer *ngIf="data.RelativeVouchers && data.RelativeVouchers.length > 0" class="print-relative-vouchers">
      <span class="tag" *ngFor="let relationVoucher of data.RelativeVouchers" nbTooltip="{{relationVoucher.type?.text}}: {{relationVoucher | objectstext}}">
        <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.type?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
        <nb-icon pack="eva" icon="close-outline" class="close-btn"></nb-icon>
      </span>
    </nb-card-footer>
    <nb-card-footer>
      <div class="buttons-row">
        <button *ngIf="mode === 'print'" nbButton hero status="info" (click)="edit(data);">
          <nb-icon pack="eva" icon="edit"></nb-icon>{{'Common.edit' | translate | headtitlecase}}
        </button>
        <button nbButton hero status="info" (click)="prepareCopy(data);" style="float: left;">
          <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
        </button>
        <!-- <button nbButton hero status="primary" (click)="print();" [disabled]="!identifier"><nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}</button> -->
        <!-- <button nbButton hero status="info" (click)="exportExcel('excel');">{{'Common.exportTo' | translate:{to: 'Excel'} | headtitlecase}}</button>
            <button nbButton hero status="info" (click)="exportExcel('pdf');">{{'Common.exportTo' | translate:{to: 'PDF'} | headtitlecase}}</button> -->
        <!-- <button nbButton hero status="danger" [disabled]="['COMPLETE'].indexOf(data.State) > -1" (click)="approvedConfirm(data, i);">{{(processMapList[i]?.nextStateLabel || '') | translate | headtitlecase}}<nb-icon pack="eva" icon="skip-forward"></nb-icon></button> -->

        <!--State process buttons-->
        <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
        <!--End State process buttons-->

        <!-- <button nbButton hero status="danger" [disabled]="!data['AllowTransferToOrder']" (click)="transferToOrder(data);">
          <nb-icon pack="eva" icon="archive-outline"></nb-icon>{{'Chuyển đổi' | translate | headtitlecase}}
        </button> -->

        <button *ngIf="!sourceOfDialog || sourceOfDialog === 'form'" nbButton hero status="success" (click)="saveAndClose(data);">
          <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>