import { Component, OnInit } from '@angular/core';
import { NbColorHelper, NbIconLibraries, NbThemeService } from '@nebular/theme';
import { CommonService } from '../../../../../services/common.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'ngx-system-library-icon',
  templateUrl: './system-library-icon.component.html',
  styleUrls: ['./system-library-icon.component.scss'],
})
export class SystemLibraryIconComponent implements OnInit {
  evaIcons = [];
  alive: boolean;
  colors: any;

  variableTable: { path: string, value: string }[] = [];
  constructor(
    public iconsLibrary: NbIconLibraries,
    public cms: CommonService,
    private themeService: NbThemeService,
  ) {
    // iconsLibrary.registerFontPack('fa', { packClass: 'fa', iconClassPrefix: 'fa' });
    // iconsLibrary.registerFontPack('far', { packClass: 'far', iconClassPrefix: 'far ' });
    // iconsLibrary.registerFontPack('ion', { iconClassPrefix: 'ion' });

    this.evaIcons = Array.from(iconsLibrary.getPack('eva').icons.keys())
      .filter(icon => icon.indexOf('outline') === -1);
    // this.icons.ionicons = Array.from(iconsLibrary.getPack('ion').icons.keys());
    // this.icons.fontAwesome = Array.from(iconsLibrary.getPack('fa').icons.keys());
    // this.icons.fontAwesomeRegular = Array.from(iconsLibrary.getPack('far').icons.keys());

    this.themeService.getJsTheme()
      // .pipe(takeWhile(() => this.alive))
      .subscribe(theme => {
        theme.variables;
        // this.chartjs = theme.variables.chartjs;
        this.colors = theme.variables;
        this.variableTable = [];
        for (let i = 0; i <= 5000; i++) {
          this.variableTable.push({ path: 'color-mix-' + i, value: NbColorHelper.mix(this.colors.danger, this.colors.warning, i) });
        }
        this.prepareJsThemeVariable(theme.variables, 'this.colors');
      });
  }

  prepareJsThemeVariable(variables: any, path?: string) {
    if (Array.isArray(variables)) {
      for (const index in variables) {
        this.prepareJsThemeVariable(variables[index], path + '[' + index + ']');
      }
    } else if (typeof variables == 'object') {
      for (const variable in variables) {
        this.prepareJsThemeVariable(variables[variable], path + '.' + variable);
      }
    } else {
      this.variableTable.push({ path: path, value: variables });
      console.log(path + ': ' + variables);
    }
  }

  showIconInfo(icon: string, pack?: string) {
    this.cms.showDialog('Icon info', `Pack: ${pack}<br>Name: ${icon}<br><span class="tag">&lt;nb-icon icon=&quot;${icon}&quot; pack=&quot;${pack}&quot;&gt;&lt;/nb-icon&gt;</span>`, []);
  }

  icons = {

    ionicons: [
      'alert',
      'alert-circled',
      'android-add',
      'android-add-circle',
      'android-alarm-clock',
      'android-alert',
      'android-apps',
      'android-archive',
      'android-arrow-back',
      'android-arrow-down',
      'android-arrow-dropdown',
      'android-arrow-dropdown-circle',
      'android-arrow-dropleft',
      'android-arrow-dropleft-circle',
      'android-arrow-dropright',
      'android-arrow-dropright-circle',
      'android-arrow-dropup',
      'android-arrow-dropup-circle',
      'android-arrow-forward',
      'android-arrow-up',
      'android-attach',
      'android-bar',
      'android-bicycle',
      'android-boat',
      'android-bookmark',
      'android-bulb',
      'android-bus',
      'android-calendar',
      'android-call',
      'android-camera',
      'android-cancel',
      'android-car',
      'android-cart',
      'android-chat',
      'android-checkbox',
      'android-checkbox-blank',
      'android-checkbox-outline',
      'android-checkbox-outline-blank',
      'android-checkmark-circle',
      'android-clipboard',
      'android-close',
      'android-cloud',
      'android-cloud-circle',
      'android-cloud-done',
      'android-cloud-outline',
      'android-color-palette',
      'android-compass',
      'android-contact',
      'android-contacts',
      'android-contract',
      'android-create',
      'android-delete',
      'android-desktop',
      'android-document',
      'android-done',
      'android-done-all',
      'android-download',
      'android-drafts',
      'android-exit',
      'android-expand',
      'android-favorite',
      'android-favorite-outline',
      'android-film',
      'android-folder',
      'android-folder-open',
      'android-funnel',
      'android-globe',
      'android-hand',
      'android-hangout',
      'android-happy',
      'android-home',
      'android-image',
      'android-laptop',
      'android-list',
      'android-locate',
      'android-lock',
      'android-mail',
      'android-map',
      'android-menu',
      'android-microphone',
      'android-microphone-off',
      'android-more-horizontal',
      'android-more-vertical',
      'android-navigate',
      'android-notifications',
      'android-notifications-none',
      'android-notifications-off',
      'android-open',
      'android-options',
      'android-people',
      'android-person',
      'android-person-add',
      'android-phone-landscape',
      'android-phone-portrait',
      'android-pin',
      'android-plane',
      'android-playstore',
      'android-print',
      'android-radio-button-off',
      'android-radio-button-on',
      'android-refresh',
      'android-remove',
      'android-remove-circle',
      'android-restaurant',
      'android-sad',
      'android-search',
      'android-send',
      'android-settings',
      'android-share',
      'android-share-alt',
      'android-star',
      'android-star-half',
      'android-star-outline',
      'android-stopwatch',
      'android-subway',
      'android-sunny',
      'android-sync',
      'android-textsms',
      'android-time',
      'android-train',
      'android-unlock',
      'android-upload',
      'android-volume-down',
      'android-volume-mute',
      'android-volume-off',
      'android-volume-up',
      'android-walk',
      'android-warning',
      'android-watch',
      'android-wifi',
      'aperture',
      'archive',
      'arrow-down-a',
      'arrow-down-b',
      'arrow-down-c',
      'arrow-expand',
      'arrow-graph-down-left',
      'arrow-graph-down-right',
      'arrow-graph-up-left',
      'arrow-graph-up-right',
      'arrow-left-a',
      'arrow-left-b',
      'arrow-left-c',
      'arrow-move',
      'arrow-resize',
      'arrow-return-left',
      'arrow-return-right',
      'arrow-right-a',
      'arrow-right-b',
      'arrow-right-c',
      'arrow-shrink',
      'arrow-swap',
      'arrow-up-a',
      'arrow-up-b',
      'arrow-up-c',
      'asterisk',
      'at',
      'backspace',
      'backspace-outline',
      'bag',
      'battery-charging',
      'battery-empty',
      'battery-full',
      'battery-half',
      'battery-low',
      'beaker',
      'beer',
      'bluetooth',
      'bonfire',
      'bookmark',
      'bowtie',
      'briefcase',
      'bug',
      'calculator',
      'calendar',
      'camera',
      'card',
      'cash',
      'chatbox',
      'chatbox-working',
      'chatboxes',
      'chatbubble',
      'chatbubble-working',
      'chatbubbles',
      'checkmark',
      'checkmark-circled',
      'checkmark-round',
      'chevron-down',
      'chevron-left',
      'chevron-right',
      'chevron-up',
      'clipboard',
      'clock',
      'close',
      'close-circled',
      'close-round',
      'closed-captioning',
      'cloud',
      'code',
      'code-download',
      'code-working',
      'coffee',
      'compass',
      'compose',
      'connection-bars',
      'contrast',
      'crop',
      'cube',
      'disc',
      'document',
      'document-text',
      'drag',
      'earth',
      'easel',
      'edit',
      'egg',
      'eject',
      'email',
      'email-unread',
      'erlenmeyer-flask',
      'erlenmeyer-flask-bubbles',
      'eye',
      'eye-disabled',
      'female',
      'filing',
      'film-marker',
      'fireball',
      'flag',
      'flame',
      'flash',
      'flash-off',
      'folder',
      'fork',
      'fork-repo',
      'forward',
      'funnel',
      'gear-a',
      'gear-b',
      'grid',
      'hammer',
      'happy',
      'happy-outline',
      'headphone',
      'heart',
      'heart-broken',
      'help',
      'help-buoy',
      'help-circled',
      'home',
      'icecream',
      'image',
      'images',
      'information',
      'information-circled',
      'ionic',
      'ios-alarm',
      'ios-alarm-outline',
      'ios-albums',
      'ios-albums-outline',
      'ios-americanfootball',
      'ios-americanfootball-outline',
      'ios-analytics',
      'ios-analytics-outline',
      'ios-arrow-back',
      'ios-arrow-down',
      'ios-arrow-forward',
      'ios-arrow-left',
      'ios-arrow-right',
      'ios-arrow-thin-down',
      'ios-arrow-thin-left',
      'ios-arrow-thin-right',
      'ios-arrow-thin-up',
      'ios-arrow-up',
      'ios-at',
      'ios-at-outline',
      'ios-barcode',
      'ios-barcode-outline',
      'ios-baseball',
      'ios-baseball-outline',
      'ios-basketball',
      'ios-basketball-outline',
      'ios-bell',
      'ios-bell-outline',
      'ios-body',
      'ios-body-outline',
      'ios-bolt',
      'ios-bolt-outline',
      'ios-book',
      'ios-book-outline',
      'ios-bookmarks',
      'ios-bookmarks-outline',
      'ios-box',
      'ios-box-outline',
      'ios-briefcase',
      'ios-briefcase-outline',
      'ios-browsers',
      'ios-browsers-outline',
      'ios-calculator',
      'ios-calculator-outline',
      'ios-calendar',
      'ios-calendar-outline',
      'ios-camera',
      'ios-camera-outline',
      'ios-cart',
      'ios-cart-outline',
      'ios-chatboxes',
      'ios-chatboxes-outline',
      'ios-chatbubble',
      'ios-chatbubble-outline',
      'ios-checkmark',
      'ios-checkmark-empty',
      'ios-checkmark-outline',
      'ios-circle-filled',
      'ios-circle-outline',
      'ios-clock',
      'ios-clock-outline',
      'ios-close',
      'ios-close-empty',
      'ios-close-outline',
      'ios-cloud',
      'ios-cloud-download',
      'ios-cloud-download-outline',
      'ios-cloud-outline',
      'ios-cloud-upload',
      'ios-cloud-upload-outline',
      'ios-cloudy',
      'ios-cloudy-night',
      'ios-cloudy-night-outline',
      'ios-cloudy-outline',
      'ios-cog',
      'ios-cog-outline',
      'ios-color-filter',
      'ios-color-filter-outline',
      'ios-color-wand',
      'ios-color-wand-outline',
      'ios-compose',
      'ios-compose-outline',
      'ios-contact',
      'ios-contact-outline',
      'ios-copy',
      'ios-copy-outline',
      'ios-crop',
      'ios-crop-strong',
      'ios-download',
      'ios-download-outline',
      'ios-drag',
      'ios-email',
      'ios-email-outline',
      'ios-eye',
      'ios-eye-outline',
      'ios-fastforward',
      'ios-fastforward-outline',
      'ios-filing',
      'ios-filing-outline',
      'ios-film',
      'ios-film-outline',
      'ios-flag',
      'ios-flag-outline',
      'ios-flame',
      'ios-flame-outline',
      'ios-flask',
      'ios-flask-outline',
      'ios-flower',
      'ios-flower-outline',
      'ios-folder',
      'ios-folder-outline',
      'ios-football',
      'ios-football-outline',
      'ios-game-controller-a',
      'ios-game-controller-a-outline',
      'ios-game-controller-b',
      'ios-game-controller-b-outline',
      'ios-gear',
      'ios-gear-outline',
      'ios-glasses',
      'ios-glasses-outline',
      'ios-grid-view',
      'ios-grid-view-outline',
      'ios-heart',
      'ios-heart-outline',
      'ios-help',
      'ios-help-empty',
      'ios-help-outline',
      'ios-home',
      'ios-home-outline',
      'ios-infinite',
      'ios-infinite-outline',
      'ios-information',
      'ios-information-empty',
      'ios-information-outline',
      'ios-ionic-outline',
      'ios-keypad',
      'ios-keypad-outline',
      'ios-lightbulb',
      'ios-lightbulb-outline',
      'ios-list',
      'ios-list-outline',
      'ios-location',
      'ios-location-outline',
      'ios-locked',
      'ios-locked-outline',
      'ios-loop',
      'ios-loop-strong',
      'ios-medical',
      'ios-medical-outline',
      'ios-medkit',
      'ios-medkit-outline',
      'ios-mic',
      'ios-mic-off',
      'ios-mic-outline',
      'ios-minus',
      'ios-minus-empty',
      'ios-minus-outline',
      'ios-monitor',
      'ios-monitor-outline',
      'ios-moon',
      'ios-moon-outline',
      'ios-more',
      'ios-more-outline',
      'ios-musical-note',
      'ios-musical-notes',
      'ios-navigate',
      'ios-navigate-outline',
      'ios-nutrition',
      'ios-nutrition-outline',
      'ios-paper',
      'ios-paper-outline',
      'ios-paperplane',
      'ios-paperplane-outline',
      'ios-partlysunny',
      'ios-partlysunny-outline',
      'ios-pause',
      'ios-pause-outline',
      'ios-paw',
      'ios-paw-outline',
      'ios-people',
      'ios-people-outline',
      'ios-person',
      'ios-person-outline',
      'ios-personadd',
      'ios-personadd-outline',
      'ios-photos',
      'ios-photos-outline',
      'ios-pie',
      'ios-pie-outline',
      'ios-pint',
      'ios-pint-outline',
      'ios-play',
      'ios-play-outline',
      'ios-plus',
      'ios-plus-empty',
      'ios-plus-outline',
      'ios-pricetag',
      'ios-pricetag-outline',
      'ios-pricetags',
      'ios-pricetags-outline',
      'ios-printer',
      'ios-printer-outline',
      'ios-pulse',
      'ios-pulse-strong',
      'ios-rainy',
      'ios-rainy-outline',
      'ios-recording',
      'ios-recording-outline',
      'ios-redo',
      'ios-redo-outline',
      'ios-refresh',
      'ios-refresh-empty',
      'ios-refresh-outline',
      'ios-reload',
      'ios-reverse-camera',
      'ios-reverse-camera-outline',
      'ios-rewind',
      'ios-rewind-outline',
      'ios-rose',
      'ios-rose-outline',
      'ios-search',
      'ios-search-strong',
      'ios-settings',
      'ios-settings-strong',
      'ios-shuffle',
      'ios-shuffle-strong',
      'ios-skipbackward',
      'ios-skipbackward-outline',
      'ios-skipforward',
      'ios-skipforward-outline',
      'ios-snowy',
      'ios-speedometer',
      'ios-speedometer-outline',
      'ios-star',
      'ios-star-half',
      'ios-star-outline',
      'ios-stopwatch',
      'ios-stopwatch-outline',
      'ios-sunny',
      'ios-sunny-outline',
      'ios-telephone',
      'ios-telephone-outline',
      'ios-tennisball',
      'ios-tennisball-outline',
      'ios-thunderstorm',
      'ios-thunderstorm-outline',
      'ios-time',
      'ios-time-outline',
      'ios-timer',
      'ios-timer-outline',
      'ios-toggle',
      'ios-toggle-outline',
      'ios-trash',
      'ios-trash-outline',
      'ios-undo',
      'ios-undo-outline',
      'ios-unlocked',
      'ios-unlocked-outline',
      'ios-upload',
      'ios-upload-outline',
      'ios-videocam',
      'ios-videocam-outline',
      'ios-volume-high',
      'ios-volume-low',
      'ios-wineglass',
      'ios-wineglass-outline',
      'ios-world',
      'ios-world-outline',
      'ipad',
      'iphone',
      'ipod',
      'jet',
      'key',
      'knife',
      'laptop',
      'leaf',
      'levels',
      'lightbulb',
      'link',
      'load-a',
      'load-b',
      'load-c',
      'load-d',
      'location',
      'lock-combination',
      'locked',
      'log-in',
      'log-out',
      'loop',
      'magnet',
      'male',
      'man',
      'map',
      'medkit',
      'merge',
      'mic-a',
      'mic-b',
      'mic-c',
      'minus',
      'minus-circled',
      'minus-round',
      'model-s',
      'monitor',
      'more',
      'mouse',
      'music-note',
      'navicon',
      'navicon-round',
      'navigate',
      'network',
      'no-smoking',
      'nuclear',
      'outlet',
      'paintbrush',
      'paintbucket',
      'paper-airplane',
      'paperclip',
      'pause',
      'person',
      'person-add',
      'person-stalker',
      'pie-graph',
      'pin',
      'pinpoint',
      'pizza',
      'plane',
      'planet',
      'play',
      'playstation',
      'plus',
      'plus-circled',
      'plus-round',
      'podium',
      'pound',
      'power',
      'pricetag',
      'pricetags',
      'printer',
      'pull-request',
      'qr-scanner',
      'quote',
      'radio-waves',
      'record',
      'refresh',
      'reply',
      'reply-all',
      'ribbon-a',
      'ribbon-b',
      'sad',
      'sad-outline',
      'scissors',
      'search',
      'settings',
      'share',
      'shuffle',
      'skip-backward',
      'skip-forward',
      'social-android',
      'social-android-outline',
      'social-angular',
      'social-angular-outline',
      'social-apple',
      'social-apple-outline',
      'social-bitcoin',
      'social-bitcoin-outline',
      'social-buffer',
      'social-buffer-outline',
      'social-chrome',
      'social-chrome-outline',
      'social-codepen',
      'social-codepen-outline',
      'social-css3',
      'social-css3-outline',
      'social-designernews',
      'social-designernews-outline',
      'social-dribbble',
      'social-dribbble-outline',
      'social-dropbox',
      'social-dropbox-outline',
      'social-euro',
      'social-euro-outline',
      'social-facebook',
      'social-facebook-outline',
      'social-foursquare',
      'social-foursquare-outline',
      'social-freebsd-devil',
      'social-github',
      'social-github-outline',
      'social-google',
      'social-google-outline',
      'social-googleplus',
      'social-googleplus-outline',
      'social-hackernews',
      'social-hackernews-outline',
      'social-html5',
      'social-html5-outline',
      'social-instagram',
      'social-instagram-outline',
      'social-javascript',
      'social-javascript-outline',
      'social-linkedin',
      'social-linkedin-outline',
      'social-markdown',
      'social-nodejs',
      'social-octocat',
      'social-pinterest',
      'social-pinterest-outline',
      'social-python',
      'social-reddit',
      'social-reddit-outline',
      'social-rss',
      'social-rss-outline',
      'social-sass',
      'social-skype',
      'social-skype-outline',
      'social-snapchat',
      'social-snapchat-outline',
      'social-tumblr',
      'social-tumblr-outline',
      'social-tux',
      'social-twitch',
      'social-twitch-outline',
      'social-twitter',
      'social-twitter-outline',
      'social-usd',
      'social-usd-outline',
      'social-vimeo',
      'social-vimeo-outline',
      'social-whatsapp',
      'social-whatsapp-outline',
      'social-windows',
      'social-windows-outline',
      'social-wordpress',
      'social-wordpress-outline',
      'social-yahoo',
      'social-yahoo-outline',
      'social-yen',
      'social-yen-outline',
      'social-youtube',
      'social-youtube-outline',
      'soup-can',
      'soup-can-outline',
      'speakerphone',
      'speedometer',
      'spoon',
      'star',
      'stats-bars',
      'steam',
      'stop',
      'thermometer',
      'thumbsdown',
      'thumbsup',
      'toggle',
      'toggle-filled',
      'transgender',
      'trash-a',
      'trash-b',
      'trophy',
      'tshirt',
      'tshirt-outline',
      'umbrella',
      'university',
      'unlocked',
      'upload',
      'usb',
      'videocamera',
      'volume-high',
      'volume-low',
      'volume-medium',
      'volume-mute',
      'wand',
      'waterdrop',
      'wifi',
      'wineglass',
      'woman',
      'wrench',
      'xbox',
    ],

    fontAwesome: [
      'adjust', 'anchor', 'archive', 'chart-area', 'arrows-alt', 'arrows-alt-h',
      'arrows-alt-v', 'asterisk', 'at', 'car', 'ban', 'university',
      'chart-bar', 'barcode', 'bars', 'bed', 'beer',
      'bell', 'bell-slash', 'bicycle', 'binoculars',
      'birthday-cake', 'bolt', 'bomb', 'book', 'bookmark',
      'briefcase', 'bug', 'building', 'bullhorn',
    ],

    fontAwesomeRegular: ['chart-bar', 'bell', 'bell-slash', 'bookmark', 'building'],
  };

  iconsx = [
    'alert',
    'alert-circled',
    'android-add',
    'android-add-circle',
    'android-alarm-clock',
    'android-alert',
    'android-apps',
    'android-archive',
    'android-arrow-back',
    'android-arrow-down',
    'android-arrow-dropdown',
    'android-arrow-dropdown-circle',
    'android-arrow-dropleft',
    'android-arrow-dropleft-circle',
    'android-arrow-dropright',
    'android-arrow-dropright-circle',
    'android-arrow-dropup',
    'android-arrow-dropup-circle',
    'android-arrow-forward',
    'android-arrow-up',
    'android-attach',
    'android-bar',
    'android-bicycle',
    'android-boat',
    'android-bookmark',
    'android-bulb',
    'android-bus',
    'android-calendar',
    'android-call',
    'android-camera',
    'android-cancel',
    'android-car',
    'android-cart',
    'android-chat',
    'android-checkbox',
    'android-checkbox-blank',
    'android-checkbox-outline',
    'android-checkbox-outline-blank',
    'android-checkmark-circle',
    'android-clipboard',
    'android-close',
    'android-cloud',
    'android-cloud-circle',
    'android-cloud-done',
    'android-cloud-outline',
    'android-color-palette',
    'android-compass',
    'android-contact',
    'android-contacts',
    'android-contract',
    'android-create',
    'android-delete',
    'android-desktop',
    'android-document',
    'android-done',
    'android-done-all',
    'android-download',
    'android-drafts',
    'android-exit',
    'android-expand',
    'android-favorite',
    'android-favorite-outline',
    'android-film',
    'android-folder',
    'android-folder-open',
    'android-funnel',
    'android-globe',
    'android-hand',
    'android-hangout',
    'android-happy',
    'android-home',
    'android-image',
    'android-laptop',
    'android-list',
    'android-locate',
    'android-lock',
    'android-mail',
    'android-map',
    'android-menu',
    'android-microphone',
    'android-microphone-off',
    'android-more-horizontal',
    'android-more-vertical',
    'android-navigate',
    'android-notifications',
    'android-notifications-none',
    'android-notifications-off',
    'android-open',
    'android-options',
    'android-people',
    'android-person',
    'android-person-add',
    'android-phone-landscape',
    'android-phone-portrait',
    'android-pin',
    'android-plane',
    'android-playstore',
    'android-print',
    'android-radio-button-off',
    'android-radio-button-on',
    'android-refresh',
    'android-remove',
    'android-remove-circle',
    'android-restaurant',
    'android-sad',
    'android-search',
    'android-send',
    'android-settings',
    'android-share',
    'android-share-alt',
    'android-star',
    'android-star-half',
    'android-star-outline',
    'android-stopwatch',
    'android-subway',
    'android-sunny',
    'android-sync',
    'android-textsms',
    'android-time',
    'android-train',
    'android-unlock',
    'android-upload',
    'android-volume-down',
    'android-volume-mute',
    'android-volume-off',
    'android-volume-up',
    'android-walk',
    'android-warning',
    'android-watch',
    'android-wifi',
    'aperture',
    'archive',
    'arrow-down-a',
    'arrow-down-b',
    'arrow-down-c',
    'arrow-expand',
    'arrow-graph-down-left',
    'arrow-graph-down-right',
    'arrow-graph-up-left',
    'arrow-graph-up-right',
    'arrow-left-a',
    'arrow-left-b',
    'arrow-left-c',
    'arrow-move',
    'arrow-resize',
    'arrow-return-left',
    'arrow-return-right',
    'arrow-right-a',
    'arrow-right-b',
    'arrow-right-c',
    'arrow-shrink',
    'arrow-swap',
    'arrow-up-a',
    'arrow-up-b',
    'arrow-up-c',
    'asterisk',
    'at',
    'backspace',
    'backspace-outline',
    'bag',
    'battery-charging',
    'battery-empty',
    'battery-full',
    'battery-half',
    'battery-low',
    'beaker',
    'beer',
    'bluetooth',
    'bonfire',
    'bookmark',
    'bowtie',
    'briefcase',
    'bug',
    'calculator',
    'calendar',
    'camera',
    'card',
    'cash',
    'chatbox',
    'chatbox-working',
    'chatboxes',
    'chatbubble',
    'chatbubble-working',
    'chatbubbles',
    'checkmark',
    'checkmark-circled',
    'checkmark-round',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'clipboard',
    'clock',
    'close',
    'close-circled',
    'close-round',
    'closed-captioning',
    'cloud',
    'code',
    'code-download',
    'code-working',
    'coffee',
    'compass',
    'compose',
    'connection-bars',
    'contrast',
    'crop',
    'cube',
    'disc',
    'document',
    'document-text',
    'drag',
    'earth',
    'easel',
    'edit',
    'egg',
    'eject',
    'email',
    'email-unread',
    'erlenmeyer-flask',
    'erlenmeyer-flask-bubbles',
    'eye',
    'eye-disabled',
    'female',
    'filing',
    'film-marker',
    'fireball',
    'flag',
    'flame',
    'flash',
    'flash-off',
    'folder',
    'fork',
    'fork-repo',
    'forward',
    'funnel',
    'gear-a',
    'gear-b',
    'grid',
    'hammer',
    'happy',
    'happy-outline',
    'headphone',
    'heart',
    'heart-broken',
    'help',
    'help-buoy',
    'help-circled',
    'home',
    'icecream',
    'image',
    'images',
    'information',
    'information-circled',
    'ionic',
    'ios-alarm',
    'ios-alarm-outline',
    'ios-albums',
    'ios-albums-outline',
    'ios-americanfootball',
    'ios-americanfootball-outline',
    'ios-analytics',
    'ios-analytics-outline',
    'ios-arrow-back',
    'ios-arrow-down',
    'ios-arrow-forward',
    'ios-arrow-left',
    'ios-arrow-right',
    'ios-arrow-thin-down',
    'ios-arrow-thin-left',
    'ios-arrow-thin-right',
    'ios-arrow-thin-up',
    'ios-arrow-up',
    'ios-at',
    'ios-at-outline',
    'ios-barcode',
    'ios-barcode-outline',
    'ios-baseball',
    'ios-baseball-outline',
    'ios-basketball',
    'ios-basketball-outline',
    'ios-bell',
    'ios-bell-outline',
    'ios-body',
    'ios-body-outline',
    'ios-bolt',
    'ios-bolt-outline',
    'ios-book',
    'ios-book-outline',
    'ios-bookmarks',
    'ios-bookmarks-outline',
    'ios-box',
    'ios-box-outline',
    'ios-briefcase',
    'ios-briefcase-outline',
    'ios-browsers',
    'ios-browsers-outline',
    'ios-calculator',
    'ios-calculator-outline',
    'ios-calendar',
    'ios-calendar-outline',
    'ios-camera',
    'ios-camera-outline',
    'ios-cart',
    'ios-cart-outline',
    'ios-chatboxes',
    'ios-chatboxes-outline',
    'ios-chatbubble',
    'ios-chatbubble-outline',
    'ios-checkmark',
    'ios-checkmark-empty',
    'ios-checkmark-outline',
    'ios-circle-filled',
    'ios-circle-outline',
    'ios-clock',
    'ios-clock-outline',
    'ios-close',
    'ios-close-empty',
    'ios-close-outline',
    'ios-cloud',
    'ios-cloud-download',
    'ios-cloud-download-outline',
    'ios-cloud-outline',
    'ios-cloud-upload',
    'ios-cloud-upload-outline',
    'ios-cloudy',
    'ios-cloudy-night',
    'ios-cloudy-night-outline',
    'ios-cloudy-outline',
    'ios-cog',
    'ios-cog-outline',
    'ios-color-filter',
    'ios-color-filter-outline',
    'ios-color-wand',
    'ios-color-wand-outline',
    'ios-compose',
    'ios-compose-outline',
    'ios-contact',
    'ios-contact-outline',
    'ios-copy',
    'ios-copy-outline',
    'ios-crop',
    'ios-crop-strong',
    'ios-download',
    'ios-download-outline',
    'ios-drag',
    'ios-email',
    'ios-email-outline',
    'ios-eye',
    'ios-eye-outline',
    'ios-fastforward',
    'ios-fastforward-outline',
    'ios-filing',
    'ios-filing-outline',
    'ios-film',
    'ios-film-outline',
    'ios-flag',
    'ios-flag-outline',
    'ios-flame',
    'ios-flame-outline',
    'ios-flask',
    'ios-flask-outline',
    'ios-flower',
    'ios-flower-outline',
    'ios-folder',
    'ios-folder-outline',
    'ios-football',
    'ios-football-outline',
    'ios-game-controller-a',
    'ios-game-controller-a-outline',
    'ios-game-controller-b',
    'ios-game-controller-b-outline',
    'ios-gear',
    'ios-gear-outline',
    'ios-glasses',
    'ios-glasses-outline',
    'ios-grid-view',
    'ios-grid-view-outline',
    'ios-heart',
    'ios-heart-outline',
    'ios-help',
    'ios-help-empty',
    'ios-help-outline',
    'ios-home',
    'ios-home-outline',
    'ios-infinite',
    'ios-infinite-outline',
    'ios-information',
    'ios-information-empty',
    'ios-information-outline',
    'ios-ionic-outline',
    'ios-keypad',
    'ios-keypad-outline',
    'ios-lightbulb',
    'ios-lightbulb-outline',
    'ios-list',
    'ios-list-outline',
    'ios-location',
    'ios-location-outline',
    'ios-locked',
    'ios-locked-outline',
    'ios-loop',
    'ios-loop-strong',
    'ios-medical',
    'ios-medical-outline',
    'ios-medkit',
    'ios-medkit-outline',
    'ios-mic',
    'ios-mic-off',
    'ios-mic-outline',
    'ios-minus',
    'ios-minus-empty',
    'ios-minus-outline',
    'ios-monitor',
    'ios-monitor-outline',
    'ios-moon',
    'ios-moon-outline',
    'ios-more',
    'ios-more-outline',
    'ios-musical-note',
    'ios-musical-notes',
    'ios-navigate',
    'ios-navigate-outline',
    'ios-nutrition',
    'ios-nutrition-outline',
    'ios-paper',
    'ios-paper-outline',
    'ios-paperplane',
    'ios-paperplane-outline',
    'ios-partlysunny',
    'ios-partlysunny-outline',
    'ios-pause',
    'ios-pause-outline',
    'ios-paw',
    'ios-paw-outline',
    'ios-people',
    'ios-people-outline',
    'ios-person',
    'ios-person-outline',
    'ios-personadd',
    'ios-personadd-outline',
    'ios-photos',
    'ios-photos-outline',
    'ios-pie',
    'ios-pie-outline',
    'ios-pint',
    'ios-pint-outline',
    'ios-play',
    'ios-play-outline',
    'ios-plus',
    'ios-plus-empty',
    'ios-plus-outline',
    'ios-pricetag',
    'ios-pricetag-outline',
    'ios-pricetags',
    'ios-pricetags-outline',
    'ios-printer',
    'ios-printer-outline',
    'ios-pulse',
    'ios-pulse-strong',
    'ios-rainy',
    'ios-rainy-outline',
    'ios-recording',
    'ios-recording-outline',
    'ios-redo',
    'ios-redo-outline',
    'ios-refresh',
    'ios-refresh-empty',
    'ios-refresh-outline',
    'ios-reload',
    'ios-reverse-camera',
    'ios-reverse-camera-outline',
    'ios-rewind',
    'ios-rewind-outline',
    'ios-rose',
    'ios-rose-outline',
    'ios-search',
    'ios-search-strong',
    'ios-settings',
    'ios-settings-strong',
    'ios-shuffle',
    'ios-shuffle-strong',
    'ios-skipbackward',
    'ios-skipbackward-outline',
    'ios-skipforward',
    'ios-skipforward-outline',
    'ios-snowy',
    'ios-speedometer',
    'ios-speedometer-outline',
    'ios-star',
    'ios-star-half',
    'ios-star-outline',
    'ios-stopwatch',
    'ios-stopwatch-outline',
    'ios-sunny',
    'ios-sunny-outline',
    'ios-telephone',
    'ios-telephone-outline',
    'ios-tennisball',
    'ios-tennisball-outline',
    'ios-thunderstorm',
    'ios-thunderstorm-outline',
    'ios-time',
    'ios-time-outline',
    'ios-timer',
    'ios-timer-outline',
    'ios-toggle',
    'ios-toggle-outline',
    'ios-trash',
    'ios-trash-outline',
    'ios-undo',
    'ios-undo-outline',
    'ios-unlocked',
    'ios-unlocked-outline',
    'ios-upload',
    'ios-upload-outline',
    'ios-videocam',
    'ios-videocam-outline',
    'ios-volume-high',
    'ios-volume-low',
    'ios-wineglass',
    'ios-wineglass-outline',
    'ios-world',
    'ios-world-outline',
    'ipad',
    'iphone',
    'ipod',
    'jet',
    'key',
    'knife',
    'laptop',
    'leaf',
    'levels',
    'lightbulb',
    'link',
    'load-a',
    'load-b',
    'load-c',
    'load-d',
    'location',
    'lock-combination',
    'locked',
    'log-in',
    'log-out',
    'loop',
    'magnet',
    'male',
    'man',
    'map',
    'medkit',
    'merge',
    'mic-a',
    'mic-b',
    'mic-c',
    'minus',
    'minus-circled',
    'minus-round',
    'model-s',
    'monitor',
    'more',
    'mouse',
    'music-note',
    'navicon',
    'navicon-round',
    'navigate',
    'network',
    'no-smoking',
    'nuclear',
    'outlet',
    'paintbrush',
    'paintbucket',
    'paper-airplane',
    'paperclip',
    'pause',
    'person',
    'person-add',
    'person-stalker',
    'pie-graph',
    'pin',
    'pinpoint',
    'pizza',
    'plane',
    'planet',
    'play',
    'playstation',
    'plus',
    'plus-circled',
    'plus-round',
    'podium',
    'pound',
    'power',
    'pricetag',
    'pricetags',
    'printer',
    'pull-request',
    'qr-scanner',
    'quote',
    'radio-waves',
    'record',
    'refresh',
    'reply',
    'reply-all',
    'ribbon-a',
    'ribbon-b',
    'sad',
    'sad-outline',
    'scissors',
    'search',
    'settings',
    'share',
    'shuffle',
    'skip-backward',
    'skip-forward',
    'social-android',
    'social-android-outline',
    'social-angular',
    'social-angular-outline',
    'social-apple',
    'social-apple-outline',
    'social-bitcoin',
    'social-bitcoin-outline',
    'social-buffer',
    'social-buffer-outline',
    'social-chrome',
    'social-chrome-outline',
    'social-codepen',
    'social-codepen-outline',
    'social-css3',
    'social-css3-outline',
    'social-designernews',
    'social-designernews-outline',
    'social-dribbble',
    'social-dribbble-outline',
    'social-dropbox',
    'social-dropbox-outline',
    'social-euro',
    'social-euro-outline',
    'social-facebook',
    'social-facebook-outline',
    'social-foursquare',
    'social-foursquare-outline',
    'social-freebsd-devil',
    'social-github',
    'social-github-outline',
    'social-google',
    'social-google-outline',
    'social-googleplus',
    'social-googleplus-outline',
    'social-hackernews',
    'social-hackernews-outline',
    'social-html5',
    'social-html5-outline',
    'social-instagram',
    'social-instagram-outline',
    'social-javascript',
    'social-javascript-outline',
    'social-linkedin',
    'social-linkedin-outline',
    'social-markdown',
    'social-nodejs',
    'social-octocat',
    'social-pinterest',
    'social-pinterest-outline',
    'social-python',
    'social-reddit',
    'social-reddit-outline',
    'social-rss',
    'social-rss-outline',
    'social-sass',
    'social-skype',
    'social-skype-outline',
    'social-snapchat',
    'social-snapchat-outline',
    'social-tumblr',
    'social-tumblr-outline',
    'social-tux',
    'social-twitch',
    'social-twitch-outline',
    'social-twitter',
    'social-twitter-outline',
    'social-usd',
    'social-usd-outline',
    'social-vimeo',
    'social-vimeo-outline',
    'social-whatsapp',
    'social-whatsapp-outline',
    'social-windows',
    'social-windows-outline',
    'social-wordpress',
    'social-wordpress-outline',
    'social-yahoo',
    'social-yahoo-outline',
    'social-yen',
    'social-yen-outline',
    'social-youtube',
    'social-youtube-outline',
    'soup-can',
    'soup-can-outline',
    'speakerphone',
    'speedometer',
    'spoon',
    'star',
    'stats-bars',
    'steam',
    'stop',
    'thermometer',
    'thumbsdown',
    'thumbsup',
    'toggle',
    'toggle-filled',
    'transgender',
    'trash-a',
    'trash-b',
    'trophy',
    'tshirt',
    'tshirt-outline',
    'umbrella',
    'university',
    'unlocked',
    'upload',
    'usb',
    'videocamera',
    'volume-high',
    'volume-low',
    'volume-medium',
    'volume-mute',
    'wand',
    'waterdrop',
    'wifi',
    'wineglass',
    'woman',
    'wrench',
    'xbox',
  ];

  ngOnInit(): void {
  }

}
