<div class="dialog-wrap {{inputMode}}" #dialogWrap>
  <nb-card *ngFor="let data of this.data; let i=index;" style="max-height: initial; margin: 0 auto; width: 22cm;" class="small-header">
    <nb-card-header>
      <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="data['Title'] || ('Purchase.Voucher.title' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
    </nb-card-header>
    <nb-card-body class="print-body" #printContent>
      <div id="print-area">
        <div>
          <div>
            <div><b>Trang: {{data.Page | objecttext}}</b></div>
            <br>
            <div><b>{{data.Title}}</b></div>
            <br>
            <div [innerHtml]="data.Summary" style="font-style: italic;"></div>
            <br>
            <div [innerHtml]="this.sanitizer.bypassSecurityTrustHtml(data.ContentBlock1)"></div>
            <div [innerHtml]="data.ContentBlock2"></div>
            <div [innerHtml]="data.ContentBlock3"></div>
            <div>Sản phẩm: {{data.Product | objecttext}}</div>
            <div>Link sản phẩm: <a target="_blank" href="https://probox.vn/{{data.Page | objectid}}/ctvbanhang/product/{{data.Product | objectid}}">https://probox.vn/{{data.Page | objectid}}/ctvbanhang/product/{{data.Product | objectid}}</a></div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="buttons-row">
        <button *ngIf="mode === 'print'" nbButton hero status="info" (click)="edit(data);">
            <nb-icon pack="eva" icon="edit"></nb-icon>{{'Common.edit' | translate | headtitlecase}}
        </button>
        <button nbButton hero status="info" (click)="prepareCopy(data);" style="float: left">
          <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
        </button>
        <!-- <button nbButton hero status="danger" (click)="approvedConfirm(data, i);">{{(processMapList[i]?.nextStateLabel || '') | translate | headtitlecase}}<nb-icon pack="eva" icon="skip-forward"></nb-icon></button> -->

        <!--State process buttons-->
        <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
        <!--End State process buttons-->
        
        <button *ngIf="!sourceOfDialog || sourceOfDialog === 'form'" nbButton hero status="success" (click)="saveAndClose(data);">
          <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>