import { AdminProductService } from '../../../admin-product/admin-product.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService, NbDialogService, NbDialogRef } from '@nebular/theme';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { environment } from '../../../../../environments/environment';
import { ActionControlListOption } from '../../../../lib/custom-element/action-control-list/action-control.interface';
import { CustomIcon, FormGroupComponent } from '../../../../lib/custom-element/form/form-group/form-group.component';
import { DataManagerFormComponent } from '../../../../lib/data-manager/data-manager-form.component';
import { ContactModel } from '../../../../models/contact.model';
import { ProductModel } from '../../../../models/product.model';
import { PurchaseProductModel } from '../../../../models/purchase.model';
import { TaxModel } from '../../../../models/tax.model';
import { UnitModel } from '../../../../models/unit.model';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { ProductFormComponent } from '../../../admin-product/product/product-form/product-form.component';
import { ContactFormComponent } from '../../../contact/contact/contact-form/contact-form.component';
import { CollaboratorSalarySlipPrintComponent } from '../salary-slip-print/salary-slip-print.component';
import { filter, takeUntil } from 'rxjs/operators';
import { ReferenceChoosingDialogComponent } from '../../../dialog/reference-choosing-dialog/reference-choosing-dialog.component';
import * as XLSX from 'xlsx';
import { DialogFormComponent } from '../../../dialog/dialog-form/dialog-form.component';
import { BusinessModel } from '../../../../models/accounting.model';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { RootServices } from '../../../../services/root.services';
import { CollaboratorSalarySlipDetailModel, CollaboratorSalarySlipModel } from '../../../../models/collaborator.model';
import { Select2Option } from '../../../../lib/custom-element/select2/select2.component';
import { MobileAppService } from '../../../mobile-app/mobile-app.service';
import { CollaboratorService } from '../../collaborator.service';
var CryptoJS = require("crypto-js");

@Component({
  selector: 'ngx-salary-slip-form',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './salary-slip-form.component.html',
  styleUrls: ['./salary-slip-form.component.scss']
})
export class CollaboratorSalarySlipFormComponent extends DataManagerFormComponent<CollaboratorSalarySlipModel> implements OnInit {

  componentName: string = 'CollaboratorSalarySlipFormComponent';
  idKey = 'Code';
  apiPath = '/collaborator/salary-slips';
  baseFormUrl = '/collaborator/salary-slip/form';

  env = environment;

  locale = this.cms.getCurrentLoaleDataset();
  curencyFormat: CurrencyMaskConfig = this.cms.getCurrencyMaskConfig();
  numberFormat: CurrencyMaskConfig = this.cms.getNumberMaskConfig();

  // locale = this.commo nService.getCurrentLoaleDataset();
  priceCurencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0 };
  toMoneyCurencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0 };
  quantityFormat: CurrencyMaskConfig = { ...this.cms.getNumberMaskConfig(), precision: 2 };

  towDigitsInputMask = this.cms.createFloatNumberMaskConfig({
    digitsOptional: false,
    digits: 2
  });

  /** Tax list */
  static _taxList: (TaxModel & { id?: string, text?: string })[];
  taxList: (TaxModel & { id?: string, text?: string })[];

  /** Unit list */
  static _unitList: (UnitModel & { id?: string, text?: string })[];
  unitList: (UnitModel & { id?: string, text?: string })[];
  @ViewChild('detailsViewport', { static: false }) detailsViewport: CdkVirtualScrollViewport;


  uploadConfig = {

  };

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastrService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public adminProductService: AdminProductService,
    public ref: NbDialogRef<CollaboratorSalarySlipFormComponent>,
    public mobileAppService: MobileAppService,
    public collaboratorService: CollaboratorService,
    // public changeDirectorRef: ChangeDetectorRef,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastrService, dialogService, cms);

    /** Append print button to head card */
    this.actionButtonList.splice(this.actionButtonList.length - 1, 0, {
      name: 'print',
      status: 'primary',
      label: this.cms.textTransform(this.cms.translate.instant('Common.print'), 'head-title'),
      icon: 'printer',
      title: this.cms.textTransform(this.cms.translate.instant('Common.print'), 'head-title'),
      size: 'medium',
      disabled: () => this.isProcessing,
      hidden: () => false,
      click: (event: any, option: ActionControlListOption) => {
        this.preview(option.form);
      },
    });
  }

  // getRequestId(callback: (id?: string[]) => void) {
  //   callback(this.inputId);
  // }



  getRequestId(callback: (id?: string[]) => void) {
    // callback(this.inputId);
    return super.getRequestId(callback);
  }

  select2OptionForPublisher = {
    ...this.cms.makeSelect2AjaxOption('/collaborator/publishers', {}, {
      placeholder: 'Chọn CTV Bán Hàng...', limit: 10, prepareReaultItem: (item) => {
        item['id'] = item.Contact;
        item['text'] = item.Name;
        return item;
      }
    }),
    // minimumInputLength: 1,
  };

  select2OptionForProduct: Select2Option = {
    placeholder: 'Chọn...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    withThumbnail: true,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    ajax: {
      data: function (params) {
        return {
          ...params,
          offset: params['offset'] || 0,
          limit: params['limit'] || 10
        };
      },
      transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
        const params = settings.data;
        const offset = settings.data['offset'];
        const limit = settings.data['limit'];
        const results = !params['term'] ? this.adminProductService.productSearchIndexsGroupById : this.adminProductService.productSearchIndexsGroupById.filter(f => this.cms.smartFilter(f.SearchText, params['term']));
        success({ data: results.slice(offset, offset + limit), total: results.length });
        return null;
      },
      delay: 300,
      processResults: (rs: { data: any[], total: number }, params: any) => {
        const data = rs.data;
        const total = rs.total;
        params.limit = params.limit || 10;
        params.offset = params.offset || 0;
        params.offset = params.offset += params.limit;
        return {
          results: data.map(item => {
            item.id = item.Code;
            item.text = `${item.Sku} - ${item.Name} (${item.Code})`;
            item.thumbnail = item?.FeaturePicture?.Thumbnail;
            return item;
          }),
          pagination: {
            more: params.offset < total
          }
        };
      },
    },
  };

  select2OptionForUnit = {
    placeholder: 'Chọn ĐVT...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
  };
  select2OptionForPage = {
    placeholder: 'Chọn trang...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  select2OptionForTax = {
    placeholder: 'Chọn thuế...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
  };

  // Type field option
  select2OptionForType = {
    placeholder: 'Chọn loại...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
  };
  select2DataForType = [
    { id: 'PRODUCT', text: 'Sản phẩm' },
    { id: 'CATEGORY', text: 'Danh mục' },
  ];

  objectControlIcons: CustomIcon[] = [{
    icon: 'plus-square-outline',
    title: this.cms.translateText('Common.addNewContact'),
    status: 'success',
    states: {
      '<>': {
        icon: 'edit-outline',
        status: 'primary',
        title: this.cms.translateText('Common.editContact'),
      },
      '': {
        icon: 'plus-square-outline',
        status: 'success',
        title: this.cms.translateText('Common.addNewContact'),
      },
    },
    action: (formGroupCompoent: FormGroupComponent, formGroup: FormGroup, array: FormArray, index: number, option: { parentForm: FormGroup }) => {
      const currentObject = this.cms.getObjectId(formGroup.get('Object').value);
      this.cms.openDialog(ContactFormComponent, {
        context: {
          inputMode: 'dialog',
          inputId: currentObject ? [currentObject] : null,
          showLoadinng: true,
          onDialogSave: (newData: ContactModel[]) => {
            console.log(newData);
            const newContact: any = { ...newData[0], id: newData[0].Code, text: newData[0].Name };
            formGroup.get('Object').patchValue(newContact);
          },
          onDialogClose: () => {

          },
        },
        closeOnEsc: false,
        closeOnBackdropClick: false,
      });
    },
  }];

  contactControlIcons: CustomIcon[] = [{
    icon: 'plus-square-outline',
    title: this.cms.translateText('Common.addNewContact'),
    status: 'success',
    states: {
      '<>': {
        icon: 'edit-outline',
        status: 'primary',
        title: this.cms.translateText('Common.editContact'),
      },
      '': {
        icon: 'plus-square-outline',
        status: 'success',
        title: this.cms.translateText('Common.addNewContact'),
      },
    },
    action: (formGroupCompoent: FormGroupComponent, formGroup: FormGroup, array: FormArray, index: number, option: { parentForm: FormGroup }) => {
      const currentObject = this.cms.getObjectId(formGroup.get('Publisher').value);
      this.cms.openDialog(ContactFormComponent, {
        context: {
          inputMode: 'dialog',
          inputId: currentObject ? [currentObject] : null,
          showLoadinng: true,
          onDialogSave: (newData: ContactModel[]) => {
            console.log(newData);
            const newContact: any = { ...newData[0], id: newData[0].Code, text: newData[0].Name };
            formGroup.get('Publisher').patchValue(newContact);
          },
          onDialogClose: () => {

          },
        },
        closeOnEsc: false,
        closeOnBackdropClick: false,
      });
    },
  }];

  accountingBusinessList: BusinessModel[] = [];
  select2OptionForAccountingBusiness = {
    placeholder: 'Nghiệp vụ kế toán...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    // dropdownCssClass: 'is_tags',
    maximumSelectionLength: 1,
    multiple: true,
    // tags: true,
    keyMap: {
      id: 'Code',
      text: 'Name',
    },
  };

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {

    /** Load and cache tax list */
    this.taxList = (await this.apiService.getPromise<TaxModel[]>('/accounting/taxes')).map(tax => {
      tax['id'] = tax.Code;
      tax['text'] = tax.Name;
      return tax;
    });

    /** Load and cache unit list */
    this.unitList = (await this.apiService.getPromise<UnitModel[]>('/admin-product/units', { limit: 'nolimit' })).map(tax => {
      tax['id'] = tax.Code;
      tax['text'] = tax.Name;
      return tax;
    });
    this.rsv.accountingService.accountingBusinessList$.pipe(filter(f => !!f), takeUntil(this.destroy$)).subscribe(list => {
      this.accountingBusinessList = list.filter(f => ['COLLABORATORSALARY'].indexOf(f.Type) > -1);
    });
    return super.init().then(async status => {
      if (this.isDuplicate) {
        // Clear id
        this.id = [];
        this.array.controls.forEach((formItem, index) => {
          formItem.get('Code').setValue('');
          formItem.get('Title').setValue('Copy of: ' + formItem.get('Title').value);
          this.getDetails(formItem as FormGroup).controls.forEach(conditonFormGroup => {
            // Clear id
            // conditonFormGroup.get('Id').setValue('');
          });
        });
      }

      return status;
    });

  }

  /** Execute api get */
  executeGet(params: any, success: (resources: CollaboratorSalarySlipModel[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includeObject'] = true;
    params['includeDetails'] = true;
    params['includeRelativeVouchers'] = true;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: CollaboratorSalarySlipModel[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: CollaboratorSalarySlipModel) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      // Details form load
      if (itemFormData.Details) {
        const details = this.getDetails(newForm);
        itemFormData.Details.forEach(detail => {
          const newDetailFormGroup = this.makeNewDetailFormGroup(newForm, detail);
          details.push(newDetailFormGroup);
          this.onAddDetailFormGroup(newForm, newDetailFormGroup, details.length - 1);
        });
        this.setNoForArray(details.controls as FormGroup[], (detail: FormGroup) => true);
      }
      this.cms.waitFor(150, 20, async () => !!this.detailsViewport).then(() => this.detailsViewport.checkViewportSize());
      this.toMoney(newForm);

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    }).then(rs => {
      return rs;
    });

  }

  makeNewFormGroup(data?: CollaboratorSalarySlipModel): FormGroup {
    const newForm = this.formBuilder.group({
      Code: [''],
      Page: { disabled: true, value: this.collaboratorService.currentpage$?.value || null },
      Object: ['', Validators.required],
      ObjectName: ['', Validators.required],
      // ObjectName: { disabled: true, value: null },
      ObjectEmail: [''],
      ObjectPhone: [''],
      ObjectAddress: [''],
      ObjectIdentifiedNumber: [''],
      ObjectTaxCode: [''],
      ObjectBankName: [''],
      ObjectBankCode: [''],

      Title: ['', Validators.required],
      Note: [''],
      SubNote: [''],
      DateOfVoucher: [null, Validators.required],
      _total: [''],
      RelativeVouchers: [],
      Details: this.formBuilder.array([]),
    });

    // Add controls allway disabled
    // this.disabledControls.push(newForm.get('ObjectName'));

    if (data) {
      newForm.patchValue(data);
    } else {
      this.addDetailFormGroup(newForm);
    }
    newForm['_details'] = this.getDetails(newForm);
    newForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      console.log('Form value change: ', value);
    });
    // if (data) {
    // }
    return newForm;
  }

  checkDisabledFormGroup(formData) {
    if (formData) {
      return !this.isDuplicate && this.cms.getObjectId(formData.State) == 'APPROVED';
    }
    return false;
  }

  onAddFormGroup(index: number, newForm: FormGroup, formData?: CollaboratorSalarySlipModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }

  goback(): false {
    // super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/collaborator/salary-slip/list']);
    } else {
      this.ref.close();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }

  /** Detail Form */
  makeNewDetailFormGroup(parentFormGroup: FormGroup, data?: CollaboratorSalarySlipDetailModel): FormGroup {
    let newForm: FormGroup;
    newForm = this.formBuilder.group({
      SystemUuid: [''],
      No: [''],
      Description: ['', Validators.required],
      Amount: ['', Validators.required],
      AccBusiness: [null, (control: FormControl) => {
        if (newForm && !this.cms.getObjectId(control.value)) {
          return { invalidName: true, required: true, text: 'trường bắt buộc' };
        }
        return null;
      }]
    });

    if (data) {
      if (data?.Product && Array.isArray(data.Product['Units'])) {
        const unitControl = newForm.get('Unit');
        newForm['UnitList'] = data?.Product['Units'];
      }
      newForm.patchValue(data);
    }
    return newForm;
  }
  getDetails(parentFormGroup: FormGroup) {
    return parentFormGroup.get('Details') as FormArray;
  }
  addDetailFormGroup(parentFormGroup: FormGroup) {
    const newChildFormGroup = this.makeNewDetailFormGroup(parentFormGroup);
    const detailsFormArray = this.getDetails(parentFormGroup);
    detailsFormArray.push(newChildFormGroup);
    const noFormControl = newChildFormGroup.get('No');
    if (!noFormControl.value) {
      noFormControl.setValue(detailsFormArray.length);
    }
    detailsFormArray.controls = [...detailsFormArray.controls];
    this.onAddDetailFormGroup(parentFormGroup, newChildFormGroup, detailsFormArray.length - 1);
    return false;
  }
  removeDetailGroup(parentFormGroup: FormGroup, detail: FormGroup, index: number) {
    const details = this.getDetails(parentFormGroup);
    details.removeAt(index);
    details.controls = [...details.controls];
    this.onRemoveDetailFormGroup(parentFormGroup, detail);
    return false;
  }

  purchaseProductMap: { [key: string]: PurchaseProductModel } = {};
  onAddDetailFormGroup(parentFormGroup: FormGroup, newChildFormGroup: FormGroup, index: number) {
    if (parentFormGroup['__disabled']) {
      newChildFormGroup.disable();
    } else {
      newChildFormGroup.enable();
    }
  }
  onRemoveDetailFormGroup(parentFormGroup: FormGroup, detailFormGroup: FormGroup) {
  }

  /**
   * Choose product form recent purchase order and add to details
   * @param parentFormGroup 
   * @returns 
   */
  addMultiProducts(parentFormGroup: FormGroup) {

    const filter = { group_Object: true, group_Product: true, includeUnit: true };
    const objectId = this.cms.getObjectId(parentFormGroup.get('Object').value);
    if (objectId) {
      filter['eq_Object'] = objectId;
      filter['sort_DateOfVoucher'] = 'desc';
    }


    return false;
  }
  /** End Detail Form */


  onObjectChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          formGroup.get('ObjectName').setValue(selectedData.Name);
          formGroup.get('ObjectPhone').setValue(selectedData.Phone);
          formGroup.get('ObjectEmail').setValue(selectedData.Email);
          formGroup.get('ObjectAddress').setValue(selectedData.Address);
        }
      }
    }
  }

  calculatToMoney(detail: FormGroup, source?: string) {
    const toMoney = detail.get('Amount').value;
    return toMoney;
  }

  toMoney(formItem: FormGroup, detail?: FormGroup, source?: string, index?: number) {
    this.cms.takeUntil(this.componentName + '_ToMoney_ ' + index, 300).then(() => {
      // Call culate total
      const details = this.getDetails(formItem);
      let total = 0;
      for (let i = 0; i < details.controls.length; i++) {
        total += this.calculatToMoney(details.controls[i] as FormGroup);
      }
      formItem.get('_total').setValue(total);
    });
    return false;
  }


  async preview(formItem: FormGroup) {
    const data: CollaboratorSalarySlipModel = formItem.value;
    this.cms.openDialog(CollaboratorSalarySlipPrintComponent, {
      context: {
        showLoadinng: true,
        title: 'Xem trước',
        data: [data],
        idKey: ['Code'],
        onSaveAndClose: (priceReport: CollaboratorSalarySlipModel) => {
          this.saveAndClose();
        },
        onSaveAndPrint: (priceReport: CollaboratorSalarySlipModel) => {
          this.save();
        },
      },
    });
    return false;
  }

  getRawFormData() {
    return super.getRawFormData();
  }

  openRelativeVoucher(relativeVocher: any) {
    if (relativeVocher) this.cms.previewVoucher(this.cms.getObjectId(relativeVocher.type), relativeVocher);
    return false;
  }

  customIcons: { [key: string]: CustomIcon[] }[] = [];
  getCustomIcons(name: string): CustomIcon[] {
    if (this.customIcons[name]) return this.customIcons[name];
    return this.customIcons[name] = [{
      icon: 'plus-square-outline',
      title: this.cms.translateText('Common.addNewProduct'),
      status: 'success',
      states: {
        '<>': {
          icon: 'edit-outline',
          status: 'primary',
          title: this.cms.translateText('Common.editProduct'),
        },
        '': {
          icon: 'plus-square-outline',
          status: 'success',
          title: this.cms.translateText('Common.addNewProduct'),
        },
      },
      action: (formGroupCompoent: FormGroupComponent, formGroup: FormGroup, array: FormArray, index: number, option: { parentForm: FormGroup }) => {
        const currentProduct = this.cms.getObjectId(formGroup.get('Product').value);
        this.cms.openDialog(ProductFormComponent, {
          context: {
            inputMode: 'dialog',
            inputId: currentProduct ? [currentProduct] : null,
            showLoadinng: true,
            onDialogSave: (newData: ProductModel[]) => {
              console.log(newData);
              // const formItem = formGroupComponent.formGroup;
              const newProduct: any = { ...newData[0], id: newData[0].Code, text: newData[0].Name, Units: newData[0].UnitConversions?.map(unit => ({ ...unit, id: this.cms.getObjectId(unit?.Unit), text: this.cms.getObjectText(unit?.Unit) })) };
              formGroup.get('Product').patchValue(newProduct);
            },
            onDialogClose: () => {

            },
          },
          closeOnEsc: false,
          closeOnBackdropClick: false,
        });
      }
    }];
  }

  openRelativeVoucherChoosedDialog(formGroup: FormGroup) {
    this.cms.openDialog(ReferenceChoosingDialogComponent, {
      context: {
        components: {
          'COMMERCEPOSORDER': { title: 'Đơn hàng POS' },
          'SALES': { title: 'Phiếu bán hàng' },
          'PRICEREPORT': { title: 'Phiếu báo giá' },
        },
        // inputMode: 'dialog',
        onDialogChoose: async (chooseItems: any[], type?: string) => {
          console.log(chooseItems);
          const relationVoucher = formGroup.get('RelativeVouchers');
          const relationVoucherValue: any[] = (relationVoucher.value || []);
          const insertList = [];
          this.onProcessing();

          setTimeout(() => {
            this.onProcessed();
          }, 1000);
        },
      }
    })
    return false;
  }

  exportDetails(formItem: FormGroup) {
    const data = this.getRawFormData();
    const details = [];
    let no = 0;
    for (const detail of data.array[0].Details) {
      no++;
      details.push({
        STT: no,
        Sku: detail['Product']['Sku'],
        Product: this.cms.getObjectId(detail['Product']),
        ProductName: detail['Description'],
        ProductTaxName: detail['ProductTaxName'],
        Tax: detail['Tax'],
        Unit: this.cms.getObjectId(detail['Unit']),
        UnitName: this.cms.getObjectText(detail['Unit']),
        Price: detail['Price'],
        Quantity: detail['Quantity'],
        ToMoney: detail['ToMoney'],
      });
    }
    const sheet = XLSX.utils.json_to_sheet(details);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, 'Chi tiết đơn đặt mua hàng');
    XLSX.writeFile(workbook, 'DDMH-' + data.array[0].Code + ' - ' + data.array[0].Title + ' - NCC: ' + this.cms.getObjectId(data.array[0].Object) + ' - ' + data.array[0].ObjectName + '.xlsx');

  }

  fileName: string;

  chooseFileAndFillDetails(formItem: FormGroup, ev: any) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    if (!file) return;
    this.fileName = file.name;
    reader.onload = async (event) => {
      try {
        this.isProcessing = true;
        let chooseSheet = null;
        const data = reader.result;
        const workBook = XLSX.read(data, { type: 'binary' });
        let sheet = null;
        const jsonData = workBook.SheetNames.reduce((initial, name) => {
          sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.onProcessing();

        const sheets = Object.keys(jsonData);
        if (sheets.length > 1) {
          sheet = await new Promise((resove, reject) => {
            this.cms.openDialog(DialogFormComponent, {
              context: {
                cardStyle: { width: '500px' },
                title: 'File excel có nhiều hơn 1 sheet, mời bạn chọn sheet cần import',
                onInit: async (form, dialog) => {
                  return true;
                },
                onClose: async (form, dialog) => {
                  // ev.target.
                  return true;
                },
                controls: [
                  {
                    name: 'Sheet',
                    label: 'Sheet',
                    placeholder: 'Chọn sheet...',
                    type: 'select2',
                    initValue: sheets[0],
                    // focus: true,
                    option: {
                      data: sheets.map(m => ({ id: m, text: m })),
                      placeholder: 'Chọn sheet...',
                      allowClear: true,
                      width: '100%',
                      dropdownAutoWidth: true,
                      minimumInputLength: 0,
                      withThumbnail: false,
                      keyMap: {
                        id: 'id',
                        text: 'text',
                      },
                    }
                  },
                ],
                actions: [
                  {
                    label: 'Esc - Trở về',
                    icon: 'back',
                    status: 'basic',
                    keyShortcut: 'Escape',
                    action: async () => { return true; },
                  },
                  {
                    label: 'Chọn',
                    icon: 'generate',
                    status: 'success',
                    // keyShortcut: 'Enter',
                    action: async (form: FormGroup, formDialogConpoent: DialogFormComponent) => {

                      console.log(form.value);
                      chooseSheet = this.cms.getObjectId(form.get('Sheet').value);
                      resove(jsonData[chooseSheet]);

                      return true;
                    },
                  },
                ],
              },
              closeOnEsc: false,
              closeOnBackdropClick: false,
            });

          });
        } else {
          sheet = jsonData[sheets[0]];
          chooseSheet = sheets[0];
        }

        // Confirm mapping
        const tmpSheet: string[][] = XLSX.utils.sheet_to_json(workBook.Sheets[chooseSheet], { header: 1 });
        const columnList = tmpSheet[0].map((m: string) => {
          const id = m.split('/')[0];
          const text = m;
          return { id, text };
        });

        // Auto mapping
        const details = this.getDetails(formItem);
        if (details.controls.length != sheet.length) {
          this.cms.showToast('Số dòng trên file excel không khớp với số dòng trên đơn đặt hàng!', 'Không khớp số dòng!', { duration: 60000, status: 'warning' });
        }

        for (const row of sheet) {
          for (const colName in row) {
            const logicColName = colName.split('/')[0];
            row[logicColName] = row[colName];
          }
          let detailForm: FormGroup = null;
          if (row['Sku']) {
            detailForm = details.controls.find(f => f.get('Product')?.value?.Sku == row['Sku']) as FormGroup;
          } else if (row['SupplierSku']) {
            detailForm = details.controls.find(f => f.get('SupplierSku')?.value == row['SupplierSku']) as FormGroup;
          } else if (row['SupplierProductName']) {// Load product by product name map by supplier
            detailForm = details.controls.find(f => f.get('SupplierProductName')?.value == row['SupplierProductName']) as FormGroup;
          } else if (row['SupplierProductTaxName']) {// Load product by product name map by supplier
            detailForm = details.controls.find(f => f.get('ProductTaxName')?.value == row['SupplierProductTaxName']) as FormGroup;
          }
          if (detailForm) {
            if (row['SupplierSku']) detailForm.get('SupplierSku').setValue(row['SupplierSku']);
            if (row['SupplierProductName']) detailForm.get('SupplierProductName').setValue(row['SupplierProductName']);
            if (row['SupplierProductTaxName']) detailForm.get('ProductTaxName').setValue(row['SupplierProductTaxName']);
            if (row['SupplierTax']) detailForm.get('Tax').setValue(row['SupplierTax']);
            if (row['Price']) detailForm.get('Price').setValue(row['Price']);
          }

          if (!detailForm) {
            this.cms.showToast(row['ProductName'] + ' Không có trên đơn đặt hàng', 'Sản phẩm không có trên đơn đặt hàng !', { duration: 15000, status: 'warning', duplicatesBehaviour: 'previous', limit: 1 });
          } else {
            detailForm['IsImport'] = true;
          }
        }

        this.onProcessed();
        this.cms.showToast('Nhập chi tiết từ thành công', 'Hệ thống đã nhập các thông tin chi tiết trên file excel vào chi tiết tương ứng trên phiếu !', { duration: 15000, status: 'success' });
        return true;
      } catch (err) {
        console.error(err);
        this.onProcessed();
        this.cms.showToast(err, 'Có lỗi xảy ra trong quá trình nhập chi tiết!', { duration: 15000, status: 'danger', duplicatesBehaviour: 'previous', limit: 1 });
      }
    };
    reader.readAsBinaryString(file);
  }


  addDetailAfter(parentFormGroup: FormGroup, detail: FormGroup, index: number) {
    const formDetails = this.getDetails(parentFormGroup);
    const newDetailFormGroup = this.makeNewDetailFormGroup(parentFormGroup, { Type: 'CATEGORY' });
    formDetails.controls.splice(index + 1, 0, newDetailFormGroup);
  }

  onMoreBtnMessageClick(msg: any) {
    console.log(msg);
    this.cms.showDialog('Bình luận', msg.text, []);
  }

}
