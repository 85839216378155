<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('User.Group.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

                    <!-- <nav>
            Cập nhật nhóm người dùng
            <button nbButton status="danger" hero size="tiny" (click)="removeFormGroup(i)" style="float: right;"
              title="Xoá">
              <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
            <button nbButton status="info" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="arrow-back"></nb-icon>
            </button>
          </nav> -->
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <div class="row">
                            <input type="hidden" formControlName="Code_old">
                            <div class="col-sm-3">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Code'), 'warning')">
                                    <label class="label"><span class="valid">Mã nhóm (*)</span><span class="invalid">Mã nhóm là bắt buộc
                                            (*)</span></label>
                                    <input type="text" nbInput fullWidth formControlName="Code" placeholder="Mã nhóm">
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Parent'), 'warning')">
                                    <label class="label"><span class="valid">Nhóm cha (*)</span></label>
                                    <ngx-select2 [data]="parentList" formControlName="Parent" [select2Option]="select2OptionForParent">
                                    </ngx-select2>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                                    <label class="label"><span class="valid">Tên (*)</span><span class="invalid">Tên là bắt buộc
                                            (*)</span></label>
                                    <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên">
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label class="label">Diễn giải</label>
                                    <input type="text" nbInput fullWidth formControlName="Description" placeholder="Diễn giải">
                                </div>
                            </div>
                            <!-- <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="label">Người dùng thuộc nhóm</label>
                                    <ngx-select2 [data]="userList" formControlName="Users" [select2Option]="select2OptionForUsers">
                                    </ngx-select2>
                                </div>
                            </div> -->
                        </div>


                        <hr>
                        <label class="label">Danh sách người dùng</label>
                        <div class="row">
                            <div class="col-sm-1 label">Stt</div>
                            <div class="col-sm-10">
                                <div class="row">
                                    <div class="col-sm-6 label">Người dùng</div>
                                    <div class="col-sm-6 label">Vai trò</div>
                                </div>
                            </div>
                            <div class="col-sm-1" style="text-align: center;">
                                <button nbButton status="success" (click)="addUserFormGroup(i)" size="medium" style="width: 100%; margin-bottom: 3px;" hero>
                                    <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                                </button>
                            </div>
                        </div>

                        <!-- <hr> -->
                        <div formArrayName="Users" class="form-details">
                            <div class="form-detail-item" *ngFor="let user of getUsers(i).controls; let ir=index" [formGroup]="user">
                                <div class="row">
                                    <div class="col-sm-1">
                                        <div class="form-group">
                                            <!-- <label class="label">Stt</label> -->
                                            <input nbInput fullWidth disabled="true" value="{{ ir + 1 }}" style="text-align: center;">
                                            <input type="hidden" formControlName="Id">
                                        </div>
                                    </div>
                                    <div class="col-sm-10">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group" style="position: relative;">
                                                    <label class="label">Người dùng</label>
                                                    <ngx-select2 formControlName="User" [select2Option]="select2OptionForUsers"></ngx-select2>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label class="label">Vai trò</label>
                                                    <ngx-select2 [data]="roles" formControlName="Roles" [select2Option]="select2OptionForRoles">
                                                    </ngx-select2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-1">
                                        <div class="form-group">
                                            <!-- <label class="label">CM</label> -->
                                            <button nbButton status="danger" hero size="medium" (click)="removeUser(i, ir)" style="width: 100%; margin-bottom: 3px;">
                                                <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton status="warning" (click)="addFormGroup($event)" hero style="float: left;">Thêm cái nữa</button>
                        <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">Lưu & Đóng</button>
                        <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>