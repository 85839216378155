<div class="card-header-container size-{{size}}" >
  <div class="title item" [ngClass]="{'headtitle-nowrap': titleNowrap}">
    <nb-icon *ngIf="icon" [pack]="icon.pack" [icon]="icon.name"></nb-icon> {{title}}
  </div>
  <div class="controls item">
    <ngx-action-control-list [nowrap]="titleNowrap" [list]="controls" [option]="option?.controlOption">
      <ng-content id="start" select="#start"></ng-content>
      <ng-content id="end" select="#end"></ng-content>
    </ngx-action-control-list>
  </div>
</div>
