<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup" style="height: 100%">
    <div formArrayName="array" style="height: 100%">
        <div *ngFor="let formItem of array.controls; let i = index" style="height: 100%">
            <nb-card [nbSpinner]="isProcessing" class="small-header popup" style="margin-bottom: 0; height: 100%">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('List Báo Cáo Chiêt Khấu CTV' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase) + ' (ID: '+(formItem.value.Code || 'NEW')+')'" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div *ngIf="formItem.__state" class="component-state">
                        <nb-tag [status]="formItem.__state.status" appearance="filled" [text]="formItem.__state | objecttext"></nb-tag>
                    </div>
                    <div [formGroup]="formItem">
                        <div class="row dashed-radius-border">
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                    <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Page" label="Collaborator.Page.label" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                                    <ngx-select2 formControlName="Page" [select2Option]="select2OptionForPage" [data]="this.collaboratorService.pageList$ | async"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-lg-3">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <ngx-form-group [formGroup]="formItem" name="DateOfBegin" label="{{validateVoucherDate(formItem.get('DateOfBegin'), 'Từ ngày')}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                                            <input type="text" [owlDateTime]="DateOfBegin" [owlDateTimeTrigger]="DateOfBegin" nbInput fullWidth formControlName="DateOfBegin" placeholder="{{'Từ ngày' | translate | headtitlecase}}">
                                            <owl-date-time #DateOfBegin [startAt]="formItem.get('DateOfBegin')?.value || this.cms.lastVoucherDate"></owl-date-time>
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-6">
                                        <ngx-form-group [formGroup]="formItem" name="DateOfEnd" label="{{validateVoucherDate(formItem.get('DateOfEnd'), 'Đến ngày')}}" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                                            <input type="text" [owlDateTime]="DateOfEnd" [owlDateTimeTrigger]="DateOfEnd" nbInput fullWidth formControlName="DateOfEnd" placeholder="{{'Đến ngày' | translate | headtitlecase}}">
                                            <owl-date-time #DateOfEnd [startAt]="formItem.get('DateOfEnd')?.value || this.cms.lastVoucherDate"></owl-date-time>
                                        </ngx-form-group>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!-- Detail form -->
                         <div class="row dashed-radius-border">
                            <div class="col-sm-12">
                                <ngx-publisher-commission-report-list [titleNowrap]="true" #details [title]="'Báo cáo chiết khấu CTV'" [parentObject]="formItem" [parentData]="formItem.value" [extendsParams]="{eq_ReportList: formItem.value.Code}" style="display: block; height: calc(100vh - 25.3rem)"></ngx-publisher-commission-report-list>
                            </div>
                         </div>
                        <!-- End Detail form -->
                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview(formItem)" [disabled]="!form.valid || isProcessing">
                            <nb-icon nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="save()">{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>