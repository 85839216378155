<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Chương trình khuyến mại' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <div class="col-sm-9">
                <ngx-form-group [formGroup]="formItem" name="Name" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Common.name' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-md-3">
                <ngx-form-group [formGroup]="formItem" name="Code" label="ID" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="Code" placeholder="{{'ID' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Branchs" label="Chi nhánh" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                  <ngx-select2 formControlName="Branchs" [select2Option]="select2OptionForBranchs"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="DateOfStart" label="Ngày bắt đầu" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                  <input type="text" [owlDateTime]="DateOfStart" [owlDateTimeTrigger]="DateOfStart" nbInput fullWidth formControlName="DateOfStart" placeholder="{{'Ngày bắt đầu' | translate | headtitlecase}}">
                  <owl-date-time #DateOfStart></owl-date-time>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Type" label="Loại" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                  <ngx-select2 formControlName="Type" [select2Option]="select2OptionForType" (selectChange)="onObjectChange(formItem, $event, i)"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="CustomerGroups" label="Nhóm khách hàng" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                  <ngx-select2 formControlName="CustomerGroups" [select2Option]="select2OptionForCustomerGroup"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="DateOfEnd" label="Ngày kết thúc" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                  <input type="text" [owlDateTime]="DateOfEnd" [owlDateTimeTrigger]="DateOfEnd" nbInput fullWidth formControlName="DateOfEnd" placeholder="{{'Ngày kết thúc' | translate | headtitlecase}}">
                  <owl-date-time #DateOfEnd></owl-date-time>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="PromotionalForm" label="Hình thức khuyến mại" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                  <ngx-select2 formControlName="PromotionalForm" [select2Option]="select2OptionForPromotionalForm" (selectChange)="onPromotionalFormChange(formItem, $event, i)"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{formItem.get('Description').placeholder || ('Common.description' | translate | headtitlecase)}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Content" label="Nội dung" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Content" placeholder="{{formItem.get('Content').placeholder || ('Nội dung' | translate | headtitlecase)}}">
                </ngx-form-group>
              </div>
            </div>

            <hr>
            <label class="label">{{'Điều kiện và khuyến mại áp dụng' | translate | headtitlecase}}</label>
            <div class="row fit-row form-detail-header">
              <div class="fit-fist-col label column">Stt</div>
              <div class="row fit-content-column">
                <div class="col-sm-2 label column Column_Cond" *ngIf="['ORDER'].indexOf(formItem._Type.value | objectid) > -1">{{'Điều kiện' | translate | headtitlecase}}</div>
                <div class="col-sm-2 label column Column_CondProduct" *ngIf="['PRODUCT'].indexOf(formItem._Type.value | objectid) > -1">{{'Hàng hóa/Nhóm hàng hóa' | translate | headtitlecase}}</div>
                <div class="col-sm-2 label column Column_CondValue text-align-right">{{'Giá trị cần đạt' | translate | headtitlecase}}</div>
                <div class="col-sm-2 label column Column_PromoValue text-align-right">{{'Giá trị khuyến mại' | translate | headtitlecase}}</div>
                <div class="col-sm-2 label column Column_PromoUnit" *ngIf="['ORDERDISCOUNT', 'SCORE'].indexOf(formItem._PromotionalForm.value | objectid) > -1">{{'Đơn vị khuyến mại' | translate | headtitlecase}}</div>
                <div class="col-sm-2 label column Column_PromoProduct" *ngIf="['GIFT'].indexOf(formItem._PromotionalForm.value | objectid) > -1">{{'Quà tặng' | translate | headtitlecase}}</div>
              </div>
              <div class="fit-last-col column">
                <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addConditionFormGroup(formItem) && false" size="medium" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                </button>
              </div>
            </div>
            <div formArrayName="Conditions" class="form-details" [sortablejs]="formItem._Conditions" [sortablejsOptions]="{ handle: '.sorting-handle' }">
              <div class="form-detail-item" *ngFor="let condition of formItem.controls.Conditions.controls; let ir=index" [formGroup]="condition">
                <div class="row fit-row">
                  <div class="fit-fist-col label">
                    <div class="form-group">
                      <!-- <label class="label"><nb-icon pack="eva" icon="move-outline" style="font-size: 0.8rem"></nb-icon> Stt</label> -->
                      <input class="fit-sequence sorting-handle" nbInput fullWidth disabled="true" value="{{ ir + 1 }}" nbTooltip="Kéo thả để di chuyển">
                    </div>
                  </div>
                  <div class="row fit-content-column">
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-2 Column_Cond" *ngIf="['ORDER'].indexOf(formItem._Type.value | objectid) > -1">
                          <ngx-form-group [hideLabel]="true" [formGroup]="condition" name="Cond" label="Điều kiện" [array]="formItem._Conditions" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                            <ngx-select2 formControlName="Cond" (selectChange)="onConditionChange(formItem, condition, $event)" [select2Option]="select2OptionForCondition"></ngx-select2>
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-2 Column_CondProduct" *ngIf="['PRODUCT'].indexOf(formItem._Type.value | objectid) > -1">
                          <ngx-form-group [hideLabel]="true" [formGroup]="condition" name="CondProduct" label="Hàng hóa/Nhóm hàng hóa" [array]="formItem._Conditions" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                            <ngx-select2 formControlName="CondProduct" (selectChange)="onConditionChange(formItem, condition, $event)" [select2Option]="select2OptionForProductGroup"></ngx-select2>
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-2 Column_CondValue">
                          <ngx-form-group [hideLabel]="true" [formGroup]="condition" name="CondValue" label="Giá trị cần đạt" [array]="formItem._Conditions" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                            <input type="text" nbInput fullWidth formControlName="CondValue" placeholder="Giá trị cần đạt" class="align-right" [inputMask]="towDigitsInputMask">
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-2 Column_PromoValue">
                          <ngx-form-group [hideLabel]="true" [formGroup]="condition" name="PromoValue" label="Giá trị khuyến mại" [array]="formItem._Conditions" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                            <input type="text" nbInput fullWidth formControlName="PromoValue" placeholder="Giá trị khuyến mại" class="align-right" [inputMask]="towDigitsInputMask">
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-2 Column_PromoUnit" *ngIf="['ORDERDISCOUNT', 'SCORE'].indexOf(formItem._PromotionalForm.value | objectid) > -1">
                          <ngx-form-group [hideLabel]="true" [formGroup]="condition" name="PromoUnit" label="Đơn vị khuyến mại" [array]="formItem._Conditions" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                            <ngx-select2 formControlName="PromoUnit" [select2Option]="select2OptionForPromoUnit"></ngx-select2>
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-2 Column_PromoProduct" *ngIf="['GIFT'].indexOf(formItem._PromotionalForm.value | objectid) > -1">
                          <ngx-form-group [hideLabel]="true" [formGroup]="condition" name="PromoProduct" label="Quà tặng" [array]="formItem._Conditions" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                            <ngx-select2 formControlName="PromoProduct" [select2Option]="select2OptionForProductGroup"></ngx-select2>
                          </ngx-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fit-last-col">
                    <div class="form-group" style="text-align: right;">
                      <!-- <label class="label">Gở</label> -->
                      <button class="fit-control-button" nbButton status="danger" [outline]="true" hero size="medium" (click)="removeCondition(formItem, ir) && false">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                </div>



              </div>
              <div class="form-group" style="text-align: right;">
                <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addConditionFormGroup(formItem) && false" size="medium" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>Thêm điều kiện
                </button>
              </div>
              <!-- <div class="form-detail-item">
                <div class="row fit-row form-detail-footer">
                  <div class="fit-fist-col label">

                  </div>
                  <div class="row fit-content-column">
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-4">

                        </div>
                        <div class="col-sm-6" style="text-align: right; font-weight: bold;"></div>
                        <div class="col-sm-2" style="text-align: right; font-weight: bold; padding-right: 1.7rem;">

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fit-last-col">
                    <div class="form-group" style="text-align: right;">
                      <button class="fit-control-button" nbButton [outline]="false" status="success" (click)="addConditionFormGroup(formItem)" size="medium" hero>
                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>Thêm KPI Level
                      </button>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>

          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup() && false" hero style="float: left;">Thêm cái nữa</button>
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose() && false">Lưu & Đóng</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>