<div class="dialog-wrap {{inputMode}}" #dialogWrap>
  <nb-card [nbSpinner]="loading || data.__loading" *ngFor="let data of this.data; let i=index;" style="max-height: initial; margin: 0 auto; width: 23cm;" class="small-header">
    <nb-card-header>
      <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="title | headtitlecase" [controls]="actionButtonList"></ngx-card-header>
    </nb-card-header>
    <nb-card-body class="print-body" #printContent>
      <div id="print-area" style="position: relative;">
        <ngx-print-header></ngx-print-header>
        <div>
          <div class="print-title">{{ title | uppercase }}
          </div>
          <div class="under-title">{{ data.Code }} - {{ data.Created | date:'short' }}</div>
        </div>
        <div>
          <div style="display: flex;">
            <div style="flex: 1;">
              <div class="under-line">{{'Tiêu đề' | headtitlecase}} :
                {{renderValue(data.Title)}}
              </div>
            </div>
            <div>
              <div class="under-line">{{'Từ ngày' | headtitlecase}} :
                {{renderValue(data.DateOfBegin | date:'short')}}
              </div>
            </div>
          </div>
          <div style="display: flex;">
            <div style="flex: 1;">
              <div class="under-line">{{'Trang' | headtitlecase}} :
                {{renderValue(data.Page)}}
              </div>
            </div>
            <div>
              <div class="under-line">{{'Đến ngày' | headtitlecase}} :
                {{renderValue(data.DateOfEnd | date:'short')}}
              </div>
            </div>
          </div>

        </div>
        <div>
          <div>
            <table style="width: 100%;" class="print-voucher-detail-table">
              <thead class="print-voucher-detail-header">
                <td>#</td>
                <td style="white-space: nowrap">{{'Báo cáo' | translate | headtitlecase}}</td>
                <td style="white-space: nowrap">{{'ID App ProBox' | translate | headtitlecase}}</td>
                <td class="text-align-right" style="white-space: nowrap">{{'Doanh số' | translate | headtitlecase}}</td>
                <td class="text-align-right" style="white-space: nowrap">{{'Đã thu được' | translate | headtitlecase}}</td>
                <td class="text-align-right" style="white-space: nowrap">{{'CK Cơ bản' | translate | headtitlecase}}</td>
                <td class="text-align-right" style="white-space: nowrap">{{'CK Nâng cao' | translate | headtitlecase}}</td>
                <td class="text-align-right" style="white-space: nowrap">{{'CK Add-on' | translate | headtitlecase}}</td>
                <td class="text-align-right" style="white-space: nowrap">{{'Tổng CK' | translate | headtitlecase}}</td>
                <td class="text-align-right" style="white-space: nowrap">{{'CK thực nhận' | translate | headtitlecase}}</td>
                <td class="text-align-right" style="white-space: nowrap">{{'Trạng thái' | translate | headtitlecase}}</td>
              </thead>
              <tr class="print-voucher-detail-line" *ngFor="let detail of data.Details; let i = index">
                <td>{{i + 1}}</td>
                <td><a href="#" (click)="cms.previewVoucher('CLBRTPUBLISHERCOMMISSION', detail.Code) && false">{{detail.Code}}</a></td>
                <td>{{detail.ObjectName}}<br>Probox.vn/{{detail.Publisher | objectid}}</td>
                <td class="text-align-right" [innerHtml]="renderCurrency(detail.GenerateCredit)"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(detail.GeneratePaidAmount)"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(detail.BasicCommissionAmount)"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(detail.AdvanceCommissionAmount)"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(detail.AddonCommissionAmount)"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(detail.TotalCommissionAmount)"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(detail.RealTotalCommissionAmount)"></td>
                <td class="text-align-right" [innerHtml]="detail.State | objecttext"></td>
              </tr>
              <tr style="font-weight: bold;">
                <td colspan="2">Tổng cộng</td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data.GenerateCredit)"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data.GeneratePaidAmount)"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data.BasicCommissionAmount)"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data.AdvanceCommissionAmount)"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data.AddonCommissionAmount)"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data.TotalCommissionAmount)"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data.RealTotalCommissionAmount)"></td>
                <td class="text-align-right">&nbsp;</td>
              </tr>
            </table>
            {{data.Note}}
          </div>
        </div>
        <div>
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <div style="text-align: center; flex: 0 0 50%;">
            <br>
            Quản lý
            <br><br><br>
          </div>
          <div style="text-align: center; flex: 0 0 50%;">
            <br>
            Nhân viên
            <br><br><br>
          </div>
        </div>
        <div class="default-print-footer" [innerHTML]="cms.systemConfigs$.value.PARAMETERS.DEFAULT_PURCHASEORDER_PRINT_FOOTER"></div>
        <div class="default-probox-one-print-footer">{{cms.env.defaultPrintFooter}}</div>
      </div>
    </nb-card-body>
    <nb-card-footer *ngIf="data.RelativeVouchers && data.RelativeVouchers.length > 0" class="print-relative-vouchers">
      <span class="tag" *ngFor="let relationVoucher of data.RelativeVouchers" nbTooltip="{{relationVoucher.type?.text}}: {{relationVoucher | objectstext}}">
        <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.type?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
        <nb-icon pack="eva" icon="close-outline" class="close-btn"></nb-icon>
      </span>
    </nb-card-footer>
    <nb-card-footer>
      <div class="buttons-row">
        <button *ngIf="mode === 'print'" nbButton hero status="info" (click)="edit(data);">
          <nb-icon pack="eva" icon="edit"></nb-icon>{{'Common.edit' | translate | headtitlecase}}
        </button>
        <button nbButton hero status="info" (click)="prepareCopy(data);" style="float: left;">
          <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
        </button>
        <!-- <button nbButton hero status="primary" (click)="print();" [disabled]="!identifier"><nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}</button> -->
        <!-- <button nbButton hero status="info" (click)="exportExcel('excel');">{{'Common.exportTo' | translate:{to: 'Excel'} | headtitlecase}}</button>
            <button nbButton hero status="info" (click)="exportExcel('pdf');">{{'Common.exportTo' | translate:{to: 'PDF'} | headtitlecase}}</button> -->
        <!-- <button nbButton hero status="danger" [disabled]="['COMPLETE'].indexOf(data.State) > -1" (click)="approvedConfirm(data, i);">{{(processMapList[i]?.nextStateLabel || '') | translate | headtitlecase}}<nb-icon pack="eva" icon="skip-forward"></nb-icon></button> -->

        <!--State process buttons-->
        <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
        <!--End State process buttons-->

        <!-- <button nbButton hero status="danger" [disabled]="!data['AllowTransferToOrder']" (click)="transferToOrder(data);">
          <nb-icon pack="eva" icon="archive-outline"></nb-icon>{{'Chuyển đổi' | translate | headtitlecase}}
        </button> -->

        <button *ngIf="!sourceOfDialog || sourceOfDialog === 'form'" nbButton hero status="success" (click)="saveAndClose(data);">
          <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>