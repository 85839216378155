<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card class="small-header">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('User.Profile.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <div class="row">
                            <input type="hidden" formControlName="Code">
                            <div class="col-sm-6">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                                    <label class="label"><span class="valid">Tên (*)</span><span class="invalid">Bạn phải nhập tên</span></label>
                                    <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Email'), 'warning')">
                                    <label class="label"><span class="valid">Email (*)</span><span class="invalid">Bạn phải nhập email</span></label>
                                    <input type="text" nbInput fullWidth formControlName="Email" placeholder="Email">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Phone'), 'warning')">
                                    <label class="label"><span class="valid">Số điện thoại (*)</span><span class="invalid">Bạn phải nhập số điện thoại</span></label>
                                    <input type="text" nbInput fullWidth formControlName="Phone" placeholder="Số điện thoại">
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Password'), 'warning')">
                                    <label class="label"><span class="valid">Mật khẩu (*)</span><span class="invalid">Bạn phải nhập mật khẩu</span></label>
                                    <input type="password" nbInput fullWidth formControlName="Password" placeholder="Mật khẩu" autocomplete="new-password">
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group" [attr.state]="formItem.get('Password').value && formItem.get('Password').value !== formItem.get('RePassword').value ? 'warning' : ''">
                                    <label class="label"><span class="valid">Nhập lại mật khẩu (*)</span><span class="invalid">Bạn phải nhập lại mật khẩu (*)</span></label>
                                    <input type="password" nbInput fullWidth formControlName="RePassword" placeholder="Mật khẩu" autocomplete="new-password">
                                </div>
                            </div>
                        </div>

                        <hr>
                        <label class="label">{{'Common.extPhoneNumberList' | translate | headtitlecase}}</label>
                        <div class="row fit-row form-detail-header">
                            <div class="fit-fist-col label column">Stt</div>
                            <div class="row fit-content-column">
                                <div class="col-sm-2 label column">{{'Common.name' | translate | headtitlecase}}</div>
                                <div class="col-sm-2 label column">{{'Accounting.extension' | translate | headtitlecase}}</div>
                                <div class="col-sm-2 label column">{{'Accounting.password' | translate | headtitlecase}}</div>
                                <div class="col-sm-2 label column">{{'Common.domain' | translate | headtitlecase}}</div>
                                <div class="col-sm-2 label column">{{'Common.host' | translate | headtitlecase}}</div>
                                <div class="col-sm-1 label column">{{'Common.port' | translate | headtitlecase}}</div>
                                <div class="col-sm-1 label column">{{'Common.prodocol' | translate | headtitlecase}}</div>
                            </div>
                            <div class="fit-last-col column">
                                <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addUserPhoneExtensionFormGroup(i)" size="medium" hero>
                                    <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                                </button>
                            </div>
                        </div>
                        <div formArrayName="UserPhoneExtensions" class="form-details">
                            <div class="form-detail-item" *ngFor="let phoneExt of getUserPhoneExtensions(i).controls; let ir=index" [formGroup]="phoneExt">

                                <div class="row fit-row">
                                    <div class="fit-fist-col label">
                                        <div class="form-group">
                                            <!-- <label class="label">Stt</label> -->
                                            <input nbInput fullWidth disabled="true" value="{{ ir + 1 }}" style="text-align: center;">
                                        </div>
                                    </div>
                                    <div class="row fit-content-column">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="col-sm-2">
                                                    <div class="form-group">
                                                        <input type="text" nbInput fullWidth formControlName="DisplayName" placeholder="Display name">
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="form-group">
                                                        <input type="text" nbInput fullWidth formControlName="Extension" placeholder="Extension">
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="form-group">
                                                        <input type="text" nbInput fullWidth formControlName="Password" placeholder="Password">
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="form-group">
                                                        <input type="text" nbInput fullWidth formControlName="Domain" placeholder="Domain">
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="form-group">
                                                        <input type="text" nbInput fullWidth formControlName="Host" placeholder="Host">
                                                    </div>
                                                </div>
                                                <div class="col-sm-1">
                                                    <div class="form-group">
                                                        <input type="text" nbInput fullWidth formControlName="Port" placeholder="Port">
                                                    </div>
                                                </div>
                                                <div class="col-sm-1">
                                                    <div class="form-group">
                                                        <input type="text" nbInput fullWidth formControlName="Transport" placeholder="Transport">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="fit-last-col">
                                        <div class="form-group" style="text-align: right;">
                                            <!-- <label class="label">CM</label> -->
                                            <button class="fit-control-button" nbButton status="danger" hero size="medium" (click)="removeUserPhoneExtensionGroup(i, ir)">
                                                <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <hr>
                        <label class="label">{{'Email account' | translate | headtitlecase}}</label>
                        <div class="row fit-row form-detail-header">
                            <div class="fit-fist-col label column">Stt</div>
                            <div class="row fit-content-column">
                                <div class="col-sm-3 label column">{{'Host' | translate | headtitlecase}}</div>
                                <div class="col-sm-3 label column">{{'Port' | translate | headtitlecase}}</div>
                                <div class="col-sm-3 label column">{{'Username' | translate | headtitlecase}}</div>
                                <div class="col-sm-3 label column">{{'Password' | translate | headtitlecase}}</div>
                            </div>
                            <div class="fit-last-col column">
                                <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addEmailAccountFormGroup(i)" size="medium" hero>
                                    <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                                </button>
                            </div>
                        </div>
                        <div formArrayName="EmailAccounts" class="form-details">
                            <div class="form-detail-item" *ngFor="let emailAccount of getEmailAccounts(i).controls; let ie=index" [formGroup]="emailAccount">

                                <div class="row fit-row">
                                    <div class="fit-fist-col label">
                                        <div class="form-group">
                                            <!-- <label class="label">Stt</label> -->
                                            <input nbInput fullWidth disabled="true" value="{{ ir + 1 }}" style="text-align: center;">
                                        </div>
                                    </div>
                                    <div class="row fit-content-column">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <input type="text" nbInput fullWidth formControlName="Host" placeholder="Host...">
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <input type="text" nbInput fullWidth formControlName="Port" placeholder="Port...">
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <input type="text" nbInput fullWidth formControlName="Username" placeholder="Username...">
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <input type="text" nbInput fullWidth formControlName="Password" placeholder="Password...">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="fit-last-col">
                                        <div class="form-group" style="text-align: right;">
                                            <!-- <label class="label">CM</label> -->
                                            <button class="fit-control-button" nbButton status="danger" hero size="medium" (click)="removeEmailAccountGroup(i, ir)">
                                                <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton status="success" [disabled]="!form.valid" hero style="float: right;">Lưu Profile</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>
<div class="row">
    <div class="col-md-6">
        <ngx-user-locale-config></ngx-user-locale-config>
    </div>
    <div class="col-md-6">
        <ngx-theme-config></ngx-theme-config>
    </div>
</div>