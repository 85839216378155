<div class="dialog-wrap {{inputMode}}" #dialogWrap>
  <nb-card [nbSpinner]="loading || data.__loading" *ngFor="let data of this.data; let i=index;" style="max-height: initial; margin: 0 auto; width: 23cm;" class="small-header">
    <nb-card-header>
      <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="('BÁO CÁO CHIẾT KHẤU CỘNG TÁC VIÊN' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
    </nb-card-header>
    <nb-card-body class="print-body" #printContent>
      <div id="print-area" style="position: relative;">
        <ngx-print-header></ngx-print-header>
        <div>
          <div class="print-title">{{ 'BÁO CÁO CHIẾT KHẤU CTV' | translate:{action: '', definition: ''} | uppercase }}
          </div>
          <div class="under-title">{{ data.Code }} - {{ data.DateOfReport | date:'short' }}</div>
        </div>
        <div>
          <div style="display: flex;">
            <div style="flex: 1;">
              <div class="under-line">{{'Nhân viên/CTV' | translate | headtitlecase}} :
                {{renderValue(data.ObjectName)}}
              </div>
            </div>
            <div>
              <div class="under-line">{{'Common.phone' | translate | headtitlecase}} :
                {{renderValue(data.ObjectPhone)}}
              </div>
            </div>
            <div>
              <div class="under-line">{{'ID Liên hệ' | translate | headtitlecase}} :
                {{renderValue(data.Object | objectid)}}
              </div>
            </div>
          </div>
          <div style="display: flex;">
            <div style="flex: 1;" class="under-line">{{'Contact.address' | translate | headtitlecase}} : {{renderValue(data.ObjectAddress)}}</div>
            <div>
              <div class="under-line">{{'ID App ProBox.vn' | translate | headtitlecase}} :
                {{renderValue(data.Publisher | objectid)}}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <table style="width: 100%;" class="print-voucher-detail-table">
              <thead class="print-voucher-detail-header" style="vertical-align: bottom; white-space: nowrap;">
                <td>#</td>
                <td [innerHTML]="'Ngày<br>Chứng từ'"></td>
                <td [innerHTML]="'Tiêu đề'"></td>
                <td class="text-align-right" [innerHTML]="'Doanh số'"></td>
                <td class="text-align-right" [innerHTML]="'Đã<br>thu được'"></td>
                <td class="text-align-center" [innerHTML]="'Đã<br>T.Toán'"></td>
                <td class="text-align-right" [innerHTML]="'Chiết khấu<br>Cơ bản'"></td>
                <td class="text-align-right" [innerHTML]="'Chiết khấu<br>Nâng cao'"></td>
                <td class="text-align-right" [innerHTML]="'Chiết khấu<br>Add-on'"></td>
                <td class="text-align-right" [innerHTML]="'Tổng<br>Chiết khấu'"></td>
                <td class="text-align-right" [innerHTML]="'Chiết khấu<br>Thực nhận'"></td>
              </thead>
              <ng-container *ngFor="let reportPart of data.PartDetails">
                <tr class="print-voucher-detail-line">
                  <td colspan="11" style="font-weight: bold;">{{reportPart['ReportPartLabel']}}</td>
                </tr>
                <tr class="print-voucher-detail-line" *ngFor="let detail of reportPart['Details']; let i = index;">
                  <td>{{i + 1}}</td>
                  <td class="nowrap">{{detail.VoucherDate | date:'short'}}<br><a href="#" (click)="cms.previewVoucher(detail.VoucherType, detail.Voucher) && false">{{detail.Voucher}}</a></td>
                  <td>{{detail.VoucherDescription}}<br>{{detail.ObjectName}}</td>
                  <td class="text-align-right" [innerHtml]="renderCurrency(detail.GenerateCredit)"></td>
                  <td class="text-align-right" [innerHtml]="renderCurrency(detail.GeneratePaidAmount)+'<br>'+(detail.GenerateCredit ? ((detail.GeneratePaidAmount || 0)/detail.GenerateCredit*100).toFixed(2) : '0')+'%'"></td>
                  <td class="text-align-center">{{detail.IsFullPaid ? '√' : ''}}</td>
                  <td class="text-align-right" [innerHtml]="renderCurrency(detail.BasicCommissionAmount)"></td>
                  <td class="text-align-right" [innerHtml]="renderCurrency(detail.AdvanceCommissionAmount)"></td>
                  <td class="text-align-right" [innerHtml]="renderCurrency(detail.AddonCommissionAmount)"></td>
                  <td class="text-align-right" [innerHtml]="renderCurrency(detail.TotalCommissionAmount)"></td>
                  <td class="text-align-right" [innerHtml]="renderCurrency(detail.RealTotalCommissionAmount)"></td>
                </tr>
                <tr style="font-weight: bold;">
                  <td colspan="3" class="text-align-left">Tổng cộng kỳ: </td>
                  <td class="text-align-right" [innerHtml]="renderCurrency(reportPart['GenerateCredit'])"></td>
                  <td class="text-align-right" [innerHtml]="renderCurrency(reportPart['GeneratePaidAmount'])+'<br>'+(data.GenerateCredit ? ((data.GeneratePaidAmount || 0)/data.GenerateCredit*100).toFixed(2) : '0')+'%'"></td>
                  <td class="text-align-right"> </td>
                  <td class="text-align-right" [innerHtml]="renderCurrency(reportPart['BasicCommissionAmount'])"></td>
                  <td class="text-align-right" [innerHtml]="renderCurrency(reportPart['AdvanceCommissionAmount'])"></td>
                  <td class="text-align-right" [innerHtml]="renderCurrency(reportPart['AddonCommissionAmount'])"></td>
                  <td class="text-align-right" [innerHtml]="renderCurrency(reportPart['TotalCommissionAmount'])"></td>
                  <td class="text-align-right" [innerHtml]="renderCurrency(reportPart['RealTotalCommissionAmount'])"></td>
                </tr>
              </ng-container>
              <tr class="print-voucher-detail-line">
                <td colspan="11" style="font-weight: bold;">&nbsp;</td>
              </tr>
              <tr style="font-weight: bold;">
                <td colspan="3" class="text-align-left">Tổng cộng: </td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data['GenerateCredit'])"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data['GeneratePaidAmount'])+'<br>'+(data.GenerateCredit ? ((data.GeneratePaidAmount || 0)/data.GenerateCredit*100).toFixed(2) : '0')+'%'"></td>
                <td class="text-align-right"> </td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data['BasicCommissionAmount'])"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data['AdvanceCommissionAmount'])"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data['AddonCommissionAmount'])"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data['TotalCommissionAmount'])"></td>
                <td class="text-align-right" [innerHtml]="renderCurrency(data['RealTotalCommissionAmount'])"></td>
              </tr>
            </table>
            {{data.Note}}
          </div>
        </div>
        <div>
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <div style="text-align: center; flex: 0 0 50%;">
            <br>
            Quản lý
            <br><br><br>
          </div>
          <div style="text-align: center; flex: 0 0 50%;">
            <br>
            Cộng tác viên
            <br><br><br>
            {{data.ObjectName}}
          </div>
        </div>
        <div class="default-print-footer" [innerHTML]="cms.systemConfigs$.value.PARAMETERS.DEFAULT_PURCHASEORDER_PRINT_FOOTER"></div>
        <div class="default-probox-one-print-footer">{{cms.env.defaultPrintFooter}}</div>
      </div>
    </nb-card-body>
    <nb-card-footer *ngIf="data.RelativeVouchers && data.RelativeVouchers.length > 0" class="print-relative-vouchers">
      <span class="tag" *ngFor="let relationVoucher of data.RelativeVouchers" nbTooltip="{{relationVoucher.type?.text}}: {{relationVoucher | objectstext}}">
        <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.type?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
        <nb-icon pack="eva" icon="close-outline" class="close-btn"></nb-icon>
      </span>
    </nb-card-footer>
    <nb-card-footer>
      <div class="buttons-row">
        <button *ngIf="mode === 'print'" nbButton hero status="info" (click)="edit(data);">
          <nb-icon pack="eva" icon="edit"></nb-icon>{{'Common.edit' | translate | headtitlecase}}
        </button>
        <button nbButton hero status="info" (click)="prepareCopy(data);" style="float: left;">
          <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
        </button>
        <!-- <button nbButton hero status="primary" (click)="print();" [disabled]="!identifier"><nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}</button> -->
        <!-- <button nbButton hero status="info" (click)="exportExcel('excel');">{{'Common.exportTo' | translate:{to: 'Excel'} | headtitlecase}}</button>
            <button nbButton hero status="info" (click)="exportExcel('pdf');">{{'Common.exportTo' | translate:{to: 'PDF'} | headtitlecase}}</button> -->
        <!-- <button nbButton hero status="danger" [disabled]="['COMPLETE'].indexOf(data.State) > -1" (click)="approvedConfirm(data, i);">{{(processMapList[i]?.nextStateLabel || '') | translate | headtitlecase}}<nb-icon pack="eva" icon="skip-forward"></nb-icon></button> -->

        <!--State process buttons-->
        <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
        <!--End State process buttons-->

        <!-- <button nbButton hero status="danger" [disabled]="!data['AllowTransferToOrder']" (click)="transferToOrder(data);">
          <nb-icon pack="eva" icon="archive-outline"></nb-icon>{{'Chuyển đổi' | translate | headtitlecase}}
        </button> -->

        <button *ngIf="!sourceOfDialog || sourceOfDialog === 'form'" nbButton hero status="success" (click)="saveAndClose(data);">
          <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>