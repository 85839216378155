<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('User.ThemeConfig.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)"
                        [controls]="actionButtonList"></ngx-card-header>

                    <!-- <nav>
            {{ 'System.Config.userLocale' | translate:{definition: '', action: cms.translate.instant('Common.' + (this.id && this.id[0] ? 'update' : 'create'))} | headtitlecase }}
            <button nbButton status="primary" hero size="tiny" (click)="onFormReload()" style="float: right;"
              [disabled]="isProcessing" title="{{'Common.reload' | translate | headtitlecase}}">
              <nb-icon pack="eva" icon="refresh"></nb-icon>{{'Common.reload' | translate | headtitlecase}}
            </button>
          </nav> -->
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <div class="row">
                            <input type="hidden" [value]="i" formControlName="_index">
                            <div class="col-sm-12">
                                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Theme'), 'warning')">
                                    <label class="label"><span class="valid">{{'Common.theme' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Common.theme')} | headtitlecase }}
                      (*)</span></label>
                                    <nb-select size="medium" formControlName="Theme" fullWidth>
                                        <nb-option value="default">Default - Giao diện sáng</nb-option>
                                        <nb-option value="dark">Dark - Giao diện tối</nb-option>
                                        <nb-option value="cosmic">Cosmic - Giao diện tối màu tím</nb-option>
                                        <nb-option value="corporate">Corporate - Giao diện sáng phẳng</nb-option>
                                    </nb-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero><nb-icon pack="eva" icon="save"></nb-icon>{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>

        </div>
    </div>
</form>