import { ProcessMap } from "./models/process-map.model";

export const stateMap = {
    NEW: {
        state: 'NEW',
        label: 'Mới',
        id: 'NEW',
        text: 'Mới',
        confirmLabel: 'Mới',
        status: 'danger',
        color: 'red',
        outline: false,
        confirmTitle: 'Chuyển sang trạng thái MỚI',
        confirmText: 'Bạn có muốn chuyển sang trạng thái MỚI',
        responseTitle: 'Đã chuyển sang trạng thái MỚI',
        responseText: 'Đã chuyển sang trạng thái mới',
    } as ProcessMap,
    APPROVED: {
        state: 'APPROVED',
        label: 'Đã duyệt',
        id: 'APPROVED',
        text: 'Đã duyệt',
        confirmLabel: 'Duyệt',
        status: 'success',
        color: 'green',
        outline: true,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn duyệt không',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã duyệt',
    } as ProcessMap,
    COMMITED: {
        state: 'COMMITED',
        label: 'Đã chốt',
        id: 'COMMITED',
        text: 'Đã chốt',
        confirmLabel: 'Chốt',
        status: 'basic',
        color: 'gray',
        outline: true,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn chốt không',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã chốt',
    } as ProcessMap,
    PRICEREPORT: {
        state: 'PRICEREPORT',
        label: 'Báo giá',
        id: 'PRICEREPORT',
        text: 'Báo giá',
        confirmLabel: 'Báo giá',
        status: 'primary',
        color: 'blue',
        outline: true,
        confirmTitle: 'Báo giá',
        confirmText: 'Bạn có muốn báo giá',
        responseTitle: 'Đã báo giá',
        responseText: 'Đã báo giá',
    } as ProcessMap,
    PROCESSING: {
        state: 'PROCESSING',
        label: 'Đang xử lý',
        id: 'PROCESSING',
        text: 'Đang xử lý',
        confirmLabel: 'Xử lý',
        status: 'danger',
        color: 'red',
        outline: false,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn xử lý không',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã chuyển sang trạng thái đang xử lý',
    } as ProcessMap,
    UNRECORDED: {
        state: 'UNRECORDED',
        label: 'Đã hủy',
        id: 'UNRECORDED',
        text: 'Đã hủy',
        status: 'warning',
        color: 'yellow',
        confirmLabel: 'Hủy',
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn hủy phiếu không ?',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã hủy phiếu',
    } as ProcessMap,
    CONFIRMED: {
        state: 'CONFIRMED',
        label: 'Đã xác nhận',
        id: 'CONFIRMED',
        text: 'Đã xác nhận',
        status: 'primary',
        color: 'blue',
        confirmLabel: 'Xác nhận',
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn xác nhận',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã xác nhận',
    } as ProcessMap,
    NOTJUSTAPPROVED: {
        state: 'NOTJUSTAPPROVED',
        label: 'Chưa duyệt',
        id: 'NOTJUSTAPPROVED',
        text: 'Chưa duyệt',
        confirmLabel: 'Bở duyệt',
        status: 'warning',
        color: 'yellow',
        outline: false,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn bỏ duyệt',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã bỏ duyệt',
    } as ProcessMap,
    INQUEUE: {
        state: 'INQUEUE',
        label: 'Hàng đợi',
        id: 'INQUEUE',
        text: 'Hàng đợi',
        confirmLabel: 'Chuyển sang hàng đợi',
        status: 'warning',
        color: 'yellow',
        outline: false,
        confirmTitle: 'Chuyển sang hàng đợi',
        confirmText: 'Bạn có muốn chuyển sang trạng thái đợi ?',
        responseTitle: 'Đã chuyển sang hàng đợi',
        responseText: 'Đã chuyển sang hàng đợi thành công',
    } as ProcessMap,
    QUEUE: {
        state: 'QUEUE',
        label: 'Chờ xử lý',
        id: 'QUEUE',
        text: 'Chờ xử lý',
        confirmLabel: 'Đẩy vào hàng đợi',
        status: 'warning',
        color: 'yellow',
        outline: false,
        confirmTitle: 'Chuyển sang hàng đợi',
        confirmText: 'Bạn có muốn chuyển sang trạng thái đợi ?',
        responseTitle: 'Đã chuyển sang hàng đợi',
        responseText: 'Đã chuyển sang hàng đợi thành công',
    } as ProcessMap,
    CONFIRMATIONREQUESTED: {
        state: 'CONFIRMATIONREQUESTED',
        label: 'Yêu cầu xác nhận',
        id: 'CONFIRMATIONREQUESTED',
        text: 'Yêu cầu xác nhận',
        confirmLabel: 'Yêu cầu xác nhận',
        status: 'info',
        color: 'lightblue',
        outline: false,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn yêu cầu xác nhận',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã yêu cầu xác nhận',
    } as ProcessMap,
    ACCEPTANCE: {
        state: 'ACCEPTANCE',
        label: 'Tiếp nhận',
        id: 'ACCEPTANCE',
        text: 'Tiếp nhận',
        confirmLabel: 'Tiếp nhận',
        status: 'info',
        color: 'lightblue',
        outline: false,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn tiếp nhận',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã tiếp nhận',
    } as ProcessMap,
    RUNNING: {
        state: 'RUNNING',
        label: 'Đang chạy',
        id: 'RUNNING',
        text: 'Đang chạy',
        confirmLabel: 'Khởi chạy',
        status: 'danger',
        color: 'red',
        outline: true,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn khởi chạy',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã khởi chạy',
    } as ProcessMap,
    COMPLETED: {
        state: 'COMPLETED',
        label: 'Đã hoàn tất',
        id: 'COMPLETED',
        text: 'Đã hoàn tất',
        confirmLabel: 'Hoàn tất',
        status: 'basic',
        color: 'gray',
        outline: true,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn hoàn tất',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã hoàn tất',
    } as ProcessMap,
    RETURN: {
        state: 'RETURN',
        label: 'Trả hàng',
        id: 'RETURN',
        text: 'Trả hàng',
        confirmLabel: 'Trả hàng',
        status: 'danger',
        color: 'red',
        outline: true,
        confirmText: 'Bạn có muốn chuyển sang trạng thái trả hàng',
        responseTitle: 'Trả hàng',
        responseText: 'Đã chuyển sang trạng thái trả hàng',
    } as ProcessMap,
    TRANSPORT: {
        state: 'TRANSPORT',
        label: 'Đang vận chuyển',
        id: 'TRANSPORT',
        text: 'Đang vận chuyển',
        confirmLabel: 'Vận chuyển',
        status: 'primary',
        color: 'blue',
        outline: true,
        confirmText: 'Bạn có muốn chuyển sang trạng thái đang vận chuyển',
        responseTitle: 'Đang vận chuyển',
        responseText: 'Đã chuyển sang trạng thái đang vận chuyển',
    } as ProcessMap,
    DELIVERED: {
        state: 'DELIVERED',
        label: 'Đã giao hàng',
        id: 'DELIVERED',
        text: 'Đã giao hàng',
        confirmLabel: 'Đã giao hàng',
        status: 'success',
        color: 'green',
        outline: true,
        confirmText: 'Bạn có muốn chuyển sang trạng thái đã giao hàng',
        responseTitle: 'Đã giao hàng',
        responseText: 'Đã chuyển sang trạng thái đã giao hàng',
    } as ProcessMap,
    DEPLOYMENT: {
        state: 'DEPLOYMENT',
        label: 'Triển khai',
        id: 'DEPLOYMENT',
        text: 'Triển khai',
        confirmLabel: 'Triển khai',
        status: 'primary',
        color: 'blue',
        outline: false,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn triển khai',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã chuyển sang trạng thái triển khai',
    } as ProcessMap,
    DEPLOYED: {
        state: 'DEPLOYED',
        label: 'Đã triển khai',
        id: 'DEPLOYED',
        text: 'Đã triển khai',
        confirmLabel: 'Hoàn tất triển khai',
        status: 'primary',
        color: 'blue',
        outline: false,
        confirmTitle: 'Hoàn tất triển khai',
        confirmText: 'Bạn có muốn hoàn tất triển khai ?',
        responseTitle: 'Hoàn tất triển khai',
        responseText: 'Đã hoàn tất triển khai !',
    } as ProcessMap,
    DEPLOYMENTED: {
        state: 'DEPLOYMENTED',
        label: 'Đã triển khai',
        id: 'DEPLOYMENTED',
        text: 'Đã triển khai',
        confirmLabel: 'Hoàn tất triển khai',
        status: 'primary',
        color: 'blue',
        outline: false,
        confirmTitle: 'Hoàn tất triển khai',
        confirmText: 'Bạn có muốn hoàn tất triển khai ?',
        responseTitle: 'Hoàn tất triển khai',
        responseText: 'Đã hoàn tất triển khai !',
    } as ProcessMap,
    APPROVALREQUESTED: {
        state: 'APPROVALREQUESTED',
        label: 'Đã duyệt yêu cầu',
        id: 'APPROVALREQUESTED',
        text: 'Đã duyệt yêu cầu',
        confirmLabel: 'Yêu cầu phê duyệt',
        status: 'warning',
        color: 'yellow',
        outline: false,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn phê duyệt yêu cầu',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Yêu cầu đã được phê duyệt',
    } as ProcessMap,
    DISTRIBUTED: {
        state: 'DISTRIBUTED',
        label: 'Đã phát hành',
        id: 'DISTRIBUTED',
        text: 'Đã phát hành',
        confirmLabel: 'Bạn có muốn phát hành',
        status: 'info',
        color: 'lightblue',
        outline: true,
        confirmTitle: 'Bạn có muốn phát hành',
        confirmText: 'Bạn có muốn phát hành',
        responseTitle: 'Đã phát hành',
        responseText: 'Đã phát hành',
    } as ProcessMap,
    PENDING: {
        state: 'PENDING',
        label: 'Đang chờ',
        id: 'PENDING',
        text: 'Đang chờ',
        confirmLabel: 'Bạn có muốn chuyển sang trạng thái đang chờ',
        status: 'info',
        color: 'lightblue',
        outline: true,
        confirmTitle: 'Chuyển trạng thái đang chờ',
        confirmText: 'Bạn có muốn chuyển sang trạng thái đang chờ',
        responseTitle: 'Đã chuyển sang trạng thái đang chờ',
        responseText: 'Đã chuyển sang trạng thái đang chờ',
    } as ProcessMap,
    ASSIGNED: {
        state: 'ASSIGNED',
        label: 'Đã cấp phát',
        id: 'ASSIGNED',
        text: 'Đã cấp phát',
        confirmLabel: 'Bạn có muốn cấp phát',
        status: 'success',
        color: 'green',
        outline: true,
        confirmTitle: 'Bạn có muốn cấp phát',
        confirmText: 'Bạn có muốn cấp phát',
        responseTitle: 'Đã cấp phát',
        responseText: 'Đã cấp phát',
    } as ProcessMap,
    NOTJUSTDISTRIBUTED: {
        state: 'NOTJUSTDISTRIBUTED',
        label: 'Chưa phát hành',
        id: 'NOTJUSTDISTRIBUTED',
        text: 'Chưa phát hành',
        status: 'warning',
        color: 'yellow',
        confirmLabel: 'Hủy phát hành',
        confirmTitle: 'Bạn có muốn hủy phát hành ?',
        confirmText: 'Bạn có muốn hủy phát hành ?',
        responseTitle: 'Đã hủy phát hành',
        responseText: 'Đã hủy phát hành',
    } as ProcessMap,
    LOCKED: {
        state: 'LOCKED',
        status: 'danger',
        id: 'LOCKED',
        text: 'danger',
        color: 'red',
        label: 'Đã khóa',
        confirmLabel: 'Khóa',
        confirmTitle: 'Bạn có muốn khóa ?',
        confirmText: 'Bạn có muốn khóa ?',
        responseTitle: 'Đã khóa',
        responseText: 'Đã khóa',
    } as ProcessMap,
    BLOCKED: {
        state: 'BLOCKED',
        label: 'Bị chặn',
        id: 'BLOCKED',
        text: 'Bị chặn',
        status: 'danger',
        color: 'red',
        confirmLabel: 'Chặn',
        outline: true,
        confirmTitle: 'Bạn có muốn chặn ?',
        confirmText: 'Bạn có muốn chặn ?',
        responseTitle: 'Đã chặn',
        responseText: 'Đã chặn',
    } as ProcessMap,
    REGISTERED: {
        state: 'REGISTERED',
        label: 'Chờ duyệt hồ sơ',
        id: 'REGISTERED',
        text: 'Chờ duyệt hồ sơ',
        status: 'primary',
        color: 'blue',
        outline: true,
        confirmLabel: 'Chuyển sang chờ duyệt hồ sơ',
        confirmTitle: 'Bạn có muốn chuyển sang chờ duyệt hồ sơ ?',
        confirmText: 'Bạn có muốn chuyển sang chờ duyệt hồ sơ ?',
        responseTitle: 'Đã chuyển sang chờ duyệt hồ sơ',
        responseText: 'Đã chuyển sang chờ duyệt hồ sơ',
    } as ProcessMap,
    FREEZE: {
        state: 'FREEZE',
        label: 'Lạnh',
        id: 'FREEZE',
        text: 'Lạnh',
        confirmLabel: 'Chuyển sang Lạnh',
        status: 'info',
        color: 'lightblue',
        outline: true,
        confirmTitle: 'Chuyển sang Lạnh',
        confirmText: 'Bạn có muốn chuyển sang trạng thái Lạnh',
        responseTitle: 'Chuyển sang trạng thái Lạnh',
        responseText: 'Đã chuyển sang trạng thái Lạnh',
    } as ProcessMap,
    COOL: {
        state: 'COOL',
        label: 'Mát',
        id: 'COOL',
        text: 'Mát',
        confirmLabel: 'Chuyển sang Mát',
        status: 'primary',
        color: 'blue',
        outline: true,
        confirmTitle: 'Chuyển sang Mát',
        confirmText: 'Bạn có muốn chuyển sang trạng thái Mát',
        responseTitle: 'Chuyển sang trạng thái Mát',
        responseText: 'Đã chuyển sang trạng thái Mát',
    } as ProcessMap,
    WARM: {
        state: 'WARM',
        label: 'Ấm',
        id: 'WARM',
        text: 'Ấm',
        confirmLabel: 'Chuyển sang Ấm',
        status: 'warning',
        color: "yellow",
        outline: true,
        confirmTitle: 'Chuyển sang Ấm',
        confirmText: 'Bạn có muốn chuyển sang trạng thái Ấm',
        responseTitle: 'Chuyển sang trạng thái Ấm',
        responseText: 'Đã chuyển sang trạng thái Ấm',
    } as ProcessMap,
    HOT: {
        state: 'HOT',
        label: 'Nóng',
        id: 'HOT',
        text: 'Nóng',
        confirmLabel: 'Chuyển sang Nóng',
        status: 'danger',
        color: 'red',
        outline: true,
        confirmTitle: 'Chuyển sang Nóng',
        confirmText: 'Bạn có muốn chuyển sang trạng thái Nóng',
        responseTitle: 'Chuyển sang trạng thái Nóng',
        responseText: 'Đã chuyển sang trạng thái Nóng',
        nextState: 'CONVERTED',
    } as ProcessMap,
    CONVERTED: {
        state: 'CONVERTED',
        label: 'Đã chuyển đổi',
        id: 'CONVERTED',
        text: 'Đã chuyển đổi',
        confirmLabel: 'Chuyển đổi',
        status: 'basic',
        color: 'gray',
        outline: true,
        confirmTitle: 'Chuyển đổi',
        confirmText: 'Bạn có muốn chuyển đổi',
        responseTitle: 'Chuyển đổi',
        responseText: 'Đã chuyển đổi',
    } as ProcessMap,
    SUCCESS: {
        state: 'SUCCESS',
        label: 'Thành công',
        id: 'SUCCESS',
        text: 'Thành công',
        confirmLabel: 'Chuyển sang thành công',
        status: 'success',
        color: 'green',
        outline: true,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn duyệt không',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Thành công',
    } as ProcessMap,
    FAILED: {
        state: 'FAILED',
        label: 'Thất bại',
        id: 'FAILED',
        text: 'Thất bại',
        confirmLabel: 'Chuyển sang thất bại',
        status: 'warning',
        color: 'orange',
        outline: true,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn duyệt không',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Thất bại',
    } as ProcessMap,
};