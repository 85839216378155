<div class="dialog-wrap {{inputMode}}" #dialogWrap>
    <nb-card [nbSpinner]="loading || data.__loading" *ngFor="let data of this.data; let i=index;" style="max-height: initial; margin: 0 auto; width: 23cm;" class="small-header">
      <nb-card-header>
        <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="data['Title'] || ('Hợp đồng' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
      </nb-card-header>
      <nb-card-body class="print-body" #printContent>
        <div id="print-area" style="position: relative;">
          <div [innerHTML]="data.Content | safehtml"></div>
        </div>
      </nb-card-body>
      <nb-card-footer *ngIf="data.RelativeVouchers && data.RelativeVouchers.length > 0" class="print-relative-vouchers">
        <span class="tag" *ngFor="let relationVoucher of data.RelativeVouchers" nbTooltip="{{relationVoucher.type?.text}}: {{relationVoucher | objectstext}}">
          <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.type?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
          <nb-icon pack="eva" icon="close-outline" class="close-btn"></nb-icon>
        </span>
      </nb-card-footer>
      <nb-card-footer>
        <div class="buttons-row">
          <button *ngIf="mode === 'print'" nbButton hero status="info" (click)="edit(data);">
            <nb-icon pack="eva" icon="edit"></nb-icon>{{'Common.edit' | translate | headtitlecase}}
          </button>
          <button nbButton hero status="info" (click)="prepareCopy(data);" style="float: left;">
            <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
          </button>
          <!-- <button nbButton hero status="primary" (click)="print();" [disabled]="!identifier"><nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}</button> -->
          <!-- <button nbButton hero status="info" (click)="exportExcel('excel');">{{'Common.exportTo' | translate:{to: 'Excel'} | headtitlecase}}</button>
              <button nbButton hero status="info" (click)="exportExcel('pdf');">{{'Common.exportTo' | translate:{to: 'PDF'} | headtitlecase}}</button> -->
          <!-- <button nbButton hero status="danger" [disabled]="['COMPLETE'].indexOf(data.State) > -1" (click)="approvedConfirm(data, i);">{{(processMapList[i]?.nextStateLabel || '') | translate | headtitlecase}}<nb-icon pack="eva" icon="skip-forward"></nb-icon></button> -->
  
          <!--State process buttons-->
          <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
          <!--End State process buttons-->
  
          <!-- <button nbButton hero status="danger" [disabled]="!data['AllowTransferToOrder']" (click)="transferToOrder(data);">
            <nb-icon pack="eva" icon="archive-outline"></nb-icon>{{'Chuyển đổi' | translate | headtitlecase}}
          </button> -->
  
          <button *ngIf="!sourceOfDialog || sourceOfDialog === 'form'" nbButton hero status="success" (click)="saveAndClose(data);">
            <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
          </button>
        </div>
      </nb-card-footer>
    </nb-card>
  </div>