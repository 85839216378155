<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)"
  (keydown.enter)="onControlEnter($event)" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Sales.PriceReport.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            {{ 'Sales.PriceTable.title' | translate:{definition: '', action: cms.translate.instant('Common.' + (this.id && this.id[0] ? 'update' : 'create'))} | headtitlecase }}
            <button nbButton status="danger" hero size="tiny" (click)="goback()" style="float: right;"
              title="{{'Common.close' | translate | headtitlecase}}">
              <nb-icon pack="eva" icon="close"></nb-icon>{{'Common.close' | translate | headtitlecase}}
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              [disabled]="isProcessing" title="{{'Common.reload' | translate | headtitlecase}}">
              <nb-icon pack="eva" icon="refresh"></nb-icon>{{'Common.reload' | translate | headtitlecase}}
            </button>
            <button nbButton status="primary" hero size="tiny" (click)="preview(formItem)" style="float: right;"
              [disabled]="isProcessing" title="{{'Common.print' | translate | headtitlecase}}">
              <nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()"
              [disabled]="!canUndo || isProcessing" style="float: right;"
              title="{{'Common.undo' | translate | headtitlecase}}">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>{{'Common.undo' | translate | headtitlecase}}
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code">
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Supplier'), 'warning')">
                  <label class="label"><span
                      class="valid">{{'Voucher.organizationOrPersornal' | translate | headtitlecase}}</span><span
                      class="invalid">
                      {{'Voucher.organizationOrPersornal' | translate | headtitlecase}} (*)</span></label>
                  <ngx-select2 formControlName="Supplier" [select2Option]="select2ContactOption"
                    (selectChange)="onSupplierChange(formItem, $event, i)"></ngx-select2>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SupplierName'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.name' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Common.name')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="SupplierName"
                    placeholder="{{'Common.name' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SupplierPhone'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.phone' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Common.phone')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="SupplierPhone"
                    placeholder="{{'Common.phone' | translate | headtitlecase}}">
                </div>
              </div>
              <!-- <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Recipient'), 'warning')">
                  <label class="label"><span
                      class="valid">{{'Common.recipient' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Common.recipient')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Recipient"
                    placeholder="{{'Common.recipient' | translate | headtitlecase}}">
                </div>
              </div> -->
              <div class="col-sm-9">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SupplierAddress'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.address' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Common.address')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="SupplierAddress"
                    placeholder="{{'Common.address' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SupplierEmail'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.email' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Common.email')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="SupplierEmail"
                    placeholder="{{'Common.email' | translate | headtitlecase}}">
                </div>
              </div>
              <!-- <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SupplierTaxCode'), 'warning')">
                  <label class="label"><span class="valid">{{'Contact.taxCode' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Contact.taxCode')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="SupplierTaxCode"
                    placeholder="{{'Contact.taxCode' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SupplierBankName'), 'warning')">
                  <label class="label"><span class="valid">{{'Bank.name' | translate | headtitlecase}}</span><span
                      class="invalid">Tên ngân hàng
                      là{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Bank.name')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="SupplierBankName"
                    placeholder="{{'Bank.name' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SupplierBankCode'), 'warning')">
                  <label class="label"><span class="valid">{{'Bank.code' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Bank.code')} | headtitlecase }}
                      buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="SupplierBankCode"
                    placeholder="{{'Bank.code' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group"
                  [attr.state]="formControlValidate(formItem.get('DirectReceiverName'), 'warning')">
                  <label class="label"><span
                      class="valid">{{'Sales.directReceiver' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Sales.directReceiver')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="DirectReceiverName"
                    placeholder="{{'Sales.directReceiver' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('DeliveryAddress'), 'warning')">
                  <label class="label"><span
                      class="valid">{{'Sales.deliveryAddress' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Sales.deliveryAddress')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="DeliveryAddress"
                    placeholder="{{'Sales.deliveryAddress' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('PaymentStep'), 'warning')">
                  <label class="label"><span
                      class="valid">{{'Sales.paymentStep' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Sales.paymentStep')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="PaymentStep"
                    placeholder="{{'Sales.paymentStep' | translate | headtitlecase}}">
                </div>
              </div>
              <div class="col-sm-9">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Title'), 'warning')">
                  <label class="label"><span class="valid">{{'Common.title' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Common.title')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Title"
                    placeholder="{{'Common.title' | translate | headtitlecase}}">
                </div>
              </div> -->
              <!-- <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('DateOfApprove'), 'warning')">
                  <label class="label"><span
                      class="valid">{{'Sales.dateOfApprove' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Sales.dateOfApprove')} | headtitlecase }}
                      (*)</span></label>
                  <input type="text" [owlDateTime]="DateOfApprove" [owlDateTimeTrigger]="DateOfApprove" nbInput
                    fullWidth formControlName="DateOfApprove"
                    placeholder="{{'Common.dateTime' | translate | headtitlecase}}">
                  <owl-date-time #DateOfApprove></owl-date-time>
                </div>
              </div> -->
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Description'), 'warning')">
                  <label class="label"><span
                      class="valid">{{'Common.description' | translate | headtitlecase}}</span><span
                      class="invalid">{{ 'Form.requireLabel' | translate:{field: cms.translate.instant('Common.description')} | headtitlecase }}
                      (*)</span></label>
                  <textarea nbInput fullWidth formControlName="Description"
                    placeholder="{{'Common.description' | translate | headtitlecase}}" rows="4"></textarea>
                </div>
              </div>
            </div>

            <!-- Detail form -->
            <!-- <hr> -->
            <!-- <label class="label">{{'Voucher.productOrservice' | translate | headtitlecase}}</label> -->

            <div class="row">
              <div class="col-sm-12">
                <div class="row title">
                  <div class="col-md-8">
                    <label class="label"><span class="valid">Chi tiết bảng giá thu mua</span></label>
                  </div>
                  <div class="col-md-4">
                    <button type="button" nbButton status="danger" hero size="small"
                      (click)="chooseFile.click() && false" [disabled]="isProcessing"
                      title="{{'Common.choose' | translate | headtitlecase}}" style="float: right; margin-bottom: 1rem;">
                      <nb-icon pack="eva" icon="file"></nb-icon>{{'Common.upload' | translate | headtitlecase}}
                    </button>
                    <input type="file" #chooseFile (change)="onFileChange($event, formItem)" style="display: none;">
                  </div>
                </div>
                <div class="ag-grid-wrap"
                  style="height: 500px; border: #ccc 1px solid; border-radius: 3px; overflow: hidden;">
                  <ag-grid-angular #agGrid style="width: 100%; height: 100%;" id="myGrid" class="ag-theme-balham"
                    [modules]="modules"
                    [columnDefs]="columnDefs"
                    [floatingFilter]="false"
                    [debug]="true" [enableCellTextSelection]="enableCellTextSelection"
                    [defaultColDef]="defaultColDef"
                    [rowSelection]="rowSelection"
                    [rowDeselection]="true"
                    [cacheOverflowSize]="cacheOverflowSize"
                    [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
                    [infiniteInitialRowCount]="infiniteInitialRowCount"
                    [maxBlocksInCache]="maxBlocksInCache"
                    [cacheBlockSize]="cacheBlockSize"
                    [getRowNodeId]="getRowNodeId"
                    [components]="components"
                    [rowData]="rowData"
                    [multiSortKey]="multiSortKey"
                    [rowDragManaged]="rowDragManaged"
                    [getRowHeight]="getRowHeight"
                    [rowHeight]="rowHeight"
                    [rowModelType]="rowModelType"
                    [paginationPageSize]="paginationPageSize"
                    [pagination]="pagination"
                    [enterMovesDownAfterEdit]="true"
                    (gridReady)="onGridReady($event)"
                    (columnResized)="onColumnResized()"
                    (rowSelected)="onRowSelected()"
                    >
                  </ag-grid-angular>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12" style="text-align: right;">

              </div>
            </div>
            <!-- End Detail form -->


          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton hero status="primary" (click)="preview(formItem)" [disabled]="!form.valid || isProcessing">
              <nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
            </button>
            <!-- <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button> -->
            <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero
              (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
            <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;"
              hero>{{'Common.save' | translate | headtitlecase}}</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
