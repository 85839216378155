<div class="row">
  <div class="col-sm-6">
    <ngx-user-locale-config></ngx-user-locale-config>
  </div>
  <div class="col-sm-6">
    <ngx-user-locale-config></ngx-user-locale-config>
  </div>
  <div class="col-sm-6">
    <ngx-user-locale-config></ngx-user-locale-config>
  </div>
  <div class="col-sm-6">
    <ngx-user-locale-config></ngx-user-locale-config>
  </div>
</div>
