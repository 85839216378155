<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card [nbSpinner]="isProcessing" class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Hợp đồng' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <ngx-form-group [formGroup]="formItem" name="Title" label="Common.title" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="Title" placeholder="{{'Common.title' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-3">
                                        <ngx-form-group [formGroup]="formItem" name="Template" label="Template" [array]="array" [index]="i" [allowCopy]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                                            <ngx-select2 formControlName="Template" [select2Option]="select2OptionForTemplate" (selectChange)="onTemplateChange(formItem, $event, i)"></ngx-select2>
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-3">
                                        <ngx-form-group [formGroup]="formItem" name="Code" label="Mã hợp đồng {Code}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="Code" placeholder="{{'Mã hợp đồng' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-6">
                                        <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description {Description}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-3">
                                        <ngx-form-group [formGroup]="formItem" name="ValidFromDate" label="Có hiệu lực từ ngày {ValidFromDate}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" [owlDateTime]="ValidFromDate" [owlDateTimeTrigger]="ValidFromDate" nbInput fullWidth formControlName="ValidFromDate" placeholder="{{'Có hiệu lực từ ngày' | translate | headtitlecase}}">
                                            <owl-date-time #ValidFromDate [pickerType]="'calendar'"></owl-date-time>
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-3">
                                        <ngx-form-group [formGroup]="formItem" name="ValidToDate" label="Có hiệu lực đến ngày {ValidToDate}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" [owlDateTime]="ValidToDate" [owlDateTimeTrigger]="ValidToDate" nbInput fullWidth formControlName="ValidToDate" placeholder="{{'Có hiệu lực đến ngày' | translate | headtitlecase}}">
                                            <owl-date-time #ValidToDate [pickerType]="'calendar'"></owl-date-time>
                                        </ngx-form-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="label label-with-border-below">{{'Thông tin bên A' | translate | headtitlecase}}</label>
                                <div class="row dashed-radius-border">
                                    <div class="col-sm-12">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="AObject" label="Bên A" [array]="array" [index]="i" [allowCopy]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="AObject" [select2Option]="select2OptionForContact" (selectChange)="onAObjectChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="AObjectName" label="Tên {AObjectName}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="AObjectName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="AObjectPhone" label="SĐT {AObjectPhone}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="AObjectPhone" placeholder="{{'Common.phone' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-9">
                                                <ngx-form-group [formGroup]="formItem" name="AObjectAddress" label="Địa chỉ {AObjectAddress}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="AObjectAddress" placeholder="{{'Common.address' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="AObjectEmail" label="Email {AObjectEmail}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="AObjectEmail" placeholder="{{'Common.email' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="AObjectBusinessCode" label="Mã số doanh nghiệp {AObjectBusinessCode}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="AObjectBusinessCode" placeholder="{{'Mã số doanh nghiệp' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ABusinessLicenseImage" label="Hình giấy phép kinh doanh" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <ngx-file-input formControlName="ABusinessLicenseImage" [alowUploadByLink]="false"></ngx-file-input>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ADelegateNoteImage" label="Hình giấy ủy quyền" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <ngx-file-input formControlName="ADelegateNoteImage" [alowUploadByLink]="false"></ngx-file-input>
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ADelegator" label="Người đại diện" [array]="array" [index]="i" [allowCopy]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="ADelegator" [select2Option]="select2OptionForContact" (selectChange)="onADelegatorChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="ADelegatorName" label="Tên người đại diện {ADelegatorName}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ADelegatorName" placeholder="{{'Tên người đại diện' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ADelegatorPosition" label="Chức vị người đại diện {ADelegatorPosition}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ADelegatorPosition" placeholder="{{'Chức vị người đại diện' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ASigningDelegator" label="Người đại diện ký kết" [array]="array" [index]="i" [allowCopy]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="ASigningDelegator" [select2Option]="select2OptionForContact" (selectChange)="onASigningDelegatorChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ASigningDelegatorName" label="Tên người đại diện ký kết {ASigningDelegatorName}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ASigningDelegatorName" placeholder="{{'Tên người đại diện ký kết' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ASigningDelegatorPosition" label="Chức vị người đại diện ký kết {ASigningDelegatorPosition}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ASigningDelegatorPosition" placeholder="{{'Chức vị người đại diện ký kết' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="AIdentityPhoneNumber" label="Số điện thoại đinh danh {AIdentityPhoneNumber}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="AIdentityPhoneNumber" placeholder="{{'Mã số doanh nghiệp' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="label label-with-border-below">{{'Thông tin bên B' | translate | headtitlecase}}</label>
                                <div class="row dashed-radius-border">
                                    <div class="col-sm-12">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="BObject" label="Bên B" [array]="array" [index]="i" [allowCopy]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="BObject" [select2Option]="select2OptionForContact" (selectChange)="onBObjectChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="BObjectName" label="Tên {BObjectName}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="BObjectName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="BObjectIdentityPhoneNumber" label="SĐT định danh {BObjectIdentityPhoneNumber}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="BObjectIdentityPhoneNumber" placeholder="{{'SĐT định danh' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-9">
                                                <ngx-form-group [formGroup]="formItem" name="BObjectAddress" label="Địa chỉ {BObjectAddress}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="BObjectAddress" placeholder="{{'Common.address' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="BObjectEmail" label="Email {BObjectEmail}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="BObjectEmail" placeholder="{{'Common.email' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="BObjectIdentityCardNumber" label="CCCD {BObjectIdentityCardNumber}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="BObjectIdentityCardNumber" placeholder="{{'CCCD' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="BObjectBirthday" label="Ngày sinh {BObjectBirthday}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" [owlDateTime]="BObjectBirthday" [owlDateTimeTrigger]="BObjectBirthday" nbInput fullWidth formControlName="BObjectBirthday" placeholder="{{'Ngày sinh' | translate | headtitlecase}}">
                                                    <owl-date-time [pickerType]="'calendar'" #BObjectBirthday [startAt]="formItem.get('BObjectBirthday')?.value || this.cms.lastVoucherDate"></owl-date-time>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="BObjectPlaceOfOrigin" label="Nơi sinh {BObjectPlaceOfOrigin}" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="BObjectPlaceOfOrigin" placeholder="{{'Nơi sinh' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="BObjectIdentityCardImage" label="Hình CCCD" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <ngx-file-input formControlName="BObjectIdentityCardImage" [alowUploadByLink]="false"></ngx-file-input>
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="BBusinessLicenseImage" label="Giấy phép kinh doanh" [array]="array" [index]="i" [allowCopy]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                                                    <ngx-file-input formControlName="BBusinessLicenseImage" [alowUploadByLink]="false"></ngx-file-input>
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="BDelegateNoteImage" label="Hình giấy phép ủy quyền" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                                    <ngx-file-input formControlName="BDelegateNoteImage" [alowUploadByLink]="false"></ngx-file-input>
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="Content" label="Nội dung" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                    <ckeditor [editor]="Editor" formControlName="Content" [config]="ckEditorConfig"></ckeditor>
                                </ngx-form-group>
                            </div>
                        </div>
                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview(formItem)" [disabled]="!form.valid || isProcessing">
                            <nb-icon nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="save()">{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>