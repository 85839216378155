import { ProcessMap } from "../../models/process-map.model";
import { stateMap } from "../../state.map";

export class CollaboratorStateMap {

    static orderStateMap: { [key: string]: ProcessMap } = {
        '': {
            ...stateMap.NEW,
            nextState: 'PROCESSING',
            nextStates: [
                stateMap.PROCESSING,
            ],
        },
        NOTJUSTAPPROVED: {
            ...stateMap.NEW,
            id: 'NOTJUSTAPPROVED',
            state: 'NOTJUSTAPPROVED',
            nextState: 'PROCESSING',
            nextStates: [
                stateMap.PROCESSING,
            ],
        },
        PROCESSING: {
            ...stateMap.PROCESSING,
            nextState: 'APPROVED',
            nextStates: [
                { ...stateMap.APPROVED, confirmLabel: 'Chốt đơn', confirmText: 'Bạn có muốn chốt đơn', status: 'success' },
                stateMap.UNRECORDED,
            ],
        },
        APPROVED: {
            ...stateMap.APPROVED,
            label: 'Chốt đơn',
            nextState: 'COMPLETED',
            nextStates: [
                // AppModule.depploymentState,
                // AppModule.transportState,
                stateMap.COMPLETED,
                stateMap.UNRECORDED,
            ],
        },
        DEPLOYED: {
            ...stateMap.DEPLOYED,
            nextState: 'COMPLETED',
            nextStates: [
                stateMap.COMPLETED,
                stateMap.UNRECORDED,
            ],
        },
        COMPLETED: {
            ...stateMap.COMPLETED,
            nextState: 'UNRECORDED',
            nextStates: [
                stateMap.UNRECORDED,
            ],
        },
        RETURN: {
            ...stateMap.RETURN,
            nextState: 'COMPLETED',
            nextStates: [
                // { ...AppModule.completeState, status: 'success' },
                stateMap.COMPLETED,
                stateMap.UNRECORDED,
            ],
        },
        UNRECORDED: {
            ...stateMap.UNRECORDED,
            nextState: 'PROCESSING',
            nextStates: [
                stateMap.PROCESSING,
            ],
        },
    };

    static opportunityStateMap: { [key: string]: ProcessMap } = {
        '': {
            ...stateMap.NEW,
            nextState: 'FREEZE',
            nextStates: [
                stateMap.FREEZE,
            ],
        },
        NOTJUSTAPPROVED: {
            ...stateMap.NEW,
            nextState: 'FREEZE',
            nextStates: [
                stateMap.FREEZE,
            ],
        },
        NEW: {
            ...stateMap.NEW,
            nextState: 'FREEZE',
            nextStates: [
                stateMap.FREEZE,
            ],
        },
        FREEZE: {
            ...stateMap.FREEZE,
            nextState: 'COOL',
            nextStates: [
                stateMap.COOL,
                stateMap.UNRECORDED,
            ],
        },
        COOL: {
            ...stateMap.COOL,
            nextState: 'WARM',
            nextStates: [
                stateMap.WARM,
                stateMap.UNRECORDED,
            ],
        },
        WARM: {
            ...stateMap.WARM,
            nextState: 'HOT',
            nextStates: [
                stateMap.HOT,
                stateMap.UNRECORDED,
            ],
        },
        HOT: {
            ...stateMap.HOT,
            nextState: 'CONVERTED',
            nextStates: [
                stateMap.CONVERTED,
                stateMap.UNRECORDED,
            ],
        },
        CONVERTED: {
            ...stateMap.CONVERTED,
            nextState: 'FREEZE',
            nextStates: [
                stateMap.UNRECORDED,
            ],
        },
        UNRECORDED: {
            ...stateMap.UNRECORDED,
            nextState: 'FREEZE',
            nextStates: [
                stateMap.FREEZE,
            ],
        },
    };

    static publisherCommissionStateMap = {
        CONFIRMATIONREQUESTED: {
            ...stateMap.CONFIRMATIONREQUESTED,
            nextState: 'CONFIRMED',
            nextStates: [
                stateMap.CONFIRMED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        CONFIRMED: {
            ...stateMap.CONFIRMED,
            nextState: 'COMMITED',
            nextStates: [
                stateMap.COMMITED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        COMMITED: {
            ...stateMap.COMMITED,
            nextState: 'UNRECORDED',
            nextStates: [
                stateMap.UNRECORDED
            ],
        } as ProcessMap,
        UNRECORDED: {
            ...stateMap.UNRECORDED,
            nextState: 'CONFIRMATIONREQUESTED',
            nextStates: [
                stateMap.CONFIRMATIONREQUESTED,
            ],
        } as ProcessMap,
        NOTJUSTAPPROVED: {
            ...stateMap.NOTJUSTAPPROVED,
            nextState: 'CONFIRMATIONREQUESTED',
            nextStates: [
                stateMap.CONFIRMATIONREQUESTED,
            ],
        } as ProcessMap,
        '': {
            ...stateMap.NOTJUSTAPPROVED,
            nextState: 'CONFIRMATIONREQUESTED',
            nextStates: [
                stateMap.CONFIRMATIONREQUESTED,
            ],
        } as ProcessMap,
    }

    static RUNNINGREQUESTED = {
        state: 'RUNNINGREQUESTED',
        id: 'RUNNINGREQUESTED',
        label: 'Yêu cầu chạy báo cáo',
        text: 'Yêu cầu chạy báo cáo',
        status: 'info',
        color: 'lightblue',
        outline: false,
        confirmLabel: 'Yêu cầu chạy báo cáo',
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn yêu cầu chạy báo cáo ?',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã yêu cầu chạy báo cáo',
        nextState: 'UNRECORDED',
    };

    static publisherCommissionListStateMap = {
        RUNNINGREQUESTED: {
            ...CollaboratorStateMap.RUNNINGREQUESTED,
            nextStates: [
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        RUNNING: {
            ...stateMap.RUNNING,
            nextState: 'UNRECORDED',
            nextStates: [
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        SUCCESS: {
            ...stateMap.SUCCESS,
            nextState: 'CONFIRMATIONREQUESTED',
            nextStates: [
                stateMap.CONFIRMATIONREQUESTED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        FAILED: {
            ...stateMap.FAILED,
            nextState: 'UNRECORDED',
            nextStates: [
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        CONFIRMATIONREQUESTED: {
            ...stateMap.CONFIRMATIONREQUESTED,
            nextState: 'COMMITED',
            nextStates: [
                stateMap.COMMITED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        COMMITED: {
            ...stateMap.COMMITED,
            nextState: 'UNRECORDED',
            nextStates: [
                stateMap.UNRECORDED
            ],
        } as ProcessMap,
        UNRECORDED: {
            ...stateMap.UNRECORDED,
            nextState: 'UNRECORDED',
            nextStates: [
                CollaboratorStateMap.RUNNINGREQUESTED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        NOTJUSTAPPROVED: {
            ...stateMap.NEW,
            state: 'NOTJUSTAPPROVED',
            id: 'NOTJUSTAPPROVED',
            nextState: 'UNRECORDED',
            nextStates: [
                CollaboratorStateMap.RUNNINGREQUESTED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        '': {
            ...stateMap.NEW,
            state: 'NOTJUSTAPPROVED',
            id: 'NOTJUSTAPPROVED',
            nextState: 'UNRECORDED',
            nextStates: [
                stateMap.UNRECORDED,
                CollaboratorStateMap.RUNNINGREQUESTED,
            ],
        } as ProcessMap,
    }

    static strategyStateMap = {
        APPROVED: {
            ...stateMap.APPROVED,
            nextState: 'RUNNING',
            nextStates: [
                stateMap.RUNNING,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        RUNNING: {
            ...stateMap.RUNNING,
            nextState: 'UNRECORDED',
            nextStates: [
                stateMap.COMPLETED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        COMPLETED: {
            ...stateMap.COMPLETED,
            nextState: 'COMPLETED',
            nextStates: [
            ],
        } as ProcessMap,
        UNRECORDED: {
            ...stateMap.UNRECORDED,
            nextState: 'APPROVED',
            nextStates: [
                stateMap.APPROVED,
            ],
        } as ProcessMap,
        NOTJUSTAPPROVED: {
            ...stateMap.NOTJUSTAPPROVED,
            nextState: 'RUNNING',
            nextStates: [
                stateMap.APPROVED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        '': {
            ...stateMap.NOTJUSTAPPROVED,
            nextState: 'RUNNING',
            nextStates: [
                stateMap.APPROVED,
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
    }
}
