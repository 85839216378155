import { ProcessMap } from "../../models/process-map.model";
import { stateMap } from "../../state.map";

export class ContractStateMap {


    static newState: ProcessMap = {
        state: 'NEW',
        label: 'Mới',
        confirmLabel: 'Chuyển sang Mới',
        status: 'danger',
        color: 'red',
        outline: false,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn chuyển sang trạng thái Mới',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã chuyển sang trạng thái Mới',
    };
    static lawInfoUpdateState: ProcessMap = {
        state: 'LAWINFOUPDATE',
        label: 'Đã cập nhật đủ thông tin pháp lý',
        confirmLabel: 'Cập nhật đủ thông tin pháp lý',
        status: 'warning',
        color: 'yellow',
        outline: false,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có chắc đã cập nhật đủ thông tin pháp lý?',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã chuyển sang trạng thái Cập nhật đủ thông tin pháp lý',
    };
    static contentLawApprovedState: ProcessMap = {
        state: 'CONTENTLAWAPPROVED',
        label: 'Đã duyệt pháp lý nội dung',
        confirmLabel: 'Duyệt pháp lý nội dung',
        status: 'info',
        color: 'lightblue',
        outline: false,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn duyệt pháp lý nội dung',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã duyệt pháp lý nội dung',
    };
    static signingRequestState: ProcessMap = {
        state: 'SIGNINGREQUEST',
        label: 'Đã trình ký',
        confirmLabel: 'Trình ký',
        status: 'primary',
        color: 'blue',
        outline: false,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn trình ký hợp đồng',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã trình ký hợp đồng',
    };
    static completedState: ProcessMap = {
        state: 'COMPLETED',
        label: 'Đã hoàn tất',
        confirmLabel: 'Hoàn tất',
        status: 'basic',
        color: 'gray',
        outline: false,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn hoàn tất hợp đồng',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã hoàn tất hợp đồng',
    };
    static destroyedState: ProcessMap = {
        state: 'DESTROYED',
        label: 'Đã hủy',
        confirmLabel: 'Hủy',
        status: 'basic',
        color: 'gray',
        outline: false,
        confirmTitle: 'Chuyển trạng thái',
        confirmText: 'Bạn có muốn hủy hợp đồng',
        responseTitle: 'Chuyển trạng thái',
        responseText: 'Đã hủy hợp đồng',
    };

    static contractStateMap: { [key: string]: ProcessMap } = {
        '': {
            ...ContractStateMap.newState,
            nextState: 'LAWINFOUPDATE',
            nextStates: [
                ContractStateMap.lawInfoUpdateState,
                ContractStateMap.destroyedState,
            ],
        },
        'NEW': {
            ...ContractStateMap.newState,
            nextState: 'LAWINFOUPDATE',
            nextStates: [
                ContractStateMap.lawInfoUpdateState,
                ContractStateMap.destroyedState,
            ],
        },
        'LAWINFOUPDATE': {
            ...ContractStateMap.lawInfoUpdateState,
            nextState: 'CONTENTLAWAPPROVED',
            nextStates: [
                ContractStateMap.contentLawApprovedState,
                ContractStateMap.destroyedState,
            ],
        },
        'CONTENTLAWAPPROVED': {
            ...ContractStateMap.contentLawApprovedState,
            nextState: 'SIGNINGREQUEST',
            nextStates: [
                ContractStateMap.signingRequestState,
                ContractStateMap.destroyedState,
            ],
        },
        'SIGNINGREQUEST': {
            ...ContractStateMap.signingRequestState,
            nextState: 'COMPLETED',
            nextStates: [
                ContractStateMap.completedState,
                ContractStateMap.destroyedState,
            ],
        },
        'COMPLETED': {
            ...ContractStateMap.completedState,
            nextState: 'DESTROYED',
            nextStates: [
                ContractStateMap.destroyedState,
            ],
        },
        'DESTROYED': {
            ...ContractStateMap.destroyedState,
            nextState: 'NEW',
            nextStates: [
                ContractStateMap.newState,
            ],
        },
    };

    static templateStateMap: { [key: string]: ProcessMap } = {
        '': {
            ...stateMap.NOTJUSTAPPROVED,
            nextState: 'APPROVED',
            nextStates: [
                stateMap.APPROVED,
            ]
        },
        'NOTJUSTAPPROVED': {
            ...stateMap.NOTJUSTAPPROVED,
            nextState: 'APPROVED',
            nextStates: [
                stateMap.APPROVED,
            ]
        },
        'APPROVED': {
            ...stateMap.APPROVED,
            nextState: 'NOTJUSTAPPROVED',
            nextStates: [
                stateMap.NOTJUSTAPPROVED,
            ]
        },
    };
}
