import { Emitter } from 'sip.js/lib/api/emitter';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService, NbDialogService, NbDialogRef } from '@nebular/theme';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { environment } from '../../../../../environments/environment.prod';
import { ActionControlListOption } from '../../../../lib/custom-element/action-control-list/action-control.interface';
import { DataManagerFormComponent } from '../../../../lib/data-manager/data-manager-form.component';
import { Model } from '../../../../models/model';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { RootServices } from '../../../../services/root.services';
import { AccountingService } from '../../../accounting/accounting.service';
import { AdminProductService } from '../../../admin-product/admin-product.service';
import { ContractPrintComponent } from '../../../contract/contract/contract-print/contract-print.component';
import { MobileAppService } from '../../../mobile-app/mobile-app.service';
import { ColDef, ColumnApi, GridApi, IRowNode } from '@ag-grid-community/core';
import { AgDynamicListComponent } from '../../../general/ag-dymanic-list/ag-dymanic-list.component';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';

@Component({
  selector: 'ngx-acc-manager-import-fb-account-form',
  templateUrl: './acc-manager-import-fb-account-form.component.html',
  styleUrls: ['./acc-manager-import-fb-account-form.component.scss']
})
export class AccManagerImportFbAccountFormComponent extends DataManagerFormComponent<Model> implements OnInit {

  @Input() onImport: (data: Model[]) => void;
  componentName: string = 'AccManagerImportFbAccountFormComponent';
  idKey = 'Code';
  baseFormUrl = '/contract/contract/form';
  apiPath = '/acc-manager/accounts';

  env = environment;

  locale = this.cms.getCurrentLoaleDataset();
  curencyFormat: CurrencyMaskConfig = this.cms.getCurrencyMaskConfig();
  numberFormat: CurrencyMaskConfig = this.cms.getNumberMaskConfig();

  // locale = this.commo nService.getCurrentLoaleDataset();
  priceCurencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0 };
  toMoneyCurencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0 };
  quantityFormat: CurrencyMaskConfig = { ...this.cms.getNumberMaskConfig(), precision: 2 };

  towDigitsInputMask = this.cms.createFloatNumberMaskConfig({
    digitsOptional: false,
    digits: 2
  });


  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastrService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public adminProductService: AdminProductService,
    public ref: NbDialogRef<AccManagerImportFbAccountFormComponent>,
    public mobileAppService: MobileAppService,
    public accountingService: AccountingService,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastrService, dialogService, cms);

    /** Append print button to head card */
    this.actionButtonList.splice(this.actionButtonList.length - 1, 0, {
      name: 'print',
      status: 'primary',
      label: this.cms.textTransform(this.cms.translate.instant('Common.print'), 'head-title'),
      icon: 'printer',
      title: this.cms.textTransform(this.cms.translate.instant('Common.print'), 'head-title'),
      size: 'medium',
      disabled: () => this.isProcessing,
      hidden: () => false,
      click: (event: any, option: ActionControlListOption) => {
        this.preview(option.form);
      },
    });

    // Ag-List
    this.columnDefs = [
      {
        ...agMakeSelectionColDef(this.cms),
        headerName: 'ID',
        field: 'Id',
        width: 100,
        valueGetter: 'node.data.Uuid',
        // sortingOrder: ['desc', 'asc'],
        initialSort: 'desc',
      },
      {
        headerName: 'Tên',
        field: 'Name',
        width: 150,
        filter: 'agTextColumnFilter',
        autoHeight: true,
      },
      {
        headerName: 'Username',
        field: 'Username',
        width: 150,
        filter: 'agTextColumnFilter',
        autoHeight: true,
      },
      {
        headerName: 'Password',
        field: 'Password',
        width: 150,
        filter: 'agTextColumnFilter',
        autoHeight: true,
      },
      {
        headerName: 'Email',
        field: 'Email',
        width: 300,
        filter: 'agTextColumnFilter',
        autoHeight: true,
      },
      {
        headerName: 'Cookie',
        field: 'Cookie',
        width: 150,
        filter: 'agTextColumnFilter',
        autoHeight: true,
      },
      {
        headerName: 'Token',
        field: 'Token',
        width: 150,
        filter: 'agTextColumnFilter',
        autoHeight: true,
      },
    ] as ColDef[];
  }

  select2OptionForTemplate = {
    ...this.cms.makeSelect2AjaxOption('/contract/templates', () => {
      return {
        includeIdText: true,
      };
    }, {
      placeholder: 'Chọn mẫu...',
      limit: 10,
      prepareReaultItem: (item) => {
        return item;
      }
    }),
    placeholder: 'Chọn mẫu...',
  };

  select2OptionForPlatform = {
    placeholder: 'Chọn nền tảng...',
    allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    data: []
  };

  // getRequestId(callback: (id?: string[]) => void) {
  //   callback(this.inputId);
  // }

  getRequestId(callback: (id?: string[]) => void) {
    // callback(this.inputId);
    return super.getRequestId(callback);
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {

    this.select2OptionForPlatform.data = await this.apiService.getPromise<Model[]>(this.apiPath + '/platforms');
    return super.init().then(async status => {

      if (this.isDuplicate) {
        // Clear id
        this.id = [];
        this.array.controls.forEach((formItem, index) => {
          formItem.get('Code').setValue('');
          // formItem.get('Title').setValue('Copy of: ' + formItem.get('Title').value);

        });
      }
      return status;
    });

  }

  /** Execute api get */
  executeGet(params: any, success: (resources: Model[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includeAccountPlatforms'] = true;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: Model[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: Model) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    });

  }

  makeNewFormGroup(data?: Model): FormGroup {
    const newForm = this.formBuilder.group({
      SystemUuid: [],
      // Name: [null, Validators.required],
      // Email: [null, Validators.required],
      // Passmail: [],
      // Birthday: [],
      // Avatar: [],
      // Banner: [],
      // AccountPlatforms: this.formBuilder.array([]),
    });
    if (data) {
      // data['Code_old'] = data['Code'];
      newForm.patchValue(data);
    }
    return newForm;
  }
  onAddFormGroup(index: number, newForm: FormGroup, formData?: Model): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }

  goback(): false {
    // super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/collaborator/opportunity/list']);
    } else {
      this.ref.close();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }

  async preview(formItem: FormGroup) {
    const data: Model = formItem.value;
    this.cms.openDialog(ContractPrintComponent, {
      context: {
        showLoadinng: true,
        title: 'Xem trước',
        data: [data],
        idKey: ['Code'],
        onSaveAndClose: (priceReport: Model) => {
          this.saveAndClose();
        },
        onSaveAndPrint: (priceReport: Model) => {
          this.save();
        },
      },
    });
    return false;
  }

  getRawFormData() {
    return super.getRawFormData();
  }

  fileName: string;
  chooseFileAndFillDetails(formItem: FormGroup, ev: any) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    if (!file) return;
    this.fileName = file.name;
    reader.onload = async (event) => {
      try {
        const importMap = [
          'Uuid',
          'Password',
          'Cookie',
          'Token',
          'Email',
          'XXX',
        ];
        // this.isProcessing = true;
        // let chooseSheet = null;
        const data = reader.result as string;
        const arr = data.split('\n');
        const table = [];
        for (const item of arr) {
          const rowData = item.split('|');
          const rowObject = {

          };
          for (const i in importMap) {
            rowObject[importMap[i]] = rowData[i];
          }
          rowObject['Username'] = rowObject['Uuid'];
          // if(!rowObject['Name']) {
          //   rowObject['Name'] = rowObject['Email'].split('@')[0];
          // }
          table.push(rowObject);
        }
        console.log(table);
        this.gridApi.setRowData(table);
        return true;
      } catch (err) {
        console.error(err);
        // this.onProcessed();
        this.cms.showToast(err, 'Có lỗi xảy ra trong quá trình nhập chi tiết!', { duration: 15000, status: 'danger', duplicatesBehaviour: 'previous', limit: 1 });
      }
    };
    reader.readAsBinaryString(file);
  }


  public gridApi: GridApi;
  public gridColumnApi: ColumnApi;
  public columnDefs: ColDef[];
  public gridParams;
  public gridParamsOfVoucher;
  onGridReady(params) {
    this.gridParams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadList();
  }

  onGridInitForVoucher(component: AgDynamicListComponent<any>) {
    const $this = this;
    let actionButtonList = component.actionButtonList;
    // actionButtonList = actionButtonList.filter(f => f.name != 'choose');

    if (component.parentObject) {
      component.parentObject['__Details'] = component;
    }

    actionButtonList = [];

    component.actionButtonList = actionButtonList;
  }

  loadList(callback?: (list: Model[]) => void) {
    if (this.gridApi) {

    }
  }
  onDetialsSelected(nodes: IRowNode<Model>[]) {
    console.log('On Details selected: ', nodes);
    if (nodes.length == 1) {
      // Load relative products
    }
  }

  import() {
    const accounts = [];
    this.gridApi.forEachNode((rowNode, index) => {
      console.log(rowNode, index);
      const rawDetail = {};
      for (const prop in rowNode.data) {
        rawDetail[prop] = this.cms.getObjectId(rowNode.data[prop]);
      }
      accounts.push(rawDetail);
    });
    console.log(accounts);
    this.onImport && this.onImport(accounts);
    this.close();
  }
}
