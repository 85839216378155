<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Cài đặt chiết khấu nâng cao cho CTV Bán Hàng' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('PublisherName'), 'warning')">
                  <label class="label"><span class="valid">Tên</span><span class="invalid">Tên là bắt buộc
                      (*)</span>
                  </label>
                  <input type="text" nbInput fullWidth formControlName="PublisherName" placeholder="Tên sản phẩm">
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Publisher'), 'warning')">
                  <label class="label"><span class="valid">ID</span><span class="invalid">ID là bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Publisher" maxlength="30" placeholder="ID">
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('IsDiscountByVoucher'), 'warning')">
                  <label class="label"><span class="valid">Giảm giá áp dụng voucher</span></label>
                  <nb-checkbox formControlName="IsDiscountByVoucher">Giảm giá áp dụng voucher</nb-checkbox>
                </div>
              </div>
              <div class="col-sm-4">
                <ngx-form-group [formGroup]="formItem" name="SelfOrderDiscount" label="Giảm giá x% khi tự đặt hàng trên app ProBox" [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                  <nb-checkbox class="form-group-icon-control" formControlName="IsSelfOrder">Giảm giá x%</nb-checkbox>
                  <input type="text" class="form-group-input-control" nbInput fullWidth formControlName="SelfOrderDiscount" placeholder="%" [inputMask]="towDigitsInputMask">
                </ngx-form-group>
              </div>
              <div class="col-sm-4">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('PlatformFee'), 'warning')">
                  <label class="label align-right"><span class="valid">Phí sàn CTV bán hàng (%)</span><span class="invalid">Phí sàn CTV bán hàng (*)</span>
                  </label>
                  <input type="text" nbInput fullWidth formControlName="PlatformFee" placeholder="%" [inputMask]="towDigitsInputMask">
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('IsUsed'), 'warning')">
                  <label class="label"><span class="valid">Điều kiện kinh doanh</span></label>
                  <nb-checkbox formControlName="IsUsed">Đã sử dụng qua sản phẩm</nb-checkbox>
                </div>
              </div>
              <div class="col-sm-2">
                <ngx-form-group [formGroup]="formItem" name="IsAutoExtended" label="Cho phép bán thêm" [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                  <nb-checkbox formControlName="IsAutoExtended" title="Cho phép team CSKH tư vấn bán thêm nhằm tiết kiệm chi phí vận chuyển cho khách"><span style="white-space:normal !important">Cho phép team CSKH tư vấn bán thêm</span></nb-checkbox>
                </ngx-form-group>
              </div>

              <label class="label">{{'I/ CÀI ĐẶT CHIẾC KHẤU CHO CTV BÁN HÀNG LEVEL 1 , TƯƠNG ỨNG DANH HIỆU CTV BÁN HÀNG ĐỒNG 1 TRÊN APP PROBOX' | translate | headtitlecase}}</label>
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-is-12 dashed-radius-border">
                <label class="label">{{'1.1 Hệ số chiết khấu' | translate | headtitlecase}}</label>
                <div class="row">
                  <div class="col-sm-3 label column">{{'Danh hiệu CTV bán hàng' | translate | headtitlecase}}</div>
                  <div class="col-sm-3 label column">{{'Cấp độ CTV Bán Hàng ' | translate | headtitlecase}}</div>
                  <div class="col-sm-3 label column">{{'Mô tả' | translate | headtitlecase}}</div>
                  <div class="col-sm-3 label column align-right">{{'Collaborator.Level.ratio' | translate | headtitlecase}} (%)</div>
                </div>

                <div class="form-details">
                  <hr>
                  <div class="form-detail-item">
                    <div class="row">
                      <div class="col-sm-3">
                        <ngx-form-group [hideLabel]="true" [formGroup]="formItem" name="Level1Badge" label="CTV Bán Hàng Đồng 1" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                          <input type="text"nbInput fullWidth formControlName="Level1Badge" placeholder="CTV Bán Hàng Đồng 1">
                        </ngx-form-group>
                      </div>
                      <div class="col-sm-3">
                        <ngx-form-group [hideLabel]="true" [formGroup]="formItem" name="Level1Label" label="Cấp độ CTV Bán Hàng " [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="Level1Label" placeholder="{{'CTV Bán Hàng Level 1' | translate | headtitlecase}}">
                        </ngx-form-group>
                      </div>
                      <div class="col-sm-3">
                        <ngx-form-group [hideLabel]="true" [formGroup]="formItem" name="Level1Description" label="Mô tả" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="Level1Description" placeholder="{{'Mô tả' | translate | headtitlecase}}">
                        </ngx-form-group>
                      </div>
                      <div class="col-sm-3">
                        <ngx-form-group [hideLabel]="true" [formGroup]="formItem" name="Level1CommissionRatio" label="Collaborator.Level.ratio" [array]="array" [index]="i" [allowCopy]="true" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="Level1CommissionRatio" placeholder="{{'Collaborator.Level.ratio' | translate | headtitlecase}}" [inputMask]="level1ComissionRatioInputMask">
                        </ngx-form-group>
                      </div>
                    </div>
                  </div>
                </div>

                
              </div>

              <label class="label">{{'II/ CÀI ĐẶT CHIẾC KHẤU CỘNG THÊM CHO CTV BÁN HÀNG LEVEL 2, TƯƠNG ỨNG DANH HIỆU CTV BÁN HÀNG BẠC 2 TRÊN APP PROBOX' | translate | headtitlecase}}</label>
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-is-12 dashed-radius-border">
                <label class="label">{{'Cài đặt Chiết khấu cho CTV Level 2' | translate | headtitlecase}}</label>
                <div class="row">
                  <div class="col-sm-2 label column">{{'Danh hiệu CTV bán hàng' | translate | headtitlecase}}</div>
                  <div class="col-sm-6 label column align-right">{{'KPI Số lượng bắt buột' | translate | headtitlecase}}</div>
                  <div class="col-sm-2 label column align-right">{{'OKR bắt buột (số sao)' | translate | headtitlecase}}</div>
                  <div class="col-sm-2 label column align-right">{{'Collaborator.Level.ratio' | translate | headtitlecase}} (%)</div>
                </div>

                <div class="form-details">
                  <hr>
                  <div class="form-detail-item">
                    <div class="row">
                      <div class="col-sm-2">
                        <ngx-form-group [hideLabel]="true" [formGroup]="formItem" name="Level2ExtBadge" label="Label" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                          <input type="text" nbInput fullWidth formControlName="Level2ExtBadge" placeholder="CTV Bán Hàng Bạc 2">
                        </ngx-form-group>
                      </div>
                      <div class="col-sm-6">
                        <ngx-form-group [hideLabel]="true" [formGroup]="formItem" name="Level2ExtRequiredKpi" label="Label" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="Level2ExtRequiredKpi" placeholder="KPI Số lượng bắt buột" [inputMask]="towDigitsInputMask">
                        </ngx-form-group>
                      </div>
                      <div class="col-sm-2">
                        <ngx-form-group [hideLabel]="true" [formGroup]="formItem" name="Level2ExtRequiredOkr" label="Phản hồi (số sao)" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="Level2ExtRequiredOkr" placeholder="{{'Phản hồi (số sao)' | translate | headtitlecase}}" [inputMask]="okrInputMask">
                        </ngx-form-group>
                      </div>
                      <div class="col-sm-2">
                        <ngx-form-group [hideLabel]="true" [formGroup]="formItem" name="Level2ExtAwardRatio" label="Collaborator.Level.ratio" [array]="array" [index]="i" [allowCopy]="true" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="Level2ExtAwardRatio" placeholder="{{'Collaborator.Level.ratio' | translate | headtitlecase}}" [inputMask]="currencyInputMask">
                        </ngx-form-group>
                      </div>
                      <div class="col-sm-2">
                        <ngx-form-group [formGroup]="formItem" name="Level2ExtLabel" label="Label" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                          <input type="text" nbInput fullWidth formControlName="Level2ExtLabel" placeholder="CTV Bán Hàng Level 2">
                        </ngx-form-group>
                      </div>
                      <div class="col-sm-10">
                        <ngx-form-group [hideLabel]="true" [formGroup]="formItem" name="Level2ExtDescription" label="Common.description" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="Level2ExtDescription" placeholder="{{'Common.description' | translate | headtitlecase}}">
                        </ngx-form-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <label class="label">{{'II/ CÀI ĐẶT CHIẾC KHẤU CỘNG THÊM CHO CTV BÁN HÀNG LEVEL 3, TƯƠNG ỨNG DANH HIỆU CTV BÁN HÀNG VÀNG 3 TRÊN APP PROBOX' | translate | headtitlecase}}</label>
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-is-12 dashed-radius-border">
                <label class="label">{{'Cài đặt Chiết khấu cho CTV Level 3' | translate | headtitlecase}}</label>
                <div class="row">
                  <div class="col-sm-2 label column">{{'Danh hiệu CTV bán hàng' | translate | headtitlecase}}</div>
                  <div class="col-sm-6 label column align-right">{{'KPI Số lượng bắt buột' | translate | headtitlecase}}</div>
                  <div class="col-sm-2 label column align-right">{{'OKR bắt buột (số sao)' | translate | headtitlecase}}</div>
                  <div class="col-sm-2 label column align-right">{{'Collaborator.Level.ratio' | translate | headtitlecase}} (%)</div>
                </div>

                <div class="form-details">
                  <hr>
                  <div class="form-detail-item">
                    <div class="row">
                      <div class="col-sm-2">
                        <ngx-form-group [hideLabel]="true" [formGroup]="formItem" name="Level3ExtBadge" label="Label" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                          <input type="text" nbInput fullWidth formControlName="Level3ExtBadge" placeholder="CTV Bán Hàng Vàng 3">
                        </ngx-form-group>
                      </div>
                      <div class="col-sm-6">
                        <ngx-form-group [hideLabel]="true" [formGroup]="formItem" name="Level3ExtRequiredKpi" label="Label" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="Level3ExtRequiredKpi" placeholder="KPI Số lượng bắt buột" [inputMask]="towDigitsInputMask">
                        </ngx-form-group>
                      </div>
                      <div class="col-sm-2">
                        <ngx-form-group [hideLabel]="true" [formGroup]="formItem" name="Level3ExtRequiredOkr" label="Phản hồi (số sao)" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="Level3ExtRequiredOkr" placeholder="{{'Phản hồi (số sao)' | translate | headtitlecase}}" [inputMask]="okrInputMask">
                        </ngx-form-group>
                      </div>
                      <div class="col-sm-2">
                        <ngx-form-group [hideLabel]="true" [formGroup]="formItem" name="Level3ExtAwardRatio" label="Collaborator.Level.ratio" [array]="array" [index]="i" [allowCopy]="true" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="Level3ExtAwardRatio" placeholder="{{'Collaborator.Level.ratio' | translate | headtitlecase}}" [inputMask]="currencyInputMask">
                        </ngx-form-group>
                      </div>
                      <div class="col-sm-2">
                        <ngx-form-group [formGroup]="formItem" name="Level3ExtLabel" label="Label" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                          <input type="text" nbInput fullWidth formControlName="Level3ExtLabel" placeholder="CTV Bán Hàng Level 3">
                        </ngx-form-group>
                      </div>
                      <div class="col-sm-10">
                        <ngx-form-group [hideLabel]="true" [formGroup]="formItem" name="Level3ExtDescription" label="Common.description" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="Level3ExtDescription" placeholder="{{'Common.description' | translate | headtitlecase}}">
                        </ngx-form-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              

            </div>

          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <!-- <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm cái nữa</button> -->
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">Lưu & Đóng</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>