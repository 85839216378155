<div class="dialog-wrap" #dialogWrap>
  <nb-card *ngFor="let data of this.data" style="max-height: initial; margin: 0 auto; width: 22cm;" class="small-header">
    <nb-card-header>
      <ngx-card-header [size]="size" [icon]="favicon"
        [title]="title ? title : ('Sales.PriceTable.title' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)"
        [controls]="actionButtonList"></ngx-card-header>
    </nb-card-header>
    <nb-card-body #printContent>
      <div id="print-area">
        <div>
          <div class="under-line">{{renderValue(data.Title)}}
          </div>
        </div>
        <div>
          <table style="width: 100%;" class="print-voucher-detail-table">
            <colgroup>
              <!--STT-->
              <col span="1" style="width: 1.5cm;">
              <!--Dien giai-->
              <col span="1" style="width: 15cm;">
              <!--DVT-->
              <col span="1" style="width: 2.5cm;">
              <!--So luong-->
              <!-- <col span="1" style="width: 2cm; text-align: right;"> -->
              <!--Don gia-->
              <!-- <col span="1" style="width: 2.8cm; text-align: right;"> -->
              <!--Thue-->
              <col span="1" style="width: 2cm;">
              <!--Thanh tien-->
              <!-- <col span="1" style="width: 2.7cm; text-align: right;"> -->
            </colgroup>
            <tr class="print-voucher-detail-header">
              <th>{{'Common.picture' | translate | headtitlecase}}</th>
              <th>{{'Sales.technicalDescription' | translate | headtitlecase}}</th>
              <th class="text-align-left">{{'Voucher.shortUnit' | translate | headtitlecase}}</th>
              <!-- <th class="text-align-right">{{'Voucher.shortQuantity' | translate | headtitlecase}}</th> -->
              <th class="text-align-right">{{'Voucher.price' | translate | headtitlecase}}</th>
              <!-- <th class="text-align-right">{{'Voucher.tax' | translate | headtitlecase}}</th> -->
              <!-- <th class="text-align-right">{{'Voucher.toMoney' | translate | headtitlecase}}</th> -->
            </tr>
            <tr class="print-voucher-detail-line" *ngFor="let detail of data.Details; let i = index">
              <!-- <td><div class="image-thumbnail" style="background-size: cover; background-repeat: no-repeat; width: 100%; height: 5cm; border-radius: 3px;" [ngStyle]="{'backgroundImage': 'url(\''+detail.PictureThumbnail + '?token='+apiService.getAccessToken()+'\')'}"></div></td> -->
              <td>
                <div class="print-voucher-detail-thumnail"
                  [ngStyle]="{'background-image': 'url('+(detail.FeaturePictureLarge + '?token='+apiService.getAccessToken())+')'}">
                </div>
              </td>
              <td>
                <div style="font-weight: bold;">{{detail.Name}}</div>
                <div [innerHTML]="renderValue(detail.Note)"></div>
              </td>
              <td class="text-align-left">{{cms.getObjectText(detail.Unit)}}</td>
              <!-- <td class="text-align-right">{{renderValue(detail.Quantity) | number}}</td> -->
              <td class="text-align-right">{{renderValue(detail.Price) | currency:'VND'}}</td>
              <!-- <td class="text-align-right">{{detail.Tax?.Label2}}</td> -->
              <!-- <td class="text-align-right">{{toMoney(detail) | currency:'VND'}}</td> -->
            </tr>
            <!-- <tr>
              <td colspan="6" class="text-align-right">{{'Voucher.total' | translate | headtitlecase}}</td>
              <td class="text-align-right">{{getTotal() | currency:'VND'}}</td>
            </tr> -->
          </table>
        </div>
        <div>
          <div class="under-line" *ngIf="data.Description">{{'Common.note' | translate | headtitlecase}}:
            {{renderValue(data.Description)}}</div>
          <!-- <div class="under-line">{{'Sales.deliveryAddress' | translate | headtitlecase}}:
            {{renderValue(data.DeliveryAddress)}}</div> -->
          <!-- <div class="under-line">{{'Sales.directReceiver' | translate | headtitlecase}}:
            {{renderValue(data.DirectReceiverName)}}</div> -->
        </div>
        <!-- <div style="display: flex; flex-wrap: wrap">
          <div style="text-align: center; flex: 0 0 50%;">
            <br>
            {{'Common.employee' | translate | headtitlecase}}
            <br><br><br>
          </div>
          <div style="text-align: center; flex: 0 0 50%;">
            <br>
            {{'Common.customer' | translate | headtitlecase}}
            <br><br><br>
          </div>
        </div> -->
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="buttons-row">
        <button nbButton hero status="primary" (click)="print();" [disabled]="!identifier">
          <nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
        </button>
        <button nbButton hero status="danger" (click)="saveAndClose();">
          <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
        </button>
        <button nbButton hero status="warning"
          (click)="exportExcel('excel');">{{'Common.exportTo' | translate:{to: 'Excel'} | headtitlecase}}</button>
        <button nbButton hero status="info"
          (click)="exportExcel('pdf');">{{'Common.exportTo' | translate:{to: 'PDF'} | headtitlecase}}</button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>
