<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="popup" style="width: 90%; margin: 0 auto;">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Thông tin liên hệ' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <div class="row">
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="ContactType" label="Loại liên hệ" [array]="array" [index]="i" [allowCopy]="false">
                                    <ngx-select2 [select2Option]="select2ContactType" formControlName="ContactType"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-1">
                                <ngx-form-group [formGroup]="formItem" name="Title" label="Danh xưng" [array]="array" [index]="i" [allowCopy]="false">
                                    <ngx-select2 [select2Option]="select2ContactTitle" formControlName="Title"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="ShortName" label="Tên rút gọn (bí danh)" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="ShortName" placeholder="{{'Tên rút gọn (bí danh)' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="Name" label="Tên đầy đủ" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Tên đầy đủ' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="FirstName" label="Tên" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="FirstName" placeholder="{{'Tên' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-1">
                                <ngx-form-group [formGroup]="formItem" name="LastName" label="Họ" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="LastName" placeholder="{{'Họ' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="Code" label="Contact.code" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" disabled nbInput fullWidth formControlName="Code" placeholder="{{'Contact.code' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <!--<div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="Organizations" label="Common.organizations" [array]="array" [index]="i" [allowCopy]="false">
                                    <ngx-select2 [select2Option]="select2Option" formControlName="Organizations"></ngx-select2>
                                </ngx-form-group>
                            </div>-->
                            <div class="col-sm-9">
                                <ngx-form-group [formGroup]="formItem" name="Groups" label="Common.groups" [array]="array" [index]="i" [allowCopy]="false">
                                    <ngx-select2 [select2Option]="select2GroupsOption" [data]="groupList" formControlName="Groups"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Avatar" label="Hình đại diện" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                    <ngx-file-input formControlName="Avatar" [alowUploadByLink]="false"></ngx-file-input>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="Email" label="Contact.email" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="Email" placeholder="{{'Contact.email' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Phone" label="Số di động" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="Phone" placeholder="{{'Số di động' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="CallcenterNumber" label="Số tổng đài" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="CallcenterNumber" placeholder="{{'Số tổng đài' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Province" label="Common.province" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                    <ngx-select2 formControlName="Province" [select2Option]="makeSelect2Option(select2OptionForProvince, formItem)"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="District" label="Common.district" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                    <ngx-select2 formControlName="District" [select2Option]="makeSelect2Option(select2OptionForDistrict, formItem)"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Ward" label="Common.ward" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                    <ngx-select2 formControlName="Ward" [select2Option]="makeSelect2Option(select2OptionForWard, formItem)"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Address" label="Số nhà và tên đường" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="Address" placeholder="{{'Số nhà và tên đường' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="MapLink" label="Link bản đồ" [array]="array" [index]="i" [allowCopy]="false">
                                    <input type="text" nbInput fullWidth formControlName="MapLink" placeholder="{{'Link bản đồ' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="Note" label="Ghi chú" [array]="array" [index]="i" [allowCopy]="false">
                                    <textarea nbInput fullWidth formControlName="Note" placeholder="{{'Ghi chú' | translate | headtitlecase}}"></textarea>
                                </ngx-form-group>
                            </div>
                        </div>
                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <!-- <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button> -->
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero>{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>

        </div>
    </div>
</form>