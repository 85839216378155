import { AdminProductService } from '../../../admin-product/admin-product.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService, NbDialogService, NbDialogRef } from '@nebular/theme';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { environment } from '../../../../../environments/environment';
import { ActionControlListOption } from '../../../../lib/custom-element/action-control-list/action-control.interface';
import { DataManagerFormComponent } from '../../../../lib/data-manager/data-manager-form.component';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { EcardEcardPrintComponent } from '../e-card-print/e-card-print.component';
// import { _ } from 'ag-grid-community';
import { RootServices } from '../../../../services/root.services';
import { EcardModel } from '../../../../models/e-card.model';
import { MobileAppService } from '../../../mobile-app/mobile-app.service';
import { AccountingService } from '../../../accounting/accounting.service';
import { ContactModel } from '../../../../models/contact.model';
var CryptoJS = require("crypto-js");

@Component({
  selector: 'ngx-ecard-ecard-form',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './e-card-form.component.html',
  styleUrls: ['./e-card-form.component.scss']
})
export class EcardEcardFormComponent extends DataManagerFormComponent<EcardModel> implements OnInit {

  componentName: string = 'EcardEcardFormComponent';
  idKey = 'Code';
  baseFormUrl = '/e-card/e-card/form';
  apiPath = '/ecard/ecards';

  env = environment;

  locale = this.cms.getCurrentLoaleDataset();
  curencyFormat: CurrencyMaskConfig = this.cms.getCurrencyMaskConfig();
  numberFormat: CurrencyMaskConfig = this.cms.getNumberMaskConfig();

  // locale = this.commo nService.getCurrentLoaleDataset();
  priceCurencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0 };
  toMoneyCurencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0 };
  quantityFormat: CurrencyMaskConfig = { ...this.cms.getNumberMaskConfig(), precision: 2 };

  towDigitsInputMask = this.cms.createFloatNumberMaskConfig({
    digitsOptional: false,
    digits: 2
  });

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastrService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public adminProductService: AdminProductService,
    public ref: NbDialogRef<EcardEcardFormComponent>,
    public mobileAppService: MobileAppService,
    public accountingService: AccountingService,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastrService, dialogService, cms);

    /** Append print button to head card */
    this.actionButtonList.splice(this.actionButtonList.length - 1, 0, {
      name: 'print',
      status: 'primary',
      label: this.cms.textTransform(this.cms.translate.instant('Common.print'), 'head-title'),
      icon: 'printer',
      title: this.cms.textTransform(this.cms.translate.instant('Common.print'), 'head-title'),
      size: 'medium',
      disabled: () => this.isProcessing,
      hidden: () => false,
      click: (event: any, option: ActionControlListOption) => {
        this.preview(option.form);
      },
    });
  }

  // getRequestId(callback: (id?: string[]) => void) {
  //   callback(this.inputId);
  // }

  getRequestId(callback: (id?: string[]) => void) {
    // callback(this.inputId);
    return super.getRequestId(callback);
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {

    return super.init().then(async status => {
      if (this.isDuplicate) {
        // Clear id
        this.id = [];
        this.array.controls.forEach((formItem, index) => {
          formItem.get('Code').setValue('');
          // formItem.get('Title').setValue('Copy of: ' + formItem.get('Title').value);

        });
      }
      return status;
    });

  }

  /** Execute api get */
  executeGet(params: any, success: (resources: EcardModel[]) => void, error?: (e: HttpErrorResponse) => void) {
    super.executeGet(params, success, error);
  }

  async formLoad(formData: EcardModel[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: EcardModel) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {
      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    });

  }

  makeNewFormGroup(data?: EcardModel): FormGroup {
    const newForm = this.formBuilder.group({
      Code: [null],
      SerialNumber: [null],
      Contact: [null],
      Name: [null],
      Company: [null],
      Address: [null],
      Phone: [null],
      Email: [null],
      Website: [null],
    });
    if (data) {
      // data['Code_old'] = data['Code'];
      newForm.patchValue(data);
    }
    return newForm;
  }
  onAddFormGroup(index: number, newForm: FormGroup, formData?: EcardModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }

  goback(): false {
    // super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/collaborator/opportunity/list']);
    } else {
      this.ref.close();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }


  async preview(formItem: FormGroup) {
    const data: EcardModel = formItem.value;
    this.cms.openDialog(EcardEcardPrintComponent, {
      context: {
        showLoadinng: true,
        title: 'Xem trước',
        data: [data],
        idKey: ['Code'],
        onSaveAndClose: (priceReport: EcardModel) => {
          this.saveAndClose();
        },
        onSaveAndPrint: (priceReport: EcardModel) => {
          this.save();
        },
      },
    });
    return false;
  }

  getRawFormData() {
    return super.getRawFormData();
  }

  onContactChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          // formGroup.get('CustomerName').setValue(selectedData.Name);
        }
      }
    }
  }

}
