<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="dialog-wrap popup" style="width: 90%; margin: 0 auto;">
    <div formArrayName="array">
      <div *ngFor="let formItem of array.controls; let i = index">
        <nb-card class="small-header popup">
          <nb-card-header>
            <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Accounting.Account.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
          </nb-card-header>
          <nb-card-body>
            <div [formGroup]="formItem">
              <div class="row">
                <div class="col-sm-6">
                  <ngx-form-group [formGroup]="formItem" name="Bank" label="Common.bank" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                    <ngx-select2 formControlName="Bank" [select2Option]="select2OptionForBank" [data]="bankList" (selectChange)="onAccBusinessChange(detail, $event, ir)"></ngx-select2>
                  </ngx-form-group>
                </div>
                <div class="col-sm-3">
                  <ngx-form-group [formGroup]="formItem" name="AccountNumber" label="Accounting.accountNumber" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                    <input type="text" nbInput fullWidth formControlName="AccountNumber" placeholder="{{'Accounting.accountNumber' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
                <div class="col-sm-3">
                  <ngx-form-group [formGroup]="formItem" name="Code" label="Common.code" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                    <input type="text" nbInput fullWidth formControlName="Code" placeholder="{{'Common.autoGenerate' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
                <div class="col-sm-3">
                  <ngx-form-group [formGroup]="formItem" name="Province" label="Common.province" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                    <input type="text" nbInput fullWidth formControlName="Province" placeholder="{{'Common.province' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
                <div class="col-sm-3">
                  <ngx-form-group [formGroup]="formItem" name="Branch" label="Common.branch" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                    <input type="text" nbInput fullWidth formControlName="Branch" placeholder="{{'Common.branch' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
                <div class="col-sm-6">
                  <ngx-form-group [formGroup]="formItem" name="Owner" label="Accounting.accountOwner" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                    <input type="text" nbInput fullWidth formControlName="Owner" placeholder="{{'Accounting.accountOwner' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
                <div class="col-sm-6">
                  <ngx-form-group [formGroup]="formItem" name="BranchAddress" label="Common.branchAddress" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                    <input type="text" nbInput fullWidth formControlName="BranchAddress" placeholder="{{'Common.branchAddress' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
                <div class="col-sm-6">
                  <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                    <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
              </div>
            </div>
          </nb-card-body>
          <nb-card-footer>
            <div class="buttons-row" style="text-align: right;">
              <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
              <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>{{'Common.save' | translate | headtitlecase}}</button>
            </div>
          </nb-card-footer>
        </nb-card>
  
      </div>
    </div>
  </form>