import { ApiService } from './../../../../services/api.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CommentService } from './comment.service';
import { CommonService } from '../../../../services/common.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { RootServices } from '../../../../services/root.services';

@Component({
  selector: 'ngx-comment',
  templateUrl: 'comment.component.html',
  styleUrls: ['comment.component.scss'],
  providers: [CommentService],
})
export class CommentComponent {

  @Input() messages: any[] = [];
  @Input() opportunity: any;
  @Output() moreBtnMessageClick = new EventEmitter<any>();

  isProcessing = false;

  constructor(
    protected chatService: CommentService,
    protected apiService: ApiService,
    protected cms: CommonService,
    protected rootServices: RootServices,
  ) {
    // this.messages = this.chatService.loadMessages();
  }

  async sendMessage(event: any) {
    // const files = !event.files ? [] : event.files.map((file) => {
    //   return {
    //     url: file.src,
    //     type: file.type,
    //     icon: 'nb-compose',
    //   };
    // });

    this.isProcessing = true;
    try {
      const files = [];

      const progress = (prg) => { console.log(prg) };

      if (event.files && event.files.length > 0) {


        await this.apiService.getAvailableFileStores({ weight: 0 }).then(rs => rs[0]).then(async fileStore => {
          for (const file of event.files) {
            const imgBlob = new Blob([this.cms.convertBase64ToByteArray((file.src as string).replace(/^data:\w+\/[^,]+;\w+\,/, ''))], {
              type: file.type
            });
            const formData = new FormData();
            // const ext = event.files[0].name.split('.').pop();
            formData.append('file', imgBlob, file.name);
            const newFile = await new Promise((resolve, reject) => {
              this.apiService.uploadPromise(fileStore.Path + '/v3/file/files', { token: fileStore.UploadToken }, formData).subscribe(
                event => {
                  console.log('Upload prgress', event);
                  if (progress) progress(event);
                  if (event.type === HttpEventType.UploadProgress) {
                  } else if (event instanceof HttpResponse) {
                    // resolve(new FileModel(event.body[0]));
                    console.log(event);
                    resolve({
                      url: event.body[0].LargeImage,
                      type: file.type,
                      icon: 'nb-compose',
                      serialize: event.body[0],
                    });
                  }
                },
                err => {
                  console.log('Upload error', err);
                },
              );
            });
            files.push(newFile);
          }
        });

      }



      if (!this.opportunity) {
        this.cms.showError('Phiếu chưa tạo, bạn phải lưu phiếu trước khi bình luận !');
      }

      await this.apiService.postPromise<any[]>('/collaborator/opportunity-comments', {}, [{
        Opportunity: this.opportunity,
        Content: event.message,
        type: 'text',
        Attachments: files.map(f => f.serialize),
      }]).then(rs => {
        const newMessage = rs[0];
        this.messages.push({
          text: event.message,
          date: new Date(newMessage.DateOfPost),
          // reply: true,
          type: files.length ? 'file' : 'text',
          // type: 'text',
          files: files,
          user: {
            name: newMessage.SenderName,
            avatar: newMessage.SenderAvatar.Thumbnail,
          },
        });
      });

      // const botReply = this.chatService.reply(event.message);
      // if (botReply) {
      //   setTimeout(() => { this.messages.push(botReply); }, 500);
      // }
      this.isProcessing = false;
    } catch (err) {
      this.isProcessing = false;
      console.error(err);
      this.cms.showError(err);
    }
  }

  onMoreBtnMessageClick(msg: any) {
    this.moreBtnMessageClick.next(msg);
  }
}
