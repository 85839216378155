<nb-card class="small-header smart-table">
  <nb-card-header>
    <ngx-card-header [size]="size" [icon]="favicon" [title]="title ? title : ('System.Route.Rule.title' | translate:{action: '', definition: cms.translate.instant('Common.list')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
  </nb-card-header>

  <nb-card-body>
    <div class="ng2-smart-table-wrap">
      <ng2-smart-table #smartTable *ngIf="settings" [attr.select]="hasSelect" #table [settings]="settings" [source]="source" (delete)="delete($event)" (edit)="onEditAction($event)"
        (create)="onSerialAction($event)" (userRowSelect)="onUserRowSelect($event)" (rowSelect)="onRowSelect($event)">
      </ng2-smart-table>
    </div>
  </nb-card-body>
</nb-card>
