<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card [nbSpinner]="isProcessing" class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Tài khoản' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">

                        <div>
                            <button type="button" nbButton status="danger" hero size="medium" (click)="chooseFile.click() && false" title="{{'Common.choose' | translate | headtitlecase}}">
                                <nb-icon pack="eva" icon="file"></nb-icon>Chọn file import
                            </button>
                            <input type="file" #chooseFile (change)="chooseFileAndFillDetails(formItem, $event)" style="display: none">
                        </div>

                        <!-- AccountPlatform form -->
                        <label class="label">{{'Platform' | translate | headtitlecase}}</label>

                        <ngx-ag-dynamic-list [titleNowrap]="true" #details [title]="'Facebook accounts'" [columnDefs]="columnDefs" [rowModelType]="'clientSide'" [parentObject]="formItem" [parentData]="formItem.value" [rowData]="formItem.__Details" [idKey]="['Uuid']" (onReady)="onGridReady($event)" (onNodesSelected)="onDetialsSelected($event)" (onComponentInit)="onGridInit($event)" [height]="'70vh'" style="display: block;"></ngx-ag-dynamic-list>

                        <div class="row">
                            <div class="col-sm-12" style="text-align: right;">

                            </div>
                        </div>
                        <!-- End AccountPlatform form -->
                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview(formItem)" [disabled]="!form.valid || isProcessing">
                            <nb-icon nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="import() && false">{{'Import' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>