<nb-chat title="Bình luận" size="large" status="primary" [nbSpinner]="isProcessing">
  <nb-chat-message *ngFor="let msg of messages" 
  [type]="'moreBtn'"
  [customMessageData]="msg"
  [message]="msg.text" 
  [reply]="msg.reply" 
  [sender]="msg.user.name" 
  [date]="msg.date" 
  [files]="msg.files" 
  [quote]="msg.quote" 
  [latitude]="msg.latitude" 
  [longitude]="msg.longitude" 
  [avatar]="msg.user.avatar">
  </nb-chat-message>

  <div *nbCustomMessage="'moreBtn'; let data; noStyles: true">
    <div style="width: 0; height: 0; float: left; position: relative;">
      <button nbButton status="warning" style="position: absolute; bottom: -0.5rem; left: -0.5rem;" hero size="tiny" (click)="onMoreBtnMessageClick(data)">
        <nb-icon pack="eva" icon="external-link-outline"></nb-icon>
      </button>
    </div>
    <nb-chat-message-file
          *ngIf="data.files && data.files.length > 0"
          [sender]="data.sender"
          [date]="data.date"
          [files]="data.files"
        >
        </nb-chat-message-file>
  </div>

  <nb-chat-form (send)="sendMessage($event)" [dropFiles]="true" [messagePlaceholder]="'Bình luận của bạn'">
  </nb-chat-form>
</nb-chat>