<div class="row">
  <div class="col-sm-6">
    <ngx-system-locale-config></ngx-system-locale-config>
  </div>
  <div class="col-sm-6">
    <ngx-system-locale-config></ngx-system-locale-config>
  </div>
  <div class="col-sm-6">
    <ngx-system-locale-config></ngx-system-locale-config>
  </div>
  <div class="col-sm-6">
    <ngx-system-locale-config></ngx-system-locale-config>
  </div>
  <div class="col-sm-12">
    <ngx-sys-parameter-list></ngx-sys-parameter-list>
  </div>
</div>
