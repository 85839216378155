import { Routes } from "@angular/router";
import { AuthGuardService } from "../../services/auth-guard.service";
import { EcardEcardListComponent } from "./e-card/e-card-list/e-card-list.component";

export const ecardRoutes: Routes = [
  // Ecard
  {
    path: 'e-card/e-card/list',
    canActivate: [AuthGuardService],
    component: EcardEcardListComponent,
    data: {
      reuse: true,
    },
  },
];