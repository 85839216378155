<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Accounting.MasterBook.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <div class="row">
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="Code" label="Common.code" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="Code" placeholder="{{'Common.code' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="Year" label="Accounting.MasterBook.year" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                                    <input nbInput fullWidth formControlName="Year" placeholder="{{'Accounting.MasterBook.year' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="DateOfStart" label="Accounting.MasterBook.dateOfStart" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                    <input type="text" [owlDateTime]="DateOfStart" [owlDateTimeTrigger]="DateOfStart" nbInput fullWidth formControlName="DateOfStart" placeholder="{{'Accounting.MasterBook.dateOfStart' | translate | headtitlecase}}">
                                    <owl-date-time #DateOfStart [pickerType]="'calendar'"></owl-date-time>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="DateOfEnd" label="Accounting.MasterBook.dateOfEnd" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                    <input type="text" [owlDateTime]="DateOfEnd" [owlDateTimeTrigger]="DateOfEnd" nbInput fullWidth formControlName="DateOfEnd" placeholder="{{'Accounting.MasterBook.dateOfEnd' | translate | headtitlecase}}">
                                    <owl-date-time #DateOfEnd [pickerType]="'calendar'"></owl-date-time>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="DateOfBeginning" label="Accounting.MasterBook.dateOfBeginning" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                    <input type="text" [owlDateTime]="DateOfBeginning" [owlDateTimeTrigger]="DateOfBeginning" nbInput fullWidth formControlName="DateOfBeginning" placeholder="{{'Accounting.MasterBook.dateOfBeginning' | translate | headtitlecase}}">
                                    <owl-date-time #DateOfBeginning [pickerType]="'calendar'"></owl-date-time>
                                </ngx-form-group>
                            </div>
                        </div>

                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <!-- <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>{{'Common.save' | translate | headtitlecase}}</button> -->
                    </div>
                </nb-card-footer>
            </nb-card>

        </div>
    </div>
</form>