<div class="page-deskboard">

  <div class="row">
    <div class="col-xxxl-3 col-md-3 col-sm-6">
      <nb-card class="status-card">
        <div class="icon-container">
          <div class="icon status-primary" (click)="this.cms.router.navigate(['collaborator/publisher/subscription-page/list'])">
            <i [ngClass]="'nb-lightbulb'"></i>
          </div>
        </div>
        <div class="details">
          <div class="title h5">Trang bán hàng</div>
          <div class="status paragraph-2">{{summaryReport?.NumOfPage | number}}</div>
        </div>
      </nb-card>
    </div>
    <div class="col-xxxl-3 col-md-3 col-sm-6">
      <nb-card class="status-card">
        <div class="icon-container">
          <div class="icon status-info" (click)="this.cms.router.navigate(['collaborator/publisher/product/list'])">
            <i [ngClass]="'nb-lightbulb'"></i>
          </div>
        </div>
        <div class="details">
          <div class="title h5">Sản phẩm kinh doanh</div>
          <div class="status paragraph-2">{{summaryReport?.NumOfProduct | number}}</div>
        </div>
      </nb-card>
    </div>
    <div class="col-xxxl-3 col-md-3 col-sm-6">
      <nb-card class="status-card">
        <div class="icon-container">
          <div class="icon status-warning" (click)="this.cms.router.navigate(['collaborator/publisher/order/list'])">
            <i [ngClass]="'nb-lightbulb'"></i>
          </div>
        </div>
        <div class="details">
          <div class="title h5">Đơn hàng phát sinh</div>
          <div class="status paragraph-2">{{summaryReport?.NumOfOrder | number}}</div>
        </div>
      </nb-card>
    </div>
    <div class="col-xxxl-3 col-md-3 col-sm-6">
      <nb-card class="status-card">
        <div class="icon-container" (click)="this.cms.router.navigate(['/collaborator/publisher/commission/list'])">
          <div class="icon status-success">
            <i [ngClass]="'nb-lightbulb'"></i>
          </div>
        </div>
        <div class="details">
          <div class="title h5">Hoa hồng</div>
          <div class="status paragraph-2">{{summaryReport?.CommissionPaymentAmount | currency:'VND'}}/{{(summaryReport?.CommissionAmount + summaryReport?.CommissionPaymentAmount) | currency:'VND'}}</div>
        </div>
      </nb-card>
    </div>
  </div>

  <div class="row">
    <div class="col-xxxl-12 col-md-12">
      <nb-card>
        <nb-card-body>
          <div class="row">
            <div class="col-sm-3">
              <ngx-form-group [formGroup]="formItem" name="DateReport" label="Common.dateReport" [index]="0" [allowCopy]="false" [required]="true">
                <ngx-select2 formControlName="DateReport" [select2Option]="select2DateReportOption" [data]="dateReportList" (selectChange)="onDateReportChange($event)"></ngx-select2>
              </ngx-form-group>
            </div>
            <div class="col-sm-3">
              <ngx-form-group [formGroup]="formItem" name="DateRange" label="Common.date" [index]="0" [allowCopy]="false" [required]="true">
                <input type="text" [owlDateTime]="DateRange" [selectMode]="'range'" [owlDateTimeTrigger]="DateRange" nbInput fullWidth formControlName="DateRange" placeholder="{{'Common.date' | translate | headtitlecase}}">
                <owl-date-time #DateRange [pickerType]="'calendar'"></owl-date-time>
              </ngx-form-group>
            </div>
            <div class="col-sm-3">
              <ngx-form-group [formGroup]="formItem" name="Page" label="Collaborator.Page.label" [index]="0" [allowCopy]="false" [required]="false">
                <ngx-select2 formControlName="Page" [select2Option]="select2OptionForPage" [data]="this.collaboratorService.pageList$.value" (selectChange)="onChangePage($event)"></ngx-select2>
              </ngx-form-group>
            </div>
            <div class="col-sm-3">
              <ngx-form-group [formGroup]="formItem" name="ProductGroup" label="AdminProduct.Group.lablel" [index]="0" [allowCopy]="false" [required]="false">
                <ngx-select2 formControlName="ProductGroup" [select2Option]="select2ProductGroup" [data]="groupList"></ngx-select2>
              </ngx-form-group>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </div>

  <div class="row">

    <div class="col-xxxl-3 col-xxl-3 col-lg-3 col-md-12">
      <ngx-most-active-products></ngx-most-active-products>
    </div>

    <div class="col-xxxl-9 col-xxl-9 col-lg-9 col-md-12">
      <nb-card class="chart small-header">
        <!-- <nb-card-header>Phát sinh doanh thu/hoa hồng</nb-card-header> -->
        <nb-card-header>
          <ngx-card-header [size]="size" [icon]="favicon" [title]="title ? title : ('Collaborator.Order.title' | translate:{action: '', definition: cms.translate.instant('Common.table')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <ngx-publisher-commission-statistics [data]="data" [options]="options"></ngx-publisher-commission-statistics>
        </nb-card-body>
      </nb-card>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-xxxl-3 col-xxl-4 col-lg-5 col-md-6">
      <ngx-temperature></ngx-temperature>
    </div>

    <div class="col-xxxl-9 col-xxl-8 col-lg-7 col-md-6">
      <ngx-electricity></ngx-electricity>
    </div>
  </div>

  <div class="row">
    <div class="col-xxxl-9 col-xl-12">
      <ngx-rooms></ngx-rooms>
    </div>

    <div class="col-xxxl-3 col-xxl-4 col-lg-7 col-md-6">
      <ngx-contacts></ngx-contacts>
    </div>

    <div class="col-xxxl-3 col-xxl-4 col-lg-5 col-md-6">
      <ngx-solar [chartValue]="solarValue"></ngx-solar>

      <ngx-kitten></ngx-kitten>
    </div>

    <div class="col-xxxl-3 col-xxl-4 col-md-5">
      <ngx-traffic></ngx-traffic>
      <ngx-weather></ngx-weather>
    </div>

    <div class="col-xxxl-6 col-xxl-12 col-md-7">
      <ngx-security-cameras></ngx-security-cameras>
    </div>
  </div> -->
</div>