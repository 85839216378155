<div class="dialog-wrap {{inputMode}}" #dialogWrap>
  <nb-card [nbSpinner]="loading || data.__loading" *ngFor="let data of this.data; let i=index;" style="max-height: initial; margin: 0 auto; width: 23cm;" class="small-header">
    <nb-card-header>
      <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="data['Title'] || ('Xác minh CCCD' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
    </nb-card-header>
    <nb-card-body class="print-body" #printContent>
      <div id="print-area" style="position: relative;">
        <ngx-print-header></ngx-print-header>
        <div>
          <div class="print-title">{{ 'Xác minh CCCD' | translate:{action: '', definition: ''} | uppercase }}
          </div>
          <div class="under-title">{{ data.Code }} - {{ data.Created | date:'short' }}</div>
        </div>
        <div>
          <div style="display: flex;">
            <div style="flex: 1;">
              <div class="under-line">{{'Common.name' | translate | headtitlecase}} :
                {{renderValue(data.Name)}}
              </div>
            </div>
            <div>
              <div class="under-line">{{'Common.phone' | translate | headtitlecase}} :
                {{renderValue(data.Phone)}}
              </div>
            </div>
            <div>
              <div class="under-line">{{'ID' | translate | headtitlecase}} :
                {{renderValue(data.User | objectid)}}
              </div>
            </div>
          </div>
          <div class="under-line">{{'Common.address' | translate | headtitlecase}} : {{renderValue(data.Address)}}</div>

        </div>
        <div>
          <div>
            <div style="font-size: 3rem' line-height: 3.5rem">CCCD: {{data.UserIdentityCardNumber}}</div>
            <div>
              <img src="{{data.UserIdentityCardImage1.LargeImage}}" (click)="cms.browseImages([data.UserIdentityCardImage1, data.UserIdentityCardImage2], 0)" style="width: 50%">
              <img src="{{data.UserIdentityCardImage2.LargeImage}}" (click)="cms.browseImages([data.UserIdentityCardImage1, data.UserIdentityCardImage2], 1)" style="width: 50%">
            </div>
          </div>
        </div>
        <div>
        </div>
        <div class="default-print-footer" [innerHTML]="cms.systemConfigs$.value.PARAMETERS.DEFAULT_PURCHASEORDER_PRINT_FOOTER"></div>
        <div class="default-probox-one-print-footer">{{cms.env.defaultPrintFooter}}</div>
      </div>
    </nb-card-body>
    <nb-card-footer *ngIf="data.RelativeVouchers && data.RelativeVouchers.length > 0" class="print-relative-vouchers">
      <span class="tag" *ngFor="let relationVoucher of data.RelativeVouchers" nbTooltip="{{relationVoucher.type?.text}}: {{relationVoucher | objectstext}}">
        <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.type?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
        <nb-icon pack="eva" icon="close-outline" class="close-btn"></nb-icon>
      </span>
    </nb-card-footer>
    <nb-card-footer>
      <div class="buttons-row">
        <button *ngIf="mode === 'print'" nbButton hero status="info" (click)="edit(data);">
          <nb-icon pack="eva" icon="edit"></nb-icon>{{'Common.edit' | translate | headtitlecase}}
        </button>
        <button nbButton hero status="info" (click)="prepareCopy(data);" style="float: left;">
          <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
        </button>
        <!-- <button nbButton hero status="primary" (click)="print();" [disabled]="!identifier"><nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}</button> -->
        <!-- <button nbButton hero status="info" (click)="exportExcel('excel');">{{'Common.exportTo' | translate:{to: 'Excel'} | headtitlecase}}</button>
            <button nbButton hero status="info" (click)="exportExcel('pdf');">{{'Common.exportTo' | translate:{to: 'PDF'} | headtitlecase}}</button> -->
        <!-- <button nbButton hero status="danger" [disabled]="['COMPLETE'].indexOf(data.State) > -1" (click)="approvedConfirm(data, i);">{{(processMapList[i]?.nextStateLabel || '') | translate | headtitlecase}}<nb-icon pack="eva" icon="skip-forward"></nb-icon></button> -->

        <!--State process buttons-->
        <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
        <!--End State process buttons-->

        <button nbButton hero status="danger" [disabled]="data['IsIdentityCardVerfied']" (click)="changeToVerified(data);">
          <nb-icon pack="eva" icon="checkmark-square-outline"></nb-icon>{{'Xác minh' | translate | headtitlecase}}
        </button>

        <button *ngIf="!sourceOfDialog || sourceOfDialog === 'form'" nbButton hero status="success" (click)="saveAndClose(data);">
          <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>