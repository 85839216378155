<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="dialog-wrap popup" style="width: 90%; margin: 0 auto;">
    <div formArrayName="array">
      <div *ngFor="let formItem of array.controls; let i = index">
        <nb-card class="small-header popup">
          <nb-card-header>
            <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Accounting.Account.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
          </nb-card-header>
          <nb-card-body>
            <div [formGroup]="formItem">
              <div class="row">
                <div class="col-sm-6">
                  <ngx-form-group [formGroup]="formItem" name="Name" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                    <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Common.name' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
                <div class="col-sm-3">
                  <ngx-form-group [formGroup]="formItem" name="Code" label="Common.code" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                    <input type="text" nbInput fullWidth formControlName="Code" placeholder="{{'Common.code' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
                <div class="col-sm-2">
                  <ngx-form-group [formGroup]="formItem" name="Currency" label="Common.currency" [array]="array" [index]="i" [allowCopy]="true" [required]="true">
                    <ngx-select2 formControlName="Currency" [select2Option]="currencyListSelect2Option" [data]="currencyList"></ngx-select2>
                  </ngx-form-group>
                </div>
                <div class="col-sm-1">
                  <ngx-form-group [formGroup]="formItem" name="Property" label="Accounting.property" [array]="array" [index]="i" [allowCopy]="true" [required]="true">
                    <ngx-select2 formControlName="Property" [select2Option]="accountTypeListSelect2Option" [data]="accountPropertyList"></ngx-select2>
                  </ngx-form-group>
                </div>
                <div class="col-sm-6">
                  <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                    <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
                <div class="col-sm-3">
                  <ngx-form-group [formGroup]="formItem" name="ReportByObject" label="Accounting.reportByObject" [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                    <ngx-select2 formControlName="ReportByObject" [select2Option]="reportByObjectSelect2Option" [data]="reportByObjectList"></ngx-select2>
                  </ngx-form-group>
                </div>
                <div class="col-sm-3">
                  <ngx-form-group [formGroup]="formItem" name="ReportByBankAccount" label="Accounting.reportByBankAccount" [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                    <nb-checkbox formControlName="ReportByBankAccount">
                      <label class="label"><span class="valid">{{'Accounting.reportByBankAccount' | translate | headtitlecase}}</span></label>
                    </nb-checkbox>
                  </ngx-form-group>
                </div>
              </div>
            </div>
          </nb-card-body>
          <nb-card-footer>
            <div class="buttons-row" style="text-align: right;">
              <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
              <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>{{'Common.save' | translate | headtitlecase}}</button>
            </div>
          </nb-card-footer>
        </nb-card>
  
      </div>
    </div>
  </form>